import React from 'react'
import Layout from '../layouts'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Button, Container, Paper, TextField, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { autenticar, salvarNovaSenha } from '../../redux/cadastro/usuarioSlice'
import Aviso from '../layouts/Aviso'
import { useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'

const AlterarSenha = (props) => {
  const { autenticar, atualizarNovaSenha } = props
  const [openAviso, setOpenAviso] = useState(false)

  const user = JSON.parse(localStorage.getItem('stateAutenticacao'))

  const validationSchema = yup.object({
    email: yup
      .string('Informe o email')
      .email('Informe um email válido')
      .required('campo obrigatório')
      .oneOf([user.login.user.email], 'E-mail não confere.'),
    senhaAtual: yup
      .string('Informe a senha atual')
      .required('Campo obrigatório'),
    novaSenha: yup
      .string('Informe a nova senha')
      .min(6, 'A nova senha deve possuir no mínimo 6 caracteres')
      .required('A nova senha é obrigatória'),
    confirmeNovaSenha: yup
      .string('Confirme a nova senha')
      .min(6, 'A nova senha deve possuir no mínimo 6 caracteres')
      .required('A confirmação da nova senha é obrigatória')
      .oneOf([yup.ref('novaSenha')], 'Senha não confirmada. Tente novamente'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      senhaAtual: '',
      novaSenha: '',
      confirmeNovaSenha: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      handleTrocarSenha(values)
    },
  })

  const handleTrocarSenha = async (values) => {
    validarSenha(values)
    console.log('formik.isSubmitting: ', formik.isSubmitting)
    console.log('formik.errors.senhaAtual: ', !formik.errors.senhaAtual)
    if (
      formik.isSubmitting &&
      (formik.errors.senhaAtual === '' ||
        formik.errors.senhaAtual === undefined)
    ) {
      console.log('gravar nova senha')
      gravarSenha(values)
    }
  }

  const validarSenha = async (values) => {
    const dados = { email: values.email, senha: values.senhaAtual }
    const result = await autenticar(dados)
    if (!result.payload) {
      console.log('formik.errors', formik.errors)
      formik.setErrors({ senhaAtual: 'Senha atual invalida.' })
    }
    console.log('Senha atual validada.')
    return
  }

  const gravarSenha = async (values) => {
    const dados = { email: values.email, senha: values.novaSenha }
    console.log('dados para salvar a nova senha: ', dados)
    const result = await atualizarNovaSenha(dados)
    console.log('retorno da gravação:', result)
    setOpenAviso(true)
  }

  return (
    <Layout titulo="Alterar Senha" subtitulo="Informe a nova senha">
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Paper
            elevation={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100vh - 130px)',
              gap: '10',
              width: 'auto',
              px: 10,
              mt: 5,
              '& .MuiTextField-root ': { m: 1, width: '40ch' },
              '& .MuiButton-root': { m: 1, width: '44ch' },
            }}
          >
            <Typography
              variante="subtitle1"
              gutterBottom
              sx={{ fontSize: '25px', mb: 4 }}
            >
              TROCAR A SENHA
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              size="small"
              fullWidth
              id="senhaAtual"
              name="senhaAtual"
              label="Senha Atual"
              type="password"
              value={formik.values.senhaAtual}
              onChange={formik.handleChange}
              error={
                formik.touched.senhaAtual && Boolean(formik.errors.senhaAtual)
              }
              helperText={formik.touched.senhaAtual && formik.errors.senhaAtual}
            />
            <TextField
              size="small"
              fullWidth
              id="novaSenha"
              name="novaSenha"
              label="Nova Senha"
              type="password"
              value={formik.values.novaSenha}
              onChange={formik.handleChange}
              error={
                formik.touched.novaSenha && Boolean(formik.errors.novaSenha)
              }
              helperText={formik.touched.novaSenha && formik.errors.novaSenha}
            />
            <TextField
              size="small"
              fullWidth
              id="confirmeNovaSenha"
              name="confirmeNovaSenha"
              label="Confirme Nova Senha"
              type="password"
              value={formik.values.confirmeNovaSenha}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmeNovaSenha &&
                Boolean(formik.errors.confirmeNovaSenha)
              }
              helperText={
                formik.touched.confirmeNovaSenha &&
                formik.errors.confirmeNovaSenha
              }
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              startIcon={<SaveIcon />}
            >
              salvar
            </Button>
          </Paper>
        </form>
      </Container>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Senha alterada com sucesso!!!`}
        tipo="success"
      />
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    autenticar: (dados) => dispatch(autenticar(dados)),
    atualizarNovaSenha: (registro) => dispatch(salvarNovaSenha(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlterarSenha)

//export default AlterarSenha
