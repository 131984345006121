import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
//import { format } from 'date-fns'
//import pt from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  adicionarMes,
  checarData,
  dataBRExibir,
  valorMoeda,
} from '../../../funcoes/data'
import {
  fetchPagamentos,
  saveAllPagamento,
} from '../../../redux/cadastro/pagamentoSlice'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import {
  fetchRecebimentos,
  saveAllRecebimento,
} from '../../../redux/cadastro/recebimentoSlice'
import { makeStyles, createStyles } from '@material-ui/styles'
import { fetchFormasPagamento } from '../../../redux/cadastro/tabelas/formasPagamentoSlice'
import { fetchTiposPagamento } from '../../../redux/cadastro/tabelas/tiposPagamentoSlice'
import { fetchStatusNegociacao } from '../../../redux/cadastro/tabelas/statusNegociacaoSlice'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import { saveNegociacao } from '../../../redux/cadastro/negociacaoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'
//import { date } from 'yup';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        border: '2px solid black',
        fontSize: 12,
        zIndex: 9999,
        '& li:nth-child(even)': { backgroundColor: '#F2F2F2' },
        '& li:nth-child(odd)': { backgroundColor: '#FFF' },
      },
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  })
)

const CustomPopper = (props) => {
  const classes = useStyles()
  return (
    <Popper
      sx={{ zIndex: 999 }}
      {...props}
      className={classes.root}
      placement="bottom"
    />
  )
}

function FormParcela(props) {
  const { dados, fecharForm } = props

  const [openAviso, setOpenAviso] = useState(false)
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [tipoNegociacao, setTipoNegociacao] = useState('')
  const [fornecedorId, setFornecedorId] = useState({})
  const [formaPagamentoId, setFormaPagamentoId] = useState('')
  const [dataPrimeiraParcela, setDataPrimeiraParcela] = useState('')
  const [qtdeParcelas, setQtdeParcelas] = useState('')
  const [valorReferencia, setValorReferencia] = useState('')
  const [tipoPagamento, setTipoPagamento] = useState('')
  const [status, setStatus] = useState('')
  const [observacoes, setObservacoes] = useState('')
  const [parcelasCriadas, setParcelasCriadas] = useState([])
  const [dialogError, setDialogError] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRecebimentos())
    dispatch(fetchPagamentos())
    dispatch(fetchPessoas())
    dispatch(fetchFormasPagamento())
    dispatch(fetchTiposPagamento())
    dispatch(fetchStatusNegociacao())
    setFormaPagamentoId(calcularFormaPagamentoId(dados.periodicidade_id))
    setStatus(calcularStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setDataPrimeiraParcela(calcularDataPrimeiraParcela())
    setValorReferencia(calcularValorReferencia())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoNegociacao])

  useEffect(() => {
    setQtdeParcelas(calcularQtdeParcelas())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrimeiraParcela, formaPagamentoId])

  useEffect(() => {
    setStatus(calcularStatus())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formaPagamentoId])

  const recebimentos = useSelector((state) => state.recebimentos.registros)
    .filter((r) => r.servico_id === dados.id)
    .sort((r1, r2) => (r1.data < r2.data ? 1 : -1))
  const pagamentos = useSelector((state) => state.pagamentos.registros)
    .filter((r) => r.servico_id === dados.id)
    .sort((r1, r2) => (r1.data_vencimento < r2.data_vencimento ? 1 : -1))
  const fornecedores = useSelector((state) => state.pessoas.registros)
    .filter((p) => p.grupo_pessoa_id === 3 || p.grupo_pessoa_id === 4)
    .sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
  const formasPagamento = useSelector(
    (state) => state.formasPagamento.registros
  )
  const tiposPagamento = useSelector((state) => state.tiposPagamento.registros)
    .filter((t) => t.id > 0)
    .sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
  const statusNegociacao = useSelector(
    (state) => state.statusNegociacao.registros
  )

  const fornecedoresOptions = fornecedores?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const handleTipoNegociacao = (e) => {
    setTipoNegociacao(e.target.value)
  }

  const calcularFormaPagamentoId = (periodicidadeServicoId) => {
    let forma_pagamento_id = 1
    switch (periodicidadeServicoId) {
      case 1:
      case 5:
        forma_pagamento_id = 1
        break
      case 2:
      case 3:
      case 4:
        forma_pagamento_id = 3
        break
      case 6:
        forma_pagamento_id = 4
        break
      default:
        forma_pagamento_id = 1
    }
    return forma_pagamento_id
  }

  const calcularQtdeParcelas = () => {
    if (dataPrimeiraParcela) {
      let qtde = 1
      let f = formaPagamentoId
      const anoPrimeiraParcela = dataPrimeiraParcela.substring(0, 4) || ''
      if (f === 1 || f === 4) {
        qtde = 1
      } else if (f === 2) {
        qtde = 2
      } else if (f === 3) {
        let proximaData = adicionarMes(dataPrimeiraParcela)
        while (anoPrimeiraParcela === proximaData.substring(0, 4)) {
          qtde++
          proximaData = adicionarMes(proximaData)
        }
      }
      return qtde
    }
    return ''
  }

  const calcularDataPrimeiraParcela = () => {
    let dtPrimeiraParcela = ''
    if (tipoNegociacao === '1') {
      dtPrimeiraParcela = recebimentos[0]?.data
    } else if (tipoNegociacao === '2') {
      dtPrimeiraParcela = pagamentos[0]?.data_vencimento
    } else {
      dtPrimeiraParcela = ''
    }
    if (dtPrimeiraParcela && dtPrimeiraParcela !== '') {
      dtPrimeiraParcela = adicionarMes(dtPrimeiraParcela)
    }
    return dtPrimeiraParcela || ''
  }

  const calcularValorReferencia = () => {
    let valor = 0.0
    if (tipoNegociacao === '1') {
      valor = recebimentos[0]?.valor
    } else if (tipoNegociacao === '2') {
      valor = pagamentos[0]?.valor
    } else {
      valor = ''
    }
    return valor || ''
  }

  const calcularStatus = () => {
    let status = ''
    if (formaPagamentoId === 3 || formaPagamentoId === 4) {
      status = 1
    } else {
      status = 2
    }
    return status || ''
  }

  const calcularParcelas = () => {
    let parcela = {}
    let parcelas = []
    let dataParcela = dataPrimeiraParcela
    for (let index = 0; index < qtdeParcelas; index++) {
      if (tipoNegociacao === '1') {
        parcela = {
          id: '',
          projeto_id: dados.projeto_id,
          servico_id: dados.id,
          status_recebimento_id: 1,
          origem_recebimento_id: null,
          numero_parcela: index + 1,
          data: dataParcela,
          data_pagamento: null,
          valor: valorReferencia,
          observacoes: observacoes,
        }
      } else if (tipoNegociacao === '2') {
        parcela = {
          id: '',
          projeto_id: dados.projeto_id,
          servico_id: dados.id,
          tipo_pagamento_id: tipoPagamento,
          origem_pagamento_id: null,
          status_pagamento_id: 1,
          fornecedor_id: fornecedorId.value,
          data_vencimento: dataParcela,
          numero_parcela: index + 1,
          data_pagamento: null,
          historico: observacoes,
          valor: valorReferencia,
        }
      } else {
        return false
      }
      parcelas.push(parcela)
      dataParcela = adicionarMes(dataParcela)
    }

    setParcelasCriadas(parcelas)
  }

  const calcularNegociacao = () => {
    const negociacao = {
      id: '',
      tipo_id: tipoNegociacao,
      servico_id: dados.id,
      forma_pagamento_id: formaPagamentoId,
      fornecedor_id: fornecedorId.value || null,
      tipo_pagamento_id: tipoPagamento || null,
      valor_referencia: parseFloat(valorReferencia).toFixed(2),
      qtde_parcelas: qtdeParcelas,
      data_referencia: dataPrimeiraParcela,
      data_ultima_Parcela: null,
      observacoes: observacoes || null,
      status_id: status,
    }
    return negociacao
  }

  const totalizarParcelas = (parcelas) => {
    return parcelas
      .map(({ valor }) => valor)
      .reduce((sum, i) => sum + parseFloat(i), 0.0)
  }

  const renderUltimoRecebimento = () => {
    return (
      <Grid item>
        <Box>
          <strong>ÚLTIMO RECEBIMENTO</strong>
        </Box>
        <Box>
          Data:{' '}
          {checarData(recebimentos[0]?.data)
            ? dataBRExibir(recebimentos[0]?.data)
            : 'Não Há'}
        </Box>
        <Box>
          Valor:{' '}
          {recebimentos[0]?.valor
            ? valorMoeda(recebimentos[0]?.valor)
            : 'Não há'}
        </Box>
      </Grid>
    )
  }

  const renderUltimoPagamento = () => {
    return (
      <Grid item>
        <Box>
          <strong>ÚLTIMO PAGAMENTO</strong>
        </Box>
        <Box>
          Data:{' '}
          {checarData(pagamentos[0]?.data_vencimento)
            ? dataBRExibir(pagamentos[0]?.data_vencimento)
            : 'Não Há'}
        </Box>
        <Box>
          Valor:{' '}
          {pagamentos[0]?.valor ? valorMoeda(pagamentos[0]?.valor) : 'Não há'}
        </Box>
      </Grid>
    )
  }

  const salvarNegociacao = async () => {
    const registro = calcularNegociacao()

    const res = await dispatch(saveNegociacao(registro))
    calcularParcelas()

    if (!!res?.error) {
      setDialogError(
        `Adicionar Negociação: ${res.error?.code} - ${res.error?.message}`
      )
      setOpenDialogErro(true)
    } else {
      setOpenAviso(true)
    }
  }

  const salvarParcelas = async () => {
    let res = {}
    if (parcelasCriadas.length > 0) {
      if (tipoNegociacao === '1') {
        res = await dispatch(saveAllRecebimento(parcelasCriadas))
      } else if (tipoNegociacao === '2') {
        res = await dispatch(saveAllPagamento(parcelasCriadas))
      } else {
        return false
      }

      if (!!res?.error) {
        setDialogError(
          `Adicionar Parcelas: ${res.error?.code} - ${res.error?.message}`
        )
        setOpenDialogErro(true)
      } else {
        setOpenAviso(true)
        setTimeout(() => {
          fecharForm()
        }, 1500)
      }
    } else {
      return false
    }
  }

  return (
    <Grid container display="flex" flexDirection="column">
      <Divider>
        <Chip label="Dados do Serviço, último Pagamento e Recebimento" />
      </Divider>

      <Grid
        mb={2}
        mt={2}
        container
        display="flex"
        justifyContent="space-between"
      >
        <Grid item>
          <Box>
            <strong>SERVIÇO</strong>
          </Box>
          <Box>Cliente: {dados?.cliente?.nome}</Box>
          <Box>Projeto: {dados?.projeto?.nome}</Box>
          <Box>Servico: {dados?.nome}</Box>
          <Box>Periodicidade: {dados?.periodicidadeServico?.nome}</Box>
        </Grid>
        {renderUltimoRecebimento()}
        {renderUltimoPagamento()}
      </Grid>
      <Grid item xs={12}>
        <Divider>
          <Typography variant="subtitle1" gutterBottom component="div">
            <Chip label="Selecione o Tipo de Parcela" />
          </Typography>
        </Divider>
        <FormControl
          sx={{ minWidth: '100%', align: 'center' }}
          variant="outlined"
        >
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={tipoNegociacao}
            onChange={(e) => handleTipoNegociacao(e)}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <FormControlLabel
              value={1}
              control={<Radio size="small" />}
              label={
                <span style={{ fontSize: '0.9rem' }}>
                  Recebimentos (entradas)
                </span>
              }
            />
            <FormControlLabel
              value={2}
              control={<Radio size="small" />}
              label={
                <span style={{ fontSize: '0.9rem' }}>Pagamentos (Saídas)</span>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {tipoNegociacao ? (
        <Grid item mt={2}>
          <Divider>
            <Chip label="Informe os dados da negociação" />
          </Divider>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            sx={{ width: '100%', pt: '20px' }}
          >
            {tipoNegociacao === '2' ? (
              <Grid item xs={12} sm={6} md={4} lg={5}>
                <Autocomplete
                  disablePortal
                  id="fornecedor_id"
                  name="fornecedor_id"
                  size="small"
                  noOptionsText={'Nenhuma opção disponível'}
                  options={fornecedoresOptions}
                  value={fornecedorId}
                  isOptionEqualToValue={(option) => (option = fornecedorId)}
                  getOptionLabel={(option) => option.label || ''}
                  onChange={(event, newValue, option) => {
                    setFornecedorId(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Fornecedor"
                      value={fornecedorId}
                      // onChange={(e) => setFornecedorId(e.target.value)}
                      // error={touched.fornecedor_id && Boolean(errors.fornecedor_id)}
                      // helperText={touched.fornecedor_id && errors.fornecedor_id}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.value}>
                      {`${option.label} (${option.value})`}
                    </Box>
                  )}
                  PopperComponent={CustomPopper}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                size="small"
                id="forma_pagamento_id"
                select
                label="Forma de pagamento"
                value={formaPagamentoId}
                defaultValue={formaPagamentoId}
                onChange={(e) => setFormaPagamentoId(e.target.value)}
                fullWidth
              >
                {formasPagamento.map((option) => {
                  return (
                    <MenuItem key={option.id} value={option.id}>
                      {option.nome}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                type="date"
                id="data_referencia"
                name="data_referencia"
                label="Data de pagamento"
                fullWidth
                size="small"
                value={dataPrimeiraParcela}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setDataPrimeiraParcela(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <TextField
                size="small"
                id="numero_total_parcelas"
                name="numero_total_parcelas"
                label="Quantidade de Parcelas"
                type="number"
                fullWidth
                value={qtdeParcelas}
                onChange={(e) => setQtdeParcelas(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                size="small"
                id="valor_referencia"
                name="valor_referencia"
                label="Valor da Parcela"
                type="number"
                fullWidth
                value={valorReferencia}
                onChange={(e) => setValorReferencia(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            {tipoNegociacao === '2' ? (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                  size="small"
                  id="tipo_pagamento_id"
                  select
                  label="Tipo de Pagamento"
                  value={tipoPagamento}
                  defaultValue={tipoPagamento}
                  onChange={(e) => setTipoPagamento(e.target.value)}
                  fullWidth
                >
                  {tiposPagamento.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Tooltip title="Delete">
                <TextField
                  size="small"
                  id="status_id"
                  select
                  label="Status da Negociação"
                  value={status}
                  defaultValue={status}
                  onChange={(e) => setStatus(e.target.value)}
                  fullWidth
                >
                  {statusNegociacao.map((option) => {
                    return (
                      <MenuItem key={option.id} value={option.id || ''}>
                        {option.nome}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="observacoes"
                name="observacoes"
                label="Observações"
                type="text"
                fullWidth
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
                multiline
                minRows={3}
              ></TextField>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={salvarNegociacao}
                startIcon={<SaveIcon />}
              >
                <strong>Salvar e Gerar Parcelas</strong>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
            {parcelasCriadas.length > 0 ? (
              <TableContainer>
                <Divider mb={2}>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Chip
                      label={
                        tipoNegociacao === 1
                          ? 'Parcelas de Recebimento Criadas'
                          : 'Parcelas de Pagamento Criadas'
                      }
                    />
                  </Typography>
                </Divider>
                <Typography variant="body2" gutterBottom>
                  Caso haja necessidade de realizar ajustes nas parcelas ou
                  datas, você deverá fazê-lo acessando a lista de recebimentos
                  ou pagamentos, após salvar.
                </Typography>
                <Table aria-label="spanning table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: 'white' }}>Nº</TableCell>
                      <TableCell sx={{ color: 'white' }} align="center">
                        DATA
                      </TableCell>
                      <TableCell sx={{ color: 'white' }} align="right">
                        VALOR
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parcelasCriadas.map((row) => (
                      <TableRow key={row.numero_parcela}>
                        <TableCell>{row.numero_parcela}</TableCell>
                        {/* <TableCell align="center">{checarData(row.data || row.data_vencimento) ? format(new Date(row.data || row.data_vencimento), 'dd/MM/yyyy') : ''}</TableCell> */}
                        <TableCell align="center">
                          {checarData(row.data || row.data_vencimento)
                            ? dataBRExibir(row.data || row.data_vencimento)
                            : ''}
                        </TableCell>
                        <TableCell align="right">
                          {valorMoeda(row.valor)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        <strong>TOTAL</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>
                          {valorMoeda(totalizarParcelas(parcelasCriadas))}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Grid item xs={12} display="flex" gap={2} p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => salvarParcelas()}
                    startIcon={<SaveIcon fontSize="small" />}
                  >
                    <strong>Salvar Parcelas</strong>
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setParcelasCriadas([])}
                    startIcon={<CancelIcon fontSize="small" />}
                  >
                    <strong>Cancelar</strong>
                  </Button>
                </Grid>
              </TableContainer>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        // mensagem={`Serviço ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
        mensagem={'Registro incluído com sucesso'}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir o serviço. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </Grid>
  )
}

export default FormParcela
