import { Box } from '@mui/material'
import React, { useState } from 'react'
import Layout from '../../layouts'
import FormUsuario from './FormUsuario'
import Tabela from './Table'

function Usuarios() {
  const [modo, setModo] = useState({ status: 'table', registro: {} })
  return (
    <Layout titulo="Usuários" subtitulo="Cadastro de usuários do sistema">
      <Box>
        {modo.status === 'table' ? (
          <Tabela setModo={setModo} modo={modo} />
        ) : (
          <FormUsuario setModo={setModo} modo={modo} />
        )}
      </Box>
    </Layout>
  )
}

export default Usuarios
