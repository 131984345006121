import React, { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { FiltroColuna } from '../../../table/FiltroColunaComponent'
import COLUMNS from './colunas'
import { useExportData } from 'react-table-plugins'
import { getExportFileBlob } from '../../../../funcoes/exportTable'
import { Box, IconButton, Typography } from '@mui/material'
import Loading from '../../../layouts/Loading'
import AppBarComponent from '../../../table/AppBarComponent'
import BarraInferiorTable from '../../../table/BarraInferiorTableComponent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import EditIcon from '@mui/icons-material/Edit'
import DialogExclusao from '../../../layouts/DialogExclusao'
import {
  deleteRegistro,
  excluir,
  fetchStatusServico,
} from '../../../../redux/cadastro/tabelas/statusServicoSlice'

export const Tabela = (props) => {
  const { setModo, registros, listar, excluirBd, excluirStore } = props

  const [botaoExcluidos] = useState(false)
  const [exibirExcluidos, setExibirExcluidos] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [idExclusao, setIdExclusao] = useState('')

  useEffect(() => {
    listar()
  }, [listar])

  const columns = useMemo(() => COLUMNS(), [])
  const data = useMemo(() => registros.dados, [registros])

  const defaultColumn = useMemo(() => {
    return {
      Filter: FiltroColuna,
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData
  )

  const { globalFilter, pageIndex, pageSize } = state

  const handleExcluir = (idExclusao) => {
    excluirBd(idExclusao)
    excluirStore(idExclusao)
  }

  const generateCellStyles = (align, value, type, header) => {
    let styleObject = {
      color: 'currentColor',
      textAlign: '',
      backgroundColor: '',
    }

    styleObject.textAlign = align === 'right' ? 'right' : 'left'

    if (header === 'Cor Padrão') {
      styleObject.backgroundColor = value
    }

    if (header === 'Cor Fonte') {
      styleObject.backgroundColor = value
    }

    return {
      style: styleObject,
    }
  }

  const combinedCellProps = (defaultCellProps, { cell }) => {
    return [
      defaultCellProps,
      generateCellStyles(
        cell.column.align,
        cell.value,
        cell.column.type,
        cell.column.Header
      ),
    ]
  }

  return (
    <Box>
      {registros.loading ? (
        <Loading />
      ) : registros.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {registros.error})
        </Typography>
      ) : (
        <Box>
          <AppBarComponent
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setModo={setModo}
            exibirExcluidos={exibirExcluidos}
            setExibirExcluidos={setExibirExcluidos}
            botaoExcluidos={botaoExcluidos}
            exportData={exportData}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            allColumns={allColumns}
          />
          <Box>
            <table {...getTableProps()} style={{ marginTop: '150px' }}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Box display="flex" justifyContent="space-between">
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon
                                  sx={{ fontSize: '13px', color: 'yellow' }}
                                />
                              ) : (
                                <ArrowDropUpIcon
                                  sx={{ fontSize: '13px', color: 'yellow' }}
                                />
                              )
                            ) : null}
                          </span>
                        </Box>
                      </th>
                    ))}
                    <th>Ações</th>
                  </tr>
                ))}

                {pageCount > 1 &&
                  headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps(combinedCellProps)}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                      <td>
                        <Box sx={{ display: 'flex' }}>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setModo({
                                status: 'edit',
                                registro: row.original,
                              })
                            }
                            size="small"
                          >
                            <EditIcon color="danger" />
                          </IconButton>
                          <DialogExclusao
                            title="o Status de Serviço "
                            referencia={`${row.original.nome} (${row.original.descricao})`}
                            idExclusao={row.original.id}
                            setIdExclusao={setIdExclusao}
                            handleExcluir={handleExcluir}
                          />
                        </Box>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {pageCount > 1 ? (
              <BarraInferiorTable
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    registros: {
      loading: state.statusServico.loading,
      dados: state.statusServico.registros,
      error: state.statusServico.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listar: () => dispatch(fetchStatusServico()),
    excluirBd: (id) => dispatch(excluir(id)),
    excluirStore: (idExclusao) => dispatch(deleteRegistro(idExclusao)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabela)
