import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: false,
  registros: [],
  error: '',
}

export const fetchTiposPessoa = createAsyncThunk('tabelas/tiposPessoas', () => {
  const autorizacao = header()
  return api
    .get('cadastro/tiposPessoa', autorizacao)
    .then((res) => res.data.registros)
})

const tiposPessoaSlice = createSlice({
  name: 'tiposPessoas',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTiposPessoa.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchTiposPessoa.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchTiposPessoa.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
  },
})

export default tiposPessoaSlice.reducer
export const selectTiposPessoa = (state) => state.tiposPessoa
