import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PushPinIcon from '@mui/icons-material/PushPin'

const options = ['Fixar']

const ITEM_HEIGHT = 48

export default function AtividadesMenu(props) {
  const { atividade, tipo } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFixar = (atividade, tipo) => {
    console.log('af:', atividade, tipo)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key={1} selected onClick={() => handleFixar(atividade, tipo)}>
          {tipo === 'fixado' ? (
            <>
              <img
                src="https://img.icons8.com/material-sharp/24/null/unpin.png"
                alt="Desafixar"
              />
              Desafixar
            </>
          ) : (
            <>
              <PushPinIcon />
              Fixar
            </>
          )}
        </MenuItem>
        {/* {options.map((option) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                        {option}
                    </MenuItem>
                ))} */}
      </Menu>
    </div>
  )
}
