function autorizacao(niveisAutorizados) {
  const auth = JSON.parse(localStorage.getItem('stateAutenticacao'))
  const tipo = auth.login.user?.tipo

  console.log(niveisAutorizados)

  if (niveisAutorizados === undefined) {
    return true
  } else if (niveisAutorizados.includes(parseInt(tipo))) {
    return true
  }
  return false
}
export default autorizacao
