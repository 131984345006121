import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'abasPessoa',
  initialState: {
    loading: true,
    status: {
      dadosGerais: false,
      pessoaFisica: true,
      pessoaJuridica: true,
      enderecos: true,
      redesSociais: true,
      telefones: true,
    },
  },
  reducers: {
    changeAbasPessoa(state, { payload }) {
      return { ...state, loading: false, status: payload }
    },
  },
})

export const { changeAbasPessoa } = slice.actions
export const selectAbasPessoa = (state) => state.abasPessoa
export default slice.reducer
