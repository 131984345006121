import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import TableAgencia from './tableAgencia'

export const Agencia = (props) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom align="center" mt={1}>
        Agências
      </Typography>
      <Divider sx={{ mb: '10px' }} />
      <TableAgencia />
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Agencia)
