export const tarefas = [
  {
    id: '1',
    nome: 'Tarefa 1 com titulo grande para ver na grade',
    descricao: 'Teste de tarefa',
    situacao: 'I',
    colorSituacao: '#ff8c00',
    colorText: '#0d0d0d',
  },
  {
    id: '2',
    nome: 'Tarefa 2',
    descricao:
      'Lorem ipsum dolor sit amet . Os operadores gráficos e tipográficos sabem disso bem, na realidade, todas as profissões que lidam com o universo da comunicação têm um relacionamento estável com essas palavras, mas o que é? Lorem ipsum é um texto fofo sem qualquer sentido.',
    situacao: 'E',
    colorSituacao: '#FF0000',
    colorText: '',
  },
  {
    id: '3',
    nome: 'Tarefa 3',
    descricao: 'Teste de tarefa',
    situacao: 'E',
    colorSituacao: '#FF0000',
    colorText: '',
  },
  {
    id: '4',
    nome: 'Tarefa 4',
    descricao: 'Teste de tarefa',
    situacao: 'C',
    colorSituacao: '#32CD32',
    colorText: '',
  },
  {
    id: '5',
    nome: 'Tarefa 5',
    descricao:
      'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão.',
    situacao: 'Co',
    colorSituacao: '#b0bec5',
    colorText: '#0a0a0a',
  },
  {
    id: '6',
    nome: 'Tarefa 6',
    descricao: 'Teste de tarefa',
    situacao: 'En',
    colorSituacao: '#000000',
    colorText: '',
  },
  {
    id: '7',
    nome: 'Tarefa 7',
    descricao:
      'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão Posteriormente serão ocupados a partir de textos ad hoc compostos por profissionais de comunicação.',
    situacao: 'En',
    colorSituacao: '#000000',
    colorText: '',
  },
  {
    id: '8',
    nome: 'Tarefa 8',
    descricao: 'Teste de tarefa',
    situacao: 'En',
    colorSituacao: '#000000',
    colorText: '',
  },
  {
    id: '9',
    nome: 'Tarefa 9',
    descricao:
      'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão Posteriormente serão ocupados a partir de textos ad hoc compostos por profissionais de comunicação.',
    situacao: 'I',
    colorSituacao: '#ff8c00',
    colorText: '#0d0d0d',
  },
]

export const colunas = {
  0: {
    title: 'Coluna 1',
    items: tarefas,
  },
  1: {
    title: 'Coluna 2',
    items: [],
  },
  2: {
    title: 'Coluna 3',
    items: [],
  },
  3: {
    title: 'Coluna 4',
    items: [],
  },
  4: {
    title: 'Coluna 5',
    items: [],
  },
  5: {
    title: 'Coluna 6',
    items: [],
  },
}
