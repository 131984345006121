import {
  AppBar,
  Button,
  Divider,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'

import CreditCardIcon from '@mui/icons-material/CreditCard'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import LockResetIcon from '@mui/icons-material/LockReset'
import MoneyIcon from '@mui/icons-material/Money'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import TaskIcon from '@mui/icons-material/Task'
import { Box } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { navigate } from 'hookrouter'
import React, { useState } from 'react'
import DrawerMenu from './DrawerMenu'
import Logotipo from './logo'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const NavBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const open = Boolean(anchorEl)
  const open1 = Boolean(anchorEl1)

  const user = JSON.parse(localStorage.getItem('stateAutenticacao'))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClose1 = () => {
    setAnchorEl1(null)
  }
  return (
    <AppBar position="fixed" color="primary">
      <Toolbar
        sx={{
          width: 'auto',
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="logo"
          onClick={() => navigate('/home')}
        >
          <Logotipo />
        </IconButton>
        <Box sx={{ flexGrow: 1, ml: 3 }}>
          <Typography variant="h6">{props.titulo}</Typography>
          <Typography
            variant="body2"
            sx={{
              display: { xs: 'none', md: 'none', lg: 'flex' },
            }}
          >
            {props.subtitulo}
          </Typography>
        </Box>
        <Hidden mdDown>
          <Stack direction="row" spacing={2}>
            <Button onClick={() => navigate('/home')} color="inherit">
              Home
            </Button>
            <Button onClick={() => navigate('/cadastro')} color="inherit">
              Cadastro
            </Button>
            <Button onClick={() => navigate('/relatorios')} color="inherit">
              Relatórios
            </Button>
            {/* <Button onClick={() => navigate('/producao')} color="inherit">
              Produção
            </Button> */}
            <Button
              color="inherit"
              id="sistema-button"
              onClick={handleClick1}
              aria-controls={open ? 'sistema-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Sistema
            </Button>

            <Button
              color="inherit"
              id="resources-button"
              onClick={handleClick}
              aria-controls={open ? 'resources-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Perfil
            </Button>

            <Link
              href="/"
              color="inherit"
              variant="body2"
              underline="none"
              sx={{
                padding: '8px 0px 0px 0px',
              }}
            >
              SAIR
            </Link>
          </Stack>
        </Hidden>

        <StyledMenu
          id="sistema-menu"
          anchorEl={anchorEl1}
          open={open1}
          MenuListProps={{
            'aria-labelledby': 'sistema-button',
          }}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => navigate('/sistema/contas')}>
            <CreditCardIcon />
            Contas
          </MenuItem>
          <MenuItem onClick={() => navigate('/sistema/tarefas')}>
            <TaskIcon />
            Tarefas
          </MenuItem>

          <Divider />
          <MenuItem onClick={() => navigate('/sistema/configuracao')}>
            <EditIcon />
            Configurações
          </MenuItem>
          <MenuItem onClick={() => navigate('/sistema/documentacao')}>
            <LocalLibraryIcon />
            Documentação
          </MenuItem>
          <MenuItem onClick={() => navigate('/sistema/versoes')}>
            <MoneyIcon />
            Versões
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>Teste 1</MenuItem> */}
        </StyledMenu>

        <StyledMenu
          id="resources-menu"
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{
            'aria-labelledby': 'resources-button',
          }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => navigate('/usuarios/alterarsenha')}>
            <LockResetIcon />
            Alterar Senha
          </MenuItem>
          <MenuItem onClick={() => navigate('/usuarios/preferencias')}>
            <RoomPreferencesIcon />
            Preferências
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>Teste 1</MenuItem> */}
        </StyledMenu>

        <Hidden mdUp>
          <DrawerMenu />
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
