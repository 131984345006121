import LoginIcon from '@mui/icons-material/Login'
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { navigate } from 'hookrouter'
import { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Capa from '../../img/capa.png'
import Logo from '../../img/logo_ideiahub.png'
import { authLogin } from '../../redux/auth/authActions'

function Login() {
  const [form, setForm] = useState({ email: '', senha: '' })
  const [msgErro, setMsgErro] = useState({ email: '', senha: '' })
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  function changeForm(e) {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
    setMsgErro({ email: '', senha: '' })
  }

  const { isAuthenticated } = useSelector((state) => state.auth)
  const login = useSelector((state) => state.auth)
  //localStorage.setItem('stateAutenticacao', JSON.stringify(login))

  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault()
    if (form.email === '') {
      msgErro.email = 'Informe um e-mail válido'
    }
    if (form.senha === '') {
      msgErro.senha = 'Informe a sua senha'
    }
    dispatch(authLogin(form))
  }

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('stateAutenticacao', JSON.stringify(login))
      navigate('/home')
    }
    setIsLoading(false)
  }, [isAuthenticated, login])

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          display: { sm: 'none', md: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#003399',
          backgroundImage: `url(${Capa})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></Grid>

      <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 3,
            mx: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="secondary" />
          </Backdrop>

          <Grid item>
            <img
              src={Logo}
              alt="Logotipo Ideia Ninja"
              loading="lazy"
              width={250}
            />
          </Grid>

          <Typography variant="h5">Sistema de Administração</Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={changeForm}
              onFocus={() => (login.error = '')}
              value={form.email}
              error={!!msgErro.email}
              helperText={msgErro.email}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              type="password"
              id="senha"
              label="Senha"
              name="senha"
              autoComplete="current-password"
              onChange={changeForm}
              onFocus={() => (login.error = '')}
              value={form.senha}
              error={!!msgErro.senha}
              helperText={msgErro.senha}
            />

            {!!login.error ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error" sx={{ fontSize: '12px' }}>
                  <AlertTitle sx={{ fontSize: '15px' }}>
                    E-mail e/ou senha incorretos ou não cadastrados.
                  </AlertTitle>
                  Entre em contato com o administrador do sistema.
                </Alert>
              </Stack>
            ) : null}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!form.email || !form.senha || !!login.error}
              endIcon={<LoginIcon />}
            >
              Entrar
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    //userData: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //fetchUsers: () => dispatch(fetchUsers())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

// TUTORIAL JWT
// https://www.bezkoder.com/react-redux-jwt-auth/#Overview_of_React_Redux_JWT_Authentication_example
