import { Box } from '@mui/material'
import { useState } from 'react'
import Layout from '../../layouts'
import Formulario from './Form'
import Table from './Table'

const Servicos = (props) => {
  const [modo, setModo] = useState({ status: 'table', registro: {} })
  const [servicoAtual, setServicoAtual] = useState({})

  return (
    <Layout titulo="Serviços" subtitulo="Cadastro de serviços">
      <Box>
        {modo.status === 'table' ? (
          <Table
            setModo={setModo}
            modo={modo}
            servicoAtual={servicoAtual}
            setServicoAtual={setServicoAtual}
          />
        ) : (
          <Formulario setModo={setModo} modo={modo} />
        )}
      </Box>
    </Layout>
  )
}

export default Servicos
