// import { InputAdornment, TextField } from "@mui/material"
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'

export const FiltroGlobal = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 300)

  return (
    <span>
      <TextField
        size="small"
        margin="normal"
        id="pesquisar"
        label="Pesquisar"
        name="pesquisar"
        autoFocus
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        // inputRef={(input) => {
        //     if (input != null) {
        //         input.focus();
        //     }
        // }}
      />
    </span>
  )
}
