import { Box } from '@mui/material'
import { useState } from 'react'
// import { useSelector } from "react-redux"
import Layout from '../../layouts'
import Formulario from './Form'
import Table from './Table'

const Recebimentos = (props) => {
  const [modo, setModo] = useState({ status: 'table', registro: {} })
  const [registroAtual, setRegistroAtual] = useState({})

  return (
    <Layout titulo="Recebimentos" subtitulo="Cadastro de recebimentos">
      <Box>
        {modo.status === 'table' ? (
          <Table
            setModo={setModo}
            modo={modo}
            registroAtual={registroAtual}
            setRegistroAtual={setRegistroAtual}
          />
        ) : (
          <Formulario setModo={setModo} modo={modo} />
        )}
      </Box>
    </Layout>
  )
}

export default Recebimentos
