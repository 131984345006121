import { createStyles, makeStyles } from '@material-ui/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  fetchPagamentos,
  savePagamento,
} from '../../../redux/cadastro/pagamentoSlice'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchProjetos } from '../../../redux/cadastro/projetoSlice'
import { fetchServicos } from '../../../redux/cadastro/servicoSlice'
import { fetchOrigemPagamento } from '../../../redux/cadastro/tabelas/origemPagamentoSlice'
import { fetchStatusPagamento } from '../../../redux/cadastro/tabelas/statusPagamentoSlice'
import { fetchTiposPagamento } from '../../../redux/cadastro/tabelas/tiposPagamentoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        border: '2px solid black',
        fontSize: 12,
        zIndex: 9999,
        '& li:nth-child(even)': { backgroundColor: '#F2F2F2' },
        '& li:nth-child(odd)': { backgroundColor: '#FFF' },
      },
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  })
)

const CustomPopper = (props) => {
  const classes = useStyles()
  return (
    <Popper
      sx={{ zIndex: 999 }}
      {...props}
      className={classes.root}
      placement="bottom"
    />
  )
}

function Formulario(props) {
  const { modo, setModo } = props
  //console.log('modo inicial', modo)
  const [openAviso, setOpenAviso] = useState(false)
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [optionClienteSelecionado, setOptionClienteSelecionado] = useState({})
  const [optionFornecedorSelecionado, setOptionFornecedorSelecionado] =
    useState({})
  const [optionProjetoSelecionado, setOptionProjetoSelecionado] = useState({})
  const [optionServicoSelecionado, setOptionServicoSelecionado] = useState({})
  const [
    optionStatusPagamentoSelecionado,
    setOptionStatusPagamentoSelecionado,
  ] = useState({})
  const [optionNivelDeAcessoSelecionado, setOptionNivelDeAcessoSelecionado] =
    useState({})
  const [optionTipoPagamentoSelecionado, setOptionTipoPagamentoSelecionado] =
    useState({})
  const [
    optionOrigemPagamentoSelecionado,
    setOptionOrigemPagamentoSelecionado,
  ] = useState({})
  const [projetosOptions, setProjetosOptions] = useState([])
  const [servicosOptions, setServicosOptions] = useState([])
  const [dialogError, setDialogError] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStatusPagamento())
    dispatch(fetchTiposPagamento())
    dispatch(fetchOrigemPagamento())
    dispatch(fetchPessoas())
    dispatch(fetchProjetos())
    dispatch(fetchServicos())

    setOptionClienteSelecionado({
      value: modo.registro?.projeto?.cliente.id || '',
      label: modo.registro?.projeto?.cliente.nome || '',
    })

    setOptionFornecedorSelecionado({
      value: modo.registro?.fornecedor?.id || '',
      label: modo.registro?.fornecedor?.nome || '',
    })

    setOptionProjetoSelecionado({
      value: modo.registro?.projeto?.id || '',
      label: modo.registro?.projeto?.nome || '',
    })

    setOptionServicoSelecionado({
      value: modo.registro?.servico?.id || '',
      label: modo.registro?.servico?.nome || '',
    })

    setOptionNivelDeAcessoSelecionado({
      value: modo.registro?.nivel_acesso || '',
      label:
        modo.registro?.nivel_acesso === 1
          ? 'Adminstrador'
          : modo.registro?.nivel_acesso === 2
            ? 'Gerente'
            : 'Operador',
    })

    setOptionStatusPagamentoSelecionado({
      value: modo.registro?.statusPagamento?.id || '',
      label: modo.registro?.statusPagamento?.nome || '',
    })

    setOptionOrigemPagamentoSelecionado({
      value: modo.registro?.origemPagamento?.id || '',
      label: modo.registro?.origemPagamento?.nome || '',
    })

    setOptionTipoPagamentoSelecionado({
      value: modo.registro?.tipoPagamento?.id || '',
      label: modo.registro?.tipoPagamento?.nome || '',
    })

    setOptionOrigemPagamentoSelecionado({
      value: modo.registro?.origemPagamento?.id || '',
      label: modo.registro?.origemPagamento?.nome || '',
    })
  }, [dispatch, modo])

  const statusPagamento = useSelector(
    (state) => state.statusPagamento.registros
  )
  const tiposPagamento = useSelector((state) => state.tiposPagamento.registros)
  const origemPagamento = useSelector(
    (state) => state.origemPagamento.registros
  )
  const pessoas = useSelector((state) => state.pessoas.registros)
  const projetos = useSelector((state) => state.projetos.registros)
  const servicos = useSelector((state) => state.servicos.registros)
  let clientes = pessoas.filter(
    (p) => p.grupo_pessoa_id === 2 || p.grupo_pessoa_id === 4
  )
  let fornecedores = pessoas.filter(
    (p) => p.grupo_pessoa_id === 3 || p.grupo_pessoa_id === 4
  )
  clientes.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
  fornecedores.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))

  const statusPagamentoOptions = statusPagamento?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const tiposPagamentoOptions = tiposPagamento?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const origemPagamentoOptions = origemPagamento?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const nivelAcessoOptions = [
    {
      value: 1,
      label: 'Adminstrador',
    },
    {
      value: 2,
      label: 'Gerente',
    },
    {
      value: 3,
      label: 'Operador',
    },
  ]

  const clientesOptions = clientes?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const fornecedoresOptions = fornecedores?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const atualizarProjetos = (cliente_id, inicial) => {
    if (!inicial) {
      setOptionProjetoSelecionado([])
    }
    const ps = projetos.filter((el) => el.cliente_id === cliente_id)
    ps.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
    const ppC = ps.map((item) => {
      let op = {}
      if (item.id) {
        op = {
          value: item.id,
          label: item.nome,
        }
      }
      return op
    })
    setProjetosOptions(ppC)
  }

  const atualizarServicos = (projeto_id, inicial) => {
    if (!inicial) {
      setOptionServicoSelecionado([])
    }
    const ps = servicos.filter((el) => el.projeto_id === projeto_id)
    ps.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
    const ppC = ps.map((item) => {
      let op = {}
      if (item.id) {
        op = {
          value: item.id,
          label: item.nome,
        }
      }
      return op
    })
    setServicosOptions(ppC)
  }

  useEffect(() => {
    if (optionClienteSelecionado) {
      atualizarProjetos(optionClienteSelecionado.value, true)
    }

    if (optionProjetoSelecionado) {
      atualizarServicos(optionProjetoSelecionado.value, true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modo,
    optionClienteSelecionado,
    optionFornecedorSelecionado,
    optionProjetoSelecionado,
  ])

  const validationSchema = Yup.object({
    fornecedor_id: Yup.string().required('Informe o nome do fornecedor'),
    cliente_id: Yup.number('O valor não é numérico').required(
      'Informe o nome do cliente'
    ),

    projeto_id: Yup.number('O valor não é numérico').required(
      'Informe o projeto'
    ),

    servico_id: Yup.number('O valor não é numérico').required(
      'Informe o serviço'
    ),

    tipo_pagamento_id: Yup.number('O valor não é numérico').required(
      'Informe o tipo de pagamento'
    ),

    data_vencimento: Yup.date('O valor não é uma data válida').required(
      'Informe a data de vencimento'
    ),

    data_pagamento: Yup.date().when('status_pagamento_id', {
      is: (value) => value && value === 2,
      then: Yup.date('O valor não é uma data válida').required(
        'Para o status "Pago" é necessário preencher a data de pagamento'
      ),
    }),

    origem_pagamento_id: Yup.number().when('status_pagamento_id', {
      is: (value) => value && value === 2,
      then: Yup.number('O valor não é um numero').required(
        'Para o status "Pago" é necessário preencher a origem do pagamento'
      ),
    }),

    status_pagamento_id: Yup.number().required(
      'O status do pagamento precisa ser informado.'
    ),

    valor: Yup.number('O valor informado não é válido (Ex.: 1123,00)').required(
      'Informe o valor do pagmento'
    ),

    historico: Yup.string().required(
      'É necessário informar o histórico do pagamento.'
    ),
  })

  const onSubmit = async (values, onSubmitProps) => {
    const res = await dispatch(savePagamento(values))

    if (!!res?.error) {
      setDialogError(
        `Adicionar serviço: ${res.error?.code} - ${res.error?.message}`
      )
      setOpenDialogErro(true)
    } else {
      setOpenAviso(true)
      onSubmitProps.resetForm()
      limparForm(onSubmitProps.resetForm)
      //props.setClassificado(false)
      if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
    }

    if (modo.status !== 'add') {
      dispatch(fetchPagamentos())
      setModo({ status: 'table', registro: {} })
    }
  }

  const limparForm = (resetForm) => {
    resetForm()
    setOptionClienteSelecionado({})
    setOptionFornecedorSelecionado({})
    setOptionProjetoSelecionado({})
    setOptionServicoSelecionado({})
    setOptionStatusPagamentoSelecionado({})
    setOptionTipoPagamentoSelecionado({})
    setOptionOrigemPagamentoSelecionado({})
  }

  //console.log('modo', modo)

  const initialValues = {
    id: modo.registro?.id || '',
    fornecedor_id: modo.registro?.fornecedor_id || '',
    cliente_id: modo.registro?.projeto?.cliente.id || '',
    projeto_id: modo.registro?.projeto_id || '',
    servico_id: modo.registro?.servico_id || '',
    tipo_pagamento_id: modo.registro?.tipo_pagamento_id || '',
    origem_pagamento_id: modo.registro?.origem_pagamento_id || '',
    status_pagamento_id: modo.registro?.status_pagamento_id || '',
    data_vencimento: modo.registro?.data_vencimento || '',
    data_pagamento: modo.registro?.data_pagamento || '',
    valor: modo.registro?.valor || '',
    historico: modo.registro?.historico || '',
    nivel_acesso: modo.registro.nivel_acesso || '',
  }

  return (
    <>
      <Card sx={{ width: '100%' }}>
        {modo.status !== 'classificar' ? (
          <CardHeader
            title={
              modo.status === 'add'
                ? 'Incluir um novo pagamento'
                : 'Alterar Pagamento'
            }
            subtitle={props.value}
          ></CardHeader>
        ) : null}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            formik,
            values,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            resetForm,
            defaultValues,
          }) => {
            return (
              <Form noValidate>
                <CardContent>
                  <Grid
                    container
                    rowSpacing={{ xs: 2, sm: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                  >
                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="fornecedor_id"
                        name="fornecedor_id"
                        size="small"
                        noOptionsText={'Nenhuma opção disponível'}
                        options={fornecedoresOptions}
                        value={optionFornecedorSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionFornecedorSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setOptionFornecedorSelecionado(newValue)
                          setFieldValue(
                            'fornecedor_id',
                            !!newValue ? newValue.value : ''
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Fornecedor"
                            value={values.fornecedor_id}
                            error={
                              touched.fornecedor_id &&
                              Boolean(errors.fornecedor_id)
                            }
                            helperText={
                              touched.fornecedor_id && errors.fornecedor_id
                            }
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="cliente_id"
                        name="cliente_id"
                        size="small"
                        noOptionsText={'Nenhuma opção disponível'}
                        options={clientesOptions}
                        value={optionClienteSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionClienteSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setOptionClienteSelecionado(newValue)
                          setFieldValue(
                            'cliente_id',
                            !!newValue ? newValue.value : ''
                          )
                          atualizarProjetos(
                            !!newValue ? newValue.value : '',
                            false
                          )
                          atualizarServicos([], false)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Cliente"
                            value={values.cliente_id || ''}
                            error={
                              touched.cliente_id && Boolean(errors.cliente_id)
                            }
                            helperText={touched.cliente_id && errors.cliente_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        noOptionsText={
                          <Box sx={{ border: '1px solid black', p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="div"
                            >
                              <strong>Nenhuma opção disponível</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom mb={2}>
                              Certifique-se de que o cliente foi selecionado e
                              de que o projeto está cadastrado.
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Button
                                sx={{ textAlign: 'center' }}
                                component="a"
                                href="/cadastro/projetos"
                                variant="contained"
                                size="small"
                              >
                                Cadastrar Projeto
                              </Button>
                            </Box>
                          </Box>
                        }
                        options={projetosOptions}
                        value={optionProjetoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionProjetoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setOptionProjetoSelecionado(newValue)
                          setFieldValue(
                            'projeto_id',
                            !!newValue ? newValue.value : ''
                          )
                          atualizarServicos(
                            !!newValue ? newValue.value : '',
                            false
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            value={values.projeto_id || ''}
                            label="Projeto"
                            error={
                              touched.projeto_id && Boolean(errors.projeto_id)
                            }
                            helperText={touched.projeto_id && errors.projeto_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        noOptionsText={
                          <Box sx={{ border: '1px solid black', p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="div"
                            >
                              <strong>Nenhuma opção disponível</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom mb={2}>
                              Certifique-se de que o projeto esteja selecionado
                              e/ou de que o serviço está cadastrado.
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Button
                                sx={{ textAlign: 'center' }}
                                component="a"
                                href="/cadastro/servicos"
                                variant="contained"
                                size="small"
                              >
                                Cadastrar Serviço
                              </Button>
                            </Box>
                          </Box>
                        }
                        options={servicosOptions}
                        value={optionServicoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionServicoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'servico_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionServicoSelecionado(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            value={values.servico_id}
                            label="Servico"
                            error={
                              touched.servico_id && Boolean(errors.servico_id)
                            }
                            helperText={touched.servico_id && errors.servico_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="tipo_pagamento_id"
                        name="tipo_pagamento_id"
                        options={tiposPagamentoOptions}
                        value={optionTipoPagamentoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionTipoPagamentoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'tipo_pagamento_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionTipoPagamentoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.tipo_pagamento_id}
                            label="Tipo de Pagamento"
                            error={
                              touched.tipo_pagamento_id &&
                              Boolean(errors.tipo_pagamento_id)
                            }
                            helperText={
                              touched.tipo_pagamento_id &&
                              errors.tipo_pagamento_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="status_pagamento_id"
                        name="status_pagamento_id"
                        options={statusPagamentoOptions}
                        value={optionStatusPagamentoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionStatusPagamentoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'status_pagamento_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionStatusPagamentoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.status_pagamento_id}
                            label="Status"
                            error={
                              touched.status_pagamento_id &&
                              Boolean(errors.status_pagamento_id)
                            }
                            helperText={
                              touched.status_pagamento_id &&
                              errors.status_pagamento_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="origem_pagamento_id"
                        name="origem_pagamento_id"
                        options={origemPagamentoOptions}
                        value={optionOrigemPagamentoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionOrigemPagamentoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'origem_pagamento_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionOrigemPagamentoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.origem_pagamento_id}
                            label="Origem"
                            error={
                              touched.origem_pagamento_id &&
                              Boolean(errors.origem_pagamento_id)
                            }
                            helperText={
                              touched.origem_pagamento_id &&
                              errors.origem_pagamento_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        required
                        type="date"
                        id="data_vencimento"
                        name="data_vencimento"
                        label="Data de Vencimento"
                        fullWidth
                        size="small"
                        format="MM/dd/yyyy"
                        value={values.data_vencimento}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.data_vencimento &&
                          Boolean(errors.data_vencimento)
                        }
                        helperText={
                          touched.data_vencimento && errors.data_vencimento
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        required
                        id="valor"
                        name="valor"
                        label="Valor"
                        type="number"
                        fullWidth
                        value={values.valor}
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                        error={touched.valor && Boolean(errors.valor)}
                        helperText={touched.valor && errors.valor}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="date"
                        id="data_pagamento"
                        name="data_pagamento"
                        label="Data de pagamento"
                        fullWidth
                        size="small"
                        format="MM/dd/yyyy"
                        value={values.data_pagamento}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.data_pagamento &&
                          Boolean(errors.data_pagamento)
                        }
                        helperText={
                          touched.data_pagamento && errors.data_pagamento
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        id="historico"
                        name="historico"
                        label="Histórico"
                        fullWidth
                        multiline
                        rows={3}
                        value={values.historico}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.historico && Boolean(errors.historico)}
                        helperText={touched.historico && errors.historico}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                      <Autocomplete
                        disablePortal
                        id="
                        nivel_acesso"
                        name="nivel_acesso"
                        options={nivelAcessoOptions}
                        value={optionNivelDeAcessoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionNivelDeAcessoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionNivelDeAcessoSelecionado(newValue)
                          setFieldValue(
                            'nivel_acesso',
                            !!newValue ? newValue.value || '' : ''
                          )
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Nivel de acesso" />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ ml: 1 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={<SaveIcon />}
                  >
                    {modo.status === 'add' ? 'Salvar' : 'Alterar'}
                  </Button>
                  {modo.status === 'add' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => limparForm(resetForm)}
                      startIcon={<ClearIcon />}
                    >
                      Limpar
                    </Button>
                  ) : null}
                  {modo.status !== 'classificar' ? (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => setModo({ status: 'table', registro: {} })}
                      startIcon={<ArrowBackIcon />}
                    >
                      VOLTAR
                    </Button>
                  ) : null}
                </CardActions>
              </Form>
            )
          }}
        </Formik>
      </Card>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Novo usuário ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir o serviço. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </>
  )
}

export default Formulario
