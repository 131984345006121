import { Grid, List, Typography } from '@mui/material'
import Layout from '../layouts'
import ItemCadastro from './itemCadastro'
import { styled } from '@mui/material/styles'

const itensCadastro = [
  {
    id: 1,
    icon: '<FolderIcon/>',
    rota: '/cadastro/pessoas',
    titulo: 'PESSOAS',
    descricao:
      'Cadastro de todas as pessoas tais como: Clientes, fornecedores, usuários do sistema, colaboradores.',
  },
  {
    id: 2,
    icon: '',
    rota: '/cadastro/projetos',
    titulo: 'PROJETOS',
    descricao:
      'Cadastro dos projetos criados para atendimento às necessidade dos clientes e da administração.',
  },
  {
    id: 3,
    icon: '',
    rota: '/cadastro/servicos',
    titulo: 'SERVIÇOS',
    descricao:
      'Cadastro dos serviços contratados pelos clientes e necessários à administração.',
  },
  {
    id: 4,
    icon: '',
    rota: '/cadastro/recebimentos',
    titulo: 'RECEBIMENTOS',
    descricao: 'Cadastro dos recebimentos realizados e a realizar.',
  },
  {
    id: 5,
    icon: '',
    rota: '/cadastro/pagamentos',
    titulo: 'PAGAMENTOS',
    descricao: 'Cadastro dos pagamentos realizados e a realizar.',
  },
  // { id: 6, icon: '', rota: '/dashboard', titulo: 'Dashboard', descricao: 'Gráficos e dados gerenciais.' },
  {
    id: 7,
    icon: '',
    rota: '/usuarios',
    titulo: 'USUÁRIOS',
    descricao: 'Usuários do sistema.',
  },
  {
    id: 8,
    icon: '',
    rota: '/cadastro/tabelas',
    titulo: 'TABELAS E PARÂMETROS',
    descricao: 'Todas as tabelas e parâmetros utilizados pelo sistema.',
  },
]
itensCadastro.sort((r1, r2) => (r1.titulo > r2.titulo ? 1 : -1))

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}))

function Cadastro() {
  return (
    <Layout
      titulo="Cadastro"
      subtitulo="Acesso às tabelas de cadastro do sistema"
    >
      <Grid container spacing={2} display="flex" flexDirection={'column'}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Cadastro do sistema
          </Typography>
        </Grid>
        <Demo>
          <List dense={true}>
            <Grid container>
              {itensCadastro.map((item) => (
                <Grid item xs={12} md={6} lg={4} key={item.id}>
                  <ItemCadastro key={item.id} item={item} />
                </Grid>
              ))}
            </Grid>
          </List>
        </Demo>
      </Grid>
    </Layout>
  )
}

export default Cadastro
