import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { valorInteiro, valorMoeda } from '../../../funcoes/data'
import { fetchFornecedores } from '../../../redux/controleGeral/controleGeralSlice'

export const Fornecedores = (props) => {
  const { fornecedores, atualizarFornecedores } = props

  useEffect(() => {
    atualizarFornecedores()
  }, [atualizarFornecedores])

  const totalizarQtde = () => {
    return fornecedores.dados.reduce((a, b) => a + parseInt(b.QTDE), 0)
  }

  const totalizarValor = () => {
    return fornecedores.dados.reduce((a, b) => a + parseFloat(b.VALORPAGO), 0.0)
  }

  const totalizarValorPrevisto = () => {
    return fornecedores.dados.reduce(
      (a, b) => a + parseFloat(b.VALORTOTAL),
      0.0
    )
  }

  return (
    <Box sx={{ mb: 10 }}>
      {fornecedores.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : fornecedores.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {fornecedores.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Fornecedores
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell>OR</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>FORNECEDOR</TableCell>
                  <TableCell align="right">QTDE</TableCell>
                  <TableCell align="right" sx={{ width: 120 }}>
                    VALOR PREVISTO
                  </TableCell>
                  <TableCell align="right" sx={{ width: 120 }}>
                    VALOR PAGO
                  </TableCell>
                  <TableCell align="right" sx={{ width: 120 }}>
                    A PAGAR
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fornecedores.dados &&
                  fornecedores.dados.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.ID}</TableCell>
                      <TableCell>{row.TITULO}</TableCell>
                      <TableCell align="right">
                        {valorInteiro(row.QTDE)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALORTOTAL)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALORPAGO)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.AREALIZAR)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow
                  py={10}
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell colSpan={3}>TOTAL</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorInteiro(totalizarQtde())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValorPrevisto())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValor())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValorPrevisto() - totalizarValor())}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    fornecedores: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarFornecedores: () => dispatch(fetchFornecedores()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fornecedores)
