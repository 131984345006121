import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { Select } from 'formik-mui'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  changeAbasPessoa,
  selectAbasPessoa,
} from '../../../redux/auxiliar/abasPessoaSlice'
import {
  fetchPessoa,
  savePessoa,
} from '../../../redux/cadastro/pessoa/pessoaSlice'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const FormDadosGerais = (props) => {
  const { pessoaAtual } = props
  const dispatch = useDispatch()
  const abasPessoa = useSelector(selectAbasPessoa)
  let abasPessoaCopia = JSON.parse(JSON.stringify(abasPessoa))

  const validationSchema = Yup.object({
    nome: Yup.string().required('Informe o nome da pessoa'),
    grupo_pessoa_id: Yup.string().required('Informe o grupo de pessoas'),
    tipo_pessoa: Yup.number('O valor não é numérico').required(
      'Informe o tipo de pessoa'
    ),
    status_id: Yup.number('O valor não é numérico').required(
      'Informe o status da pessoa'
    ),
  })

  const gruposPessoas = useSelector((state) => state.gruposPessoas.registros)
  const gruposPessoasOptions = gruposPessoas.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })
  const tiposPessoa = useSelector((state) => state.tiposPessoa.registros)
  const tiposPessoaOptions = tiposPessoa.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })
  const status = useSelector((state) => state.status.registros)
  const statusOptions = status.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const handleSubmit = async (values) => {
    const formData = new FormData()
    formData.append('image', values.imagem)

    const registro = {
      id: values.id,
      grupo_pessoa_id: values.grupo_pessoa_id,
      tipo_pessoa: values.tipo_pessoa,
      nome: values.nome,
      imagem: '' /*resUpload.payload.filename || ''*/,
      status_id: values.status_id,
    }
    const savePessoaRes = await dispatch(savePessoa(registro))
    await dispatch(fetchPessoa(savePessoaRes.payload.id))
    props.setAlterado(true)

    if (parseInt(registro.tipo_pessoa) === 1) {
      abasPessoaCopia.status.pessoaFisica = false
      props.setValue(1)
    } else {
      abasPessoaCopia.status.pessoaJuridica = false
      props.setValue(2)
    }

    dispatch(changeAbasPessoa(abasPessoaCopia.status))
  }

  return (
    <Grid container justify="center" flexDirection="column" spacing={1}>
      <Grid item sx={{ width: '100%' }}>
        <Card sx={{ width: '100%' }}>
          <CardHeader
            title="Dados gerais da pessoa"
            subtitle={props?.value}
          ></CardHeader>
          <Formik
            initialValues={{
              id: pessoaAtual?.id || '',
              grupo_pessoa_id: pessoaAtual?.grupo_pessoa_id || '',
              tipo_pessoa: pessoaAtual?.tipo_pessoa || '',
              nome: pessoaAtual?.nome || '',
              imagem: pessoaAtual?.imagem || '',
              status_id: pessoaAtual?.status_id || '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await handleSubmit(values)
            }}
          >
            {({
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form noValidate>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '15px',
                      }}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        id="nome"
                        name="nome"
                        label="Nome"
                        value={values?.nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.nome && Boolean(errors.nome)}
                        helperText={touched.nome && errors.nome}
                      />
                      <Field
                        as="select"
                        size="small"
                        id="grupo_pessoa_id"
                        label="Grupo de Pessoas"
                        name="grupo_pessoa_id"
                        sx={{ mt: 1 }}
                        component={Select}
                      >
                        {gruposPessoasOptions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        as="select"
                        size="small"
                        id="tipo_pessoa"
                        label="Tipo de Pessoa"
                        name="tipo_pessoa"
                        sx={{ mt: 1 }}
                        component={Select}
                      >
                        {tiposPessoaOptions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        as="select"
                        size="small"
                        id="status_id"
                        label="Status"
                        name="status_id"
                        sx={{ mt: 1 }}
                        component={Select}
                      >
                        {statusOptions.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ ml: 1 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      startIcon={<SaveIcon />}
                    >
                      Salvar
                    </Button>
                    {props.modo !== 'edicao' ? (
                      <Button
                        type="reset"
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                      >
                        Limpar
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="warning"
                      type="reset"
                      onClick={() => props.setModo('table')}
                      startIcon={<ArrowBackIcon />}
                    >
                      VOLTAR
                    </Button>
                  </CardActions>
                </Form>
              )
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FormDadosGerais
