import { Close, DeleteForever } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteNegociacao,
  deleteRegistro,
} from '../../../redux/cadastro/negociacaoSlice'

const Excluir = (props) => {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { registro } = props

  const handleDelete = async (registro) => {
    await dispatch(deleteNegociacao(registro))
    await dispatch(deleteRegistro({ id: registro.id }))
    handleClose(true)
  }

  return (
    <span>
      <IconButton onClick={handleOpen}>
        <DeleteForever color="error" sx={{ cursor: 'pointer' }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pr: 1,
            pl: 4,
            bgcolor: 'background.warning',
            color: 'white',
          }}
        >
          Atenção!
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 4, mt: 2 }}>
          <p>
            Você excluirá do cadastro o registro do Gerador Automático de
            Parcelas.
          </p>
          <p>Confirma?</p>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            pr: 1,
            pl: 4,
            bgcolor: 'background.warning',
            color: 'white',
          }}
        >
          <Button
            variant="contained"
            color="third"
            onClick={() => handleDelete(registro)}
          >
            Sim
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  )
}

export default Excluir
