import { Close, DeleteForever, Warning } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Tooltip,
} from '@mui/material'
import { orange } from '@mui/material/colors'
import React, { useState } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogExclusao(props) {
  const { title, referencia, setIdExclusao, idExclusao, handleExcluir } = props

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Tooltip
        title={<Box sx={{ fontSize: '0.9rem' }}>Excluir</Box>}
        placement="top"
      >
        <IconButton color="primary" onClick={() => handleOpen()} size="small">
          <DeleteForever sx={{ color: 'red' }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle sx={{ display: 'flex', gap: 2 }}>
          <Warning sx={{ color: orange[900], fontSize: 30 }} />
          {'  '}
          {'Atenção'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <p>{`Você excluirá ${title} do cadastro:`}</p>
          <p>{`${idExclusao} - ${referencia} `}</p>
          <p>Confirma?</p>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mr: 2, mb: 1 }}>
          <Button
            variant="contained"
            color="third"
            onClick={() => {
              setIdExclusao(idExclusao)
              handleExcluir(idExclusao)
            }}
          >
            Sim
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
