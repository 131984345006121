import { Box } from '@mui/system'
import React from 'react'
import { connect } from 'react-redux'
import PainelTarefas from '../../painelTarefas'
import BarraSuperior from './barraSuperior'

export const PainelAtividades = (props) => {
  const { atividade } = props
  console.log('Atividade no painel: ', atividade)
  return (
    <Box>
      <BarraSuperior atividade={atividade} />
      <PainelTarefas />
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PainelAtividades)
