import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  List,
  Box,
  Divider,
  Collapse,
  ListItemButton,
} from '@mui/material'
import { useState } from 'react'
import { navigate } from 'hookrouter'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import GroupsIcon from '@mui/icons-material/Groups'
import TaskIcon from '@mui/icons-material/Task'
// import InboxIcon from '@mui/icons-material/Inbox';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { atividades } from './atividades/atividadesData'
import AtividadesMenu from './atividades/atividadesMenu'

export default function MenuLateral(props) {
  const { setPainel, setAtividade } = props

  //console.log('atividades: ', atividades)

  const [openMenuLateral, setOpenMenuLateral] = useState(false)
  const [openFixado, setOpenFixado] = useState(true)
  const [openTodos, setOpenTodos] = useState(true)

  const handleClickFixado = () => {
    setOpenFixado(!openFixado)
  }

  const handleClickTodos = () => {
    setOpenTodos(!openTodos)
  }

  const handlePainel = (painel, atividade) => {
    setPainel(painel)
    setAtividade(atividade)
    setOpenMenuLateral(false)
    console.log('atividade: ', atividade)
  }

  const atividadesFixadas = atividades.filter((a) => a.fixado === true)
  const atividadesTodas = atividades.filter((a) => a.fixado === false)

  //console.log('atividadesFixadas: ', atividadesFixadas)
  //console.log('atividadesTodas: ', atividadesTodas)

  return (
    <Box>
      <IconButton
        color="primary"
        component="label"
        onClick={() => {
          setOpenMenuLateral(true)
        }}
      >
        <MenuOpenIcon />
      </IconButton>

      <Drawer
        open={openMenuLateral}
        anchor={'left'}
        onClose={() => setOpenMenuLateral(false)}
      >
        <List dense sx={{ width: '250px' }}>
          <Box display={'flex'} justifyContent={'right'}>
            <IconButton onClick={() => setOpenMenuLateral(false)}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Divider />
          <ListItem
            button
            key={1}
            onClick={() => navigate('/sistema/tarefas/atividades')}
          >
            <ListItemIcon>
              <WorkspacesIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Atividades'} />
          </ListItem>
          <ListItem
            button
            key={2}
            onClick={() => navigate('/sistema/tarefas/equipes')}
          >
            <ListItemIcon>
              <GroupsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Equipes'} />
          </ListItem>
          {/* <ListItem button key={23} onClick={() => navigate('/sistema/tarefas/minhasTarefas')}> */}
          <ListItem button key={23} onClick={() => handlePainel(1)}>
            <ListItemIcon>
              <TaskIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Minhas Tarefas'} />
          </ListItem>
          <Divider />
          <ListItemButton onClick={handleClickFixado}>
            {/* <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon> */}
            <ListItemText primary="Fixado" />
            {openFixado ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openFixado} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              {atividadesFixadas.map((af) => (
                <ListItemButton
                  key={af.id}
                  onClick={() => handlePainel(2, af)} /*sx={{ pl: 4 }}*/
                >
                  <ListItemIcon>
                    <StarBorderIcon />
                  </ListItemIcon>
                  <ListItemText primary={af.titulo} secondary={af.subTitulo} />
                  <AtividadesMenu atividade={af} tipo="fixado" />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <ListItemButton onClick={handleClickTodos}>
            {/* <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon> */}
            <ListItemText primary="Todos" />
            {openTodos ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={openTodos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
              {atividadesTodas.map((at) => (
                <ListItemButton key={at.id} /*sx={{ pl: 4 }}*/>
                  <ListItemIcon>
                    <StarBorderIcon />
                  </ListItemIcon>
                  <ListItemText primary={at.titulo} secondary={at.subTitulo} />
                  <AtividadesMenu atividade={at} tipo="todos" />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
      </Drawer>
    </Box>
  )
}
