import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../middleware/jwtInterceptor'
import api from '../../services/api'

const initialState = {
  loading: true,
  registros: [],
  registroAtual: {},
  error: '',
}

export const fetchUsuarios = createAsyncThunk('cadastro/usuarios', () => {
  const autorizacao = header()
  return api.get('usuarios/lista', autorizacao).then((res) => res.data)
})

export const saveUsuario = createAsyncThunk(
  'cadastro/usuarios/saveUsuario',
  (registro) => {
    const autorizacao = header()
    return api
      .post(`/usuarios/salvar`, registro, autorizacao)
      .then((res) => res.data)
  }
)

export const deleteUsuario = createAsyncThunk(
  'cadastro/usuarios/excluirUsuario',
  (id) => {
    const autorizacao = header()
    return api.get(`/usuarios/excluir/${id}`, autorizacao).then((res) => res)
  }
)

export const autenticar = createAsyncThunk(
  'cadastro/usuarios/autenticado',
  (dados) => {
    const autorizacao = header()
    return api
      .post(`/users/authLogin`, dados, autorizacao)
      .then((res) => res.data)
  }
)

export const salvarNovaSenha = createAsyncThunk(
  'cadastro/usuarios/salvarNovaSenha',
  (dados) => {
    const autorizacao = header()
    return api
      .post('/usuarios/salvarNovaSenha', dados, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'usuarios',
  initialState,
  reducers: {
    resetRegistros: (state, action) => {
      state.registros = []
    },
    deleteRegistro: (state, action) => {
      const id = action.payload
      state.registros = state.registros.filter((registro) => registro.id !== id)
    },
    updateRegistro: (state, action) => {
      state.registros[action.payload.id] = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsuarios.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUsuarios.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchUsuarios.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    builder.addCase(saveUsuario.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveUsuario.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(saveUsuario.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })

    builder.addCase(deleteUsuario.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteUsuario.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(deleteUsuario.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })
    // Autenticar
    builder.addCase(autenticar.pending, (state) => {
      state.loading = true
    })
    builder.addCase(autenticar.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(autenticar.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })
    // Salvar nova senha
    builder.addCase(salvarNovaSenha.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarNovaSenha.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(salvarNovaSenha.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })
  },
})

export const { resetRegistros, deleteRegistro, updateRegistro } = slice.actions
export default slice.reducer
