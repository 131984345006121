import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import TableTiposConta from './tableTiposConta'

export const TiposConta = (props) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom align="center" mt={1}>
        Tipos de Conta
      </Typography>
      <Divider sx={{ mb: '10px' }} />
      <TableTiposConta />
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TiposConta)
