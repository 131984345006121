import { createStyles, makeStyles } from '@material-ui/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchProjetos } from '../../../redux/cadastro/projetoSlice'
import {
  fetchServicos,
  saveServico,
} from '../../../redux/cadastro/servicoSlice'
import { fetchPeriodicidadeServico } from '../../../redux/cadastro/tabelas/periodicidadeServicoSlice'
import { fetchStatusServico } from '../../../redux/cadastro/tabelas/statusServicoSlice'
import { fetchTiposServico } from '../../../redux/cadastro/tabelas/tiposServicoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        border: '2px solid black',
        fontSize: 12,
        zIndex: 9999,
        '& li:nth-child(even)': { backgroundColor: '#F2F2F2' },
        '& li:nth-child(odd)': { backgroundColor: '#FFF' },
      },
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  })
)

const CustomPopper = (props) => {
  const classes = useStyles()
  return (
    <Popper
      sx={{ zIndex: 999 }}
      {...props}
      className={classes.root}
      placement="bottom"
    />
  )
}

function Formulario(props) {
  const { modo, setModo } = props
  const [openAviso, setOpenAviso] = useState(false)
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [dialogError, setDialogError] = useState('')
  const [optionClienteSelecionado, setOptionClienteSelecionado] = useState({})
  const [optionProjetoSelecionado, setOptionProjetoSelecionado] = useState({})
  const [projetosOptions, setProjetosOptions] = useState([])
  const [optionTipoServicoSelecionado, setOptionTipoServicoSelecionado] =
    useState({})
  const [optionStatusServicoSelecionado, setOptionStatusServicoSelecionado] =
    useState({})
  const [
    optionPeriodicidadeServicoSelecionado,
    setOptionPeriodicidadeServicoSelecionado,
  ] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTiposServico())
    dispatch(fetchStatusServico())
    dispatch(fetchPeriodicidadeServico())
    dispatch(fetchPessoas())
    dispatch(fetchProjetos())

    setOptionClienteSelecionado({
      value: modo.registro?.cliente?.id || '',
      label: modo.registro?.cliente?.nome || '',
    })

    setOptionProjetoSelecionado({
      value: modo.registro?.projeto?.id || '',
      label: modo.registro?.projeto?.nome || '',
    })

    setOptionTipoServicoSelecionado({
      value: modo.registro?.tipoServico?.id || '',
      label: modo.registro?.tipoServico?.nome || '',
    })
    setOptionStatusServicoSelecionado({
      value: modo.registro?.statusServico?.id || '',
      label: modo.registro?.statusServico?.nome || '',
    })

    setOptionPeriodicidadeServicoSelecionado({
      value: modo.registro?.periodicidadeServico?.id || '',
      label: modo.registro?.periodicidadeServico?.nome || '',
    })
  }, [dispatch, modo])

  const tiposServico = useSelector((state) => state.tiposServico.registros)
  const statusServico = useSelector((state) => state.statusServico.registros)
  const periodicidadeServico = useSelector(
    (state) => state.periodicidadeServico.registros
  )
  const pessoas = useSelector((state) => state.pessoas.registros)
  const projetos = useSelector((state) => state.projetos.registros)
  let clientes = [...pessoas]

  clientes.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
  clientes = clientes.filter((el) => el.deleted_at === null)

  const tiposServicoOptions = tiposServico?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const statusServicoOptions = statusServico?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const periodicidadeServicoOptions = periodicidadeServico?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const clientesOptions = clientes?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const validationSchema = Yup.object({
    tipo_servico_id: Yup.number('O valor não é numérico').required(
      'Informe o tipo de serviço'
    ),
    cliente_id: Yup.number('O valor não é numérico').required(
      'Informe o cliente do serviço'
    ),
    projeto_id: Yup.number('O valor não é numérico').required(
      'Informe o projeto do cliente'
    ),
    nome: Yup.string()
      .min(3, 'Mínimo de 3 carcateres')
      .max(255, 'Máximo de 255 carcateres')
      .required('Informe o título do serviço'),
    descricao: Yup.string()
      .min(3, 'Mínimo de 3 carcateres')
      .max(500, 'Máximo de 500 carcateres')
      .required('Informe a descrição do serviço'),
    status_servico_id: Yup.number('O valor não é numérico').required(
      'Informe o status do serviço'
    ),
    periodicidade_id: Yup.number('O valor não é numérico').required(
      'Informe o status do serviço'
    ),
  })

  const onSubmit = async (values, onSubmitProps) => {
    const res = await dispatch(saveServico(values))
    dispatch(fetchServicos())
    if (!!res?.error) {
      setDialogError(
        `Adicionar serviço: ${res.error?.code} - ${res.error?.message}`
      )
      setOpenDialogErro(true)
    } else {
      setOpenAviso(true)
      onSubmitProps.resetForm()
      limparForm(onSubmitProps.resetForm)
      if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
    }
  }

  const atualizarProjetos = (cliente_id, inicial) => {
    if (!inicial) {
      setOptionProjetoSelecionado([])
    }
    const ps = projetos.filter((el) => el.cliente_id === cliente_id)
    ps.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
    const ppC = ps.map((item) => {
      let op = {}
      if (item.id) {
        op = {
          value: item.id,
          label: item.nome,
        }
      }
      return op
    })
    setProjetosOptions(ppC)
  }

  const limparForm = (resetForm) => {
    resetForm()
    setOptionClienteSelecionado({})
    setOptionProjetoSelecionado({})
    setOptionTipoServicoSelecionado({})
    setOptionStatusServicoSelecionado({})
    setOptionPeriodicidadeServicoSelecionado({})
  }

  const initialValues = {
    id: modo.registro.id || '',
    cliente_id: modo.registro.cliente_id || '',
    projeto_id: modo.registro.projeto_id || '',
    tipo_servico_id: modo.registro.tipo_servico_id || '',
    status_servico_id: modo.registro.status_servico_id || '',
    periodicidade_id: modo.registro.periodicidade_id || '',
    nome: modo.registro.nome || '',
    descricao: modo.registro.descricao || '',
    data_inicio: modo.registro.data_inicio || '',
    data_termino: modo.registro.data_termino || '',
  }

  return (
    <>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={
            modo.status === 'add' ? 'Incluir novo serviço' : 'Alterar Serviço'
          }
          subtitle={props.value}
        ></CardHeader>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            formik,
            values,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            resetForm,
            defaultValues,
            reset,
          }) => {
            return (
              <Form>
                <CardContent>
                  <Grid
                    container
                    rowSpacing={{ xs: 2, sm: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                  >
                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="cliente_id"
                        name="cliente_id"
                        size="small"
                        noOptionsText={'Nenhuma opção disponível'}
                        options={clientesOptions}
                        value={optionClienteSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionClienteSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setOptionClienteSelecionado(newValue)
                          setFieldValue(
                            'cliente_id',
                            !!newValue ? newValue.value : ''
                          )
                          atualizarProjetos(
                            !!newValue ? newValue.value : '',
                            false
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Cliente"
                            value={values.cliente_id || ''}
                            error={
                              touched.cliente_id && Boolean(errors.cliente_id)
                            }
                            helperText={touched.cliente_id && errors.cliente_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="projeto_id"
                        name="projeto_id"
                        size="small"
                        noOptionsText={
                          <Box sx={{ border: '1px solid black', p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="div"
                            >
                              <strong>Nenhuma opção disponível</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom mb={2}>
                              Certifique-se de que o cliente foi selecionado e
                              de que o projeto está cadastrado.
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Button
                                sx={{ textAlign: 'center' }}
                                component="a"
                                href="/cadastro/projetos"
                                variant="contained"
                                size="small"
                              >
                                Cadastrar Projeto
                              </Button>
                            </Box>
                          </Box>
                        }
                        options={projetosOptions}
                        value={optionProjetoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionProjetoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'projeto_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionProjetoSelecionado(newValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Projeto"
                            error={
                              touched.projeto_id && Boolean(errors.projeto_id)
                            }
                            helperText={touched.projeto_id && errors.projeto_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        id="nome"
                        name="nome"
                        label="Título"
                        fullWidth
                        value={values.nome}
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.nome && Boolean(errors.nome)}
                        helperText={touched.nome && errors.nome}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        id="tipo_servico_id"
                        name="tipo_servico_id"
                        options={tiposServicoOptions}
                        value={optionTipoServicoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionTipoServicoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'tipo_servico_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionTipoServicoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.tipo_servico_id}
                            label="Tipo"
                            error={
                              touched.tipo_servico_id &&
                              Boolean(errors.tipo_servico_id)
                            }
                            helperText={
                              touched.tipo_servico_id && errors.tipo_servico_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        id="status_servico_id"
                        name="status_servico_id"
                        options={statusServicoOptions}
                        value={optionStatusServicoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionStatusServicoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'status_servico_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionStatusServicoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.status_servico_id}
                            label="Status"
                            error={
                              touched.status_servico_id &&
                              Boolean(errors.status_servico_id)
                            }
                            helperText={
                              touched.status_servico_id &&
                              errors.status_servico_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        id="periodicidade_id"
                        name="periodicidade_id"
                        options={periodicidadeServicoOptions}
                        value={optionPeriodicidadeServicoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionPeriodicidadeServicoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setFieldValue(
                            'periodicidade_id',
                            !!newValue ? newValue.value : ''
                          )
                          setOptionPeriodicidadeServicoSelecionado(newValue)
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={values.periodicidade_id}
                            label="Periodicidade"
                            error={
                              touched.periodicidade_id &&
                              Boolean(errors.periodicidade_id)
                            }
                            helperText={
                              touched.periodicidade_id &&
                              errors.periodicidade_id
                            }
                          />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        fullWidth
                        multiline
                        rows={4}
                        value={values.descricao}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.descricao && Boolean(errors.descricao)}
                        helperText={touched.descricao && errors.descricao}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="date"
                        id="data_inicio"
                        name="data_inicio"
                        label="Data de Início"
                        fullWidth
                        size="small"
                        format="dd/mm/yyyy"
                        value={values.data_inicio}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="date"
                        id="data_termino"
                        name="data_termino"
                        label="Data de Término"
                        fullWidth
                        size="small"
                        format="dd/MM/yyyy"
                        value={values.data_termino}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ ml: 1 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    startIcon={<SaveIcon />}
                  >
                    {modo.status === 'add' ? 'Salvar' : 'Alterar'}
                  </Button>
                  {modo.status === 'add' ? (
                    <Button
                      type="reset"
                      variant="contained"
                      color="secondary"
                      onClick={() => limparForm(resetForm)}
                      startIcon={<ClearIcon />}
                    >
                      Limpar
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    color="warning"
                    type="reset"
                    onClick={() => setModo({ status: 'table', registro: {} })}
                    startIcon={<ArrowBackIcon />}
                  >
                    VOLTAR
                  </Button>
                </CardActions>
              </Form>
            )
          }}
        </Formik>
      </Card>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Serviço ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir o serviço. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </>
  )
}

export default Formulario
