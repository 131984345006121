import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Divider, Grid, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { salvarTipoConta } from '../../../../redux/sistemas/contas/tiposContaSlice'

const validationSchema = yup.object({
  nome: yup
    .string('Informe o nome do Tipo de Conta')
    .min(3, 'O nome do Tipos de Conta precisa ter no mínimo 3 caracteres')
    .max(100, 'O nome do Tipo de Conta precisa ter no máximo 100 carcateres')
    .required('O nome do Tipo de Conta é obrigatório'),
  descricao: yup
    .string('Informe a descrição do Tipo de Conta')
    .min(3, 'A descrição precisa ter no mínimo 3 caracteres')
    .max(100, 'A descrição precisa ter no máximo 100 carcateres')
    .required('A descrição é obrigatório'),
})

export const FormTiposConta = (props) => {
  const { registro, salvarTipoConta, acao } = props
  const [open, setOpen] = useState(false)

  const formik = useFormik({
    initialValues: {
      id: registro?.id || '',
      nome: registro?.nome || '',
      descricao: registro?.descricao || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      salvarTipoConta(values)
      handleClose()
    },
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {acao === 'alt' ? (
        <Tooltip
          title={<Box sx={{ fontSize: '0.9rem' }}>Alterar dados</Box>}
          placement="top"
        >
          <IconButton color="primary" onClick={handleClickOpen} size="small">
            <EditIcon color="success" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="third"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
        >
          Adicionar Tipo de Conta
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth>
        <Grid component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {acao === 'alt'
              ? 'Alterar Dados do Tipo de Conta'
              : 'Adicionar Novo Tipo de Conta'}
          </DialogTitle>
          <Divider />

          <DialogContent>
            <Box mt={2} display="flex" flexDirection={'column'} gap={3}>
              <TextField
                size="small"
                fullWidth
                name="nome"
                label="Nome"
                value={formik.values.nome}
                onChange={formik.handleChange}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
              />
              <TextField
                size="small"
                fullWidth
                name="descricao"
                label="descrição"
                value={formik.values.descricao}
                onChange={formik.handleChange}
                error={
                  formik.touched.descricao && Boolean(formik.errors.descricao)
                }
                helperText={formik.touched.descricao && formik.errors.descricao}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              color="warning"
              type="reset"
              onClick={handleClose}
              startIcon={<ArrowBackIcon />}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
  return {
    salvarTipoConta: (registro) => dispatch(salvarTipoConta(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormTiposConta)
