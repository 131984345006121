import { connect } from 'react-redux'
import Layout from '../../layouts'
// import CardTarefa from './cardTarefa'
// import Masonry from '@mui/lab/Masonry';
import { Divider, Grid } from '@mui/material'
import MenuLateral from './menuLateral'
import PainelTarefas from './painelTarefas'
import { useState } from 'react'
import PainelAtividades from './paineis/atividades'

// const tarefas = [
//     {
//         nome: 'Tarefa 1',
//         descricao: 'Teste de tarefa',
//         situacao: 'I',
//         colorSituacao: '#ff8c00',
//         colorText: '#0d0d0d'
//     },
//     {
//         nome: 'Tarefa 2',
//         descricao: 'Lorem ipsum dolor sit amet . Os operadores gráficos e tipográficos sabem disso bem, na realidade, todas as profissões que lidam com o universo da comunicação têm um relacionamento estável com essas palavras, mas o que é? Lorem ipsum é um texto fofo sem qualquer sentido.',
//         situacao: 'E',
//         colorSituacao: '#FF0000',
//         colorText: ''
//     },
//     {
//         nome: 'Tarefa 3',
//         descricao: 'Teste de tarefa',
//         situacao: 'E',
//         colorSituacao: '#FF0000',
//         colorText: ''
//     },
//     {
//         nome: 'Tarefa 4',
//         descricao: 'Teste de tarefa',
//         situacao: 'C',
//         colorSituacao: '#32CD32',
//         colorText: '',
//     },
//     {
//         nome: 'Tarefa 5',
//         descricao: 'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão.',
//         situacao: 'Co',
//         colorSituacao: '#b0bec5',
//         colorText: '#0a0a0a',
//     },
//     {
//         nome: 'Tarefa 6',
//         descricao: 'Teste de tarefa',
//         situacao: 'En',
//         colorSituacao: '#000000',
//         colorText: '',
//     },
//     {
//         nome: 'Tarefa 7',
//         descricao: 'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão Posteriormente serão ocupados a partir de textos ad hoc compostos por profissionais de comunicação.',
//         situacao: 'En',
//         colorSituacao: '#000000',
//         colorText: '',
//     },
//     {
//         nome: 'Tarefa 8',
//         descricao: 'Teste de tarefa',
//         situacao: 'En',
//         colorSituacao: '#000000',
//         colorText: '',
//     },
//     {
//         nome: 'Tarefa 9',
//         descricao: 'É uma seqüência de palavras latinas que, como estão posicionadas, não formem frases com um sentido completo, mas dão vida a um texto de teste útil para preencher espaços que irão Posteriormente serão ocupados a partir de textos ad hoc compostos por profissionais de comunicação.',
//         situacao: 'I',
//         colorSituacao: '#ff8c00',
//         colorText: '#0d0d0d',
//     }
// ]

export const Tarefas = (props) => {
  const [painel, setPainel] = useState(1)
  const [atividade, setAtividade] = useState(null)

  console.log('Painel: ', painel)
  return (
    <Layout
      titulo="Gestão das Tarefas"
      subtitulo="Cadastro e Gestão das Tarefas"
    >
      <Grid container spacing={2}>
        <Grid item xs={1} bgcolor={'#D3D3D3'} marginLeft={-6} mt={2}>
          <MenuLateral setPainel={setPainel} setAtividade={setAtividade} />
        </Grid>

        <Grid item xs={11} mt={2}>
          {painel === 1 ? (
            <PainelTarefas atividade="Minhas tarefas" />
          ) : painel === 2 ? (
            <PainelAtividades atividade={atividade} />
          ) : null}

          <Divider />
          {/* <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={2} >

                        {
                            tarefas.map((t) => (
                                <CardTarefa
                                    nome={t.nome}
                                    descricao={t.descricao}
                                    situacao={t.situacao}
                                    colorSituacao={t.colorSituacao}
                                    colorText={t.colorText}
                                />
                            ))
                        }
                    </Masonry> */}
        </Grid>
      </Grid>
    </Layout>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Tarefas)

// https://www.freecodecamp.org/news/how-to-add-drag-and-drop-in-react-with-react-beautiful-dnd/
// https://codesandbox.io/s/todoapp-9wstd?file=/src/Kanban.js
