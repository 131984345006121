import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  registros: [],
  error: {},
}

export const listarPreferencias = createAsyncThunk(
  'preferencias',
  (user_id) => {
    const autorizacao = header()
    return api
      .get(`/preferencias/obterpreferencia/${user_id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const salvarPreferencia = createAsyncThunk(
  'preferencias/salvar',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/preferencias/salvarPreferencia', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'preferencias',
  initialState: initialState,
  extraReducers: (builder) => {
    // Listar preferencias
    builder.addCase(listarPreferencias.pending, (state) => {
      state.loading = true
    })
    builder.addCase(listarPreferencias.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = {}
    })
    builder.addCase(listarPreferencias.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    //Salvar Preferencia
    builder.addCase(salvarPreferencia.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarPreferencia.fulfilled, (state, action) => {
      state.loading = false
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )
      if (index === -1) {
        state.registros.push(action.payload.registro)
      } else {
        state.registros[index] = {
          ...state.registros[index],
          ...action.payload.registro,
        }
      }
      state.error = {}
    })
    builder.addCase(salvarPreferencia.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export default slice.reducer
