import { Box } from '@mui/material'
import { useState } from 'react'
import Layout from '../../layouts'
import Formulario from './Form'
import Table from './Table'

const Pagamentos = (props) => {
  const [modo, setModo] = useState({ status: 'table', registro: {} })
  const [registroAtual, setRegistroAtual] = useState({})

  return (
    <Layout
      titulo="Pagamentos"
      subtitulo="Cadastro de pagamentos realizados e a realizar"
    >
      <Box>
        {modo.status === 'table' ? (
          <Table
            setModo={setModo}
            modo={modo}
            registroAtual={registroAtual}
            setRegistroAtual={setRegistroAtual}
          />
        ) : (
          <Formulario setModo={setModo} modo={modo} />
        )}
      </Box>
    </Layout>
  )
}

export default Pagamentos
