import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { connect } from 'react-redux'
import {
  excluirBanco,
  listarBancos,
} from '../../../../redux/sistemas/contas/bancosSlice'
import { useEffect } from 'react'
import { useState } from 'react'
import { Box } from '@mui/material'
import Loading from '../../../layouts/Loading'
import DialogErro from '../../../layouts/DialogErro'
import { dataHoraBRExibir } from '../../../../funcoes/data'
import FormBanco from './formBanco'
import DialogExclusao from '../../../layouts/DialogExclusao'

export const TableBanco = (props) => {
  const { loading, registros, error, listarBancos, excluirBanco } = props
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [atualizarLista, setAtualizarLista] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [idExclusao, setIdExclusao] = useState('')

  useEffect(() => {
    if (atualizarLista) {
      listarBancos()
      setAtualizarLista(false)
    }
    if (error) setOpenDialogErro(true)
  }, [atualizarLista, error, listarBancos])

  const rows = registros

  const handleExcluir = (idExclusao) => {
    excluirBanco(idExclusao)
  }

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={error}
          mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : (
        <>
          <FormBanco registro={null} acao="add" />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'Silver' }}>
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>CÓDIGO</TableCell>
                  <TableCell>NOME</TableCell>
                  <TableCell align="center">INCLUSÃO</TableCell>
                  <TableCell align="center">ALTERAÇÃO</TableCell>
                  <TableCell align="center">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.codigo}</TableCell>
                    <TableCell>{row.nome}</TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.updated_at)}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FormBanco registro={row} acao="alt" />
                        <DialogExclusao
                          title="o banco do sistema"
                          referencia={`Banco ${row.nome}`}
                          idExclusao={row.id}
                          setIdExclusao={setIdExclusao}
                          handleExcluir={handleExcluir}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.bancos.loading,
    registros: state.bancos.registros,
    error: state.bancos.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listarBancos: () => dispatch(listarBancos()),
    excluirBanco: (id) => dispatch(excluirBanco(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableBanco)
