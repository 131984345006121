import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../../../layouts'
import { navigate } from 'hookrouter'

function MinhasTarefas() {
  return (
    <Layout titulo="Minhas Tarefas" subtitulo="Tarefas atribuídas a mim.">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography paragraph variant="h4">
          Minhas Tarefas
        </Typography>
        <Typography paragraph>Listagem das tarefas atribuídas a mim</Typography>
        <Typography paragraph>Página em construção</Typography>
        <Button variant="outlined" onClick={() => navigate('/sistema/tarefas')}>
          Voltar
        </Button>
      </Box>
    </Layout>
  )
}

export default MinhasTarefas
