import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import TableConta from './tableConta'

export const ContaCorrente = (props) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom align="center" mt={1}>
        Contas
      </Typography>
      <Divider sx={{ mb: '10px' }} />
      <TableConta />
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ContaCorrente)
