import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { valorInteiro, valorMoeda } from '../../../funcoes/data'
import { fetchServicos } from '../../../redux/controleGeral/controleGeralSlice'

export const Servicos = (props) => {
  const { servicos, atualizarServicos } = props

  useEffect(() => {
    atualizarServicos()
  }, [atualizarServicos])

  const totalizarQtde = () => {
    return servicos.dados.reduce((a, b) => a + parseInt(b.QTDE), 0)
  }

  const totalizarValor = () => {
    return servicos.dados.reduce((a, b) => a + parseFloat(b.VALOR), 0.0)
  }

  const totalizarValorPrevisto = () => {
    return servicos.dados.reduce(
      (a, b) => a + parseFloat(b.VALOR_PREVISTO),
      0.0
    )
  }

  return (
    <Box>
      {servicos.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : servicos.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {servicos.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Relatório serviços
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell>ID</TableCell>
                  <TableCell>TITULO</TableCell>
                  <TableCell align="right">QTDE</TableCell>
                  <TableCell align="right">VALOR PREVISTO</TableCell>
                  <TableCell align="right">VALOR RECEBIDO</TableCell>
                  <TableCell align="right">A REALIZAR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {servicos.dados &&
                  servicos.dados.map((row) => (
                    <TableRow
                      key={row.ID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.ID}</TableCell>
                      <TableCell>{row.TITULO}</TableCell>
                      <TableCell align="right">
                        {valorInteiro(row.QTDE)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALOR_PREVISTO)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALOR)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALOR_PREVISTO - row.VALOR)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow
                  py={10}
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell colSpan={2}>TOTAL</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorInteiro(totalizarQtde())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValorPrevisto())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValor())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValorPrevisto() - totalizarValor())}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    servicos: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarServicos: () => dispatch(fetchServicos()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Servicos)
