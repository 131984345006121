import styled from '@emotion/styled'
import React from 'react'
import { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import TaskCard from './tarefas/TaskCard'
import { colunas } from './tarefasTesteData'
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import AddTaskIcon from '@mui/icons-material/AddTask'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Container = styled.div`
  display: flex;
`

const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 250px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 10px;
`

const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
`
const Title = styled.span`
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
  margin-bottom: 4px;
`

export const PainelTarefas = (props) => {
  const [columns, setColumns] = useState(colunas)
  const [openConcluido, setOpenConcluido] = useState(false)

  const handleClickConcluido = () => {
    setOpenConcluido(!openConcluido)
  }

  const onDragEnd = (result, columns, setColumns) => {
    //console.log('colunas em onDragEnd (inicio):',columns)
    /* console.log('result:', result)
        console.log('columns:', columns)
        console.log('setColumns:', setColumns) */

    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
    }
    //console.log('colunas em onDragEnd (fim):',columns)
  }

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <Container>
        <TaskColumnStyles>
          {Object.entries(columns).map(([columnId, column], index) => {
            //console.log('columnId: ', columnId)
            //console.log('column: ', column)
            //console.log('column.nome: ', column.nome)
            //console.log('index: ', index)

            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Title>{column.title}</Title>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<AddTaskIcon />}
                      sx={{ marginBottom: '6px' }}
                    >
                      Adicionar Tarefa
                    </Button>
                    {column.items.map((item, index) => (
                      /* <CardTarefa
                                                        key={index} item={item} index={index}
                                                        nome={item.nome}
                                                        descricao={item.descricao}
                                                        situacao={item.situacao}
                                                        colorSituacao={item.colorSituacao}
                                                        colorText={item.colorText}
                                                    /> */
                      <TaskCard key={index} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                    <List dense>
                      <ListItemButton onClick={handleClickConcluido}>
                        <ListItemText primary="Concluido" />
                        {openConcluido ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItemButton>
                    </List>
                    <Collapse in={openConcluido} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding dense>
                        {column.items.map((item, index) => (
                          /* <CardTarefa
                                                                key={index} item={item} index={index}
                                                                nome={item.nome}
                                                                descricao={item.descricao}
                                                                situacao={item.situacao}
                                                                colorSituacao={item.colorSituacao}
                                                                colorText={item.colorText}
                                                            /> */
                          <TaskCard key={index} item={item} index={index} />
                        ))}
                        {provided.placeholder}
                      </List>
                    </Collapse>
                  </TaskList>
                )}
              </Droppable>
            )
          })}
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PainelTarefas)

// https://codesandbox.io/s/todoapp-9wstd?file=/src/Kanban.js:174-193
