import { createStyles, makeStyles } from '@material-ui/styles'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchProjetos } from '../../../redux/cadastro/projetoSlice'
import {
  fetchAReceber,
  fetchRecebimentos,
  saveRecebimento,
} from '../../../redux/cadastro/recebimentoSlice'
import { fetchServicos } from '../../../redux/cadastro/servicoSlice'
import { fetchOrigemRecebimento } from '../../../redux/cadastro/tabelas/origemRecebimentoSlice'
import { fetchStatusRecebimento } from '../../../redux/cadastro/tabelas/statusRecebimentoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'
//import { setRefreshContasAPagar } from '../../../redux/cadastro/controleSlice'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        border: '2px solid black',
        fontSize: 12,
        zIndex: 9999,
        '& li:nth-child(even)': { backgroundColor: '#F2F2F2' },
        '& li:nth-child(odd)': { backgroundColor: '#FFF' },
      },
    },
    menu: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
  })
)

const CustomPopper = (props) => {
  const classes = useStyles()
  return (
    <Popper
      sx={{ zIndex: 999 }}
      {...props}
      className={classes.root}
      placement="bottom"
    />
  )
}

function Formulario(props) {
  const { modo, setModo, intervalo } = props
  const [openAviso, setOpenAviso] = useState(false)
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [optionClienteSelecionado, setOptionClienteSelecionado] = useState({})
  const [optionProjetoSelecionado, setOptionProjetoSelecionado] = useState({})
  const [optionServicoSelecionado, setOptionServicoSelecionado] = useState({})
  const [optionNivelDeAcessoSelecionado, setOptionNivelDeAcessoSelecionado] =
    useState({})
  const [optionStatusSelecionado, setOptionStatusSelecionado] = useState({})
  const [optionOrigemSelecionado, setOptionOrigemSelecionado] = useState({})
  const [projetosOptions, setProjetosOptions] = useState([])
  const [servicosOptions, setServicosOptions] = useState([])
  const [dialogError, setDialogError] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchStatusRecebimento())
    dispatch(fetchOrigemRecebimento())
    dispatch(fetchPessoas())
    dispatch(fetchProjetos())
    dispatch(fetchServicos())

    setOptionClienteSelecionado({
      value: modo.registro?.projeto?.cliente.id || '',
      label: modo.registro?.projeto?.cliente.nome || '',
    })

    setOptionProjetoSelecionado({
      value: modo.registro?.projeto?.id || '',
      label: modo.registro?.projeto?.nome || '',
    })

    setOptionServicoSelecionado({
      value: modo.registro?.servico?.id || '',
      label: modo.registro?.servico?.nome || '',
    })
    setOptionStatusSelecionado({
      value: modo.registro?.statusRecebimento?.id || '',
      label: modo.registro?.statusRecebimento?.nome || '',
    })
    setOptionNivelDeAcessoSelecionado({
      value: modo.registro?.nivel_acesso || '',
      label:
        modo.registro?.nivel_acesso === 1
          ? 'Adminstrador'
          : modo.registro?.nivel_acesso === 2
            ? 'Gerente'
            : 'Operador',
    })
    setOptionOrigemSelecionado({
      value: modo.registro?.origemRecebimento?.id || '',
      label: modo.registro?.origemRecebimento?.nome || '',
    })
  }, [dispatch, modo])

  const statusRecebimento = useSelector(
    (state) => state.statusRecebimento.registros
  )
  const origemRecebimento = useSelector(
    (state) => state.origemRecebimento.registros
  )
  const pessoas = useSelector((state) => state.pessoas.registros)
  const projetos = useSelector((state) => state.projetos.registros)
  const servicos = useSelector((state) => state.servicos.registros)
  let clientes = pessoas.filter(
    (p) => p.grupo_pessoa_id === 2 || p.grupo_pessoa_id === 4
  )
  clientes.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))

  const statusRecebimentoOptions = statusRecebimento?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const origemRecebimentoOptions = origemRecebimento?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const nivelAcessoOptions = [
    {
      value: 1,
      label: 'Adminstrador',
    },
    {
      value: 2,
      label: 'Gerente',
    },
    {
      value: 3,
      label: 'Operador',
    },
  ]

  const clientesOptions = clientes?.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const atualizarProjetos = (cliente_id, inicial) => {
    if (!inicial) {
      setOptionProjetoSelecionado([])
    }
    const ps = projetos.filter((el) => el.cliente_id === cliente_id)
    ps.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
    const ppC = ps.map((item) => {
      let op = {}
      if (item.id) {
        op = {
          value: item.id,
          label: item.nome,
        }
      }
      return op
    })
    setProjetosOptions(ppC)
  }

  const atualizarServicos = (projeto_id, inicial) => {
    if (!inicial) {
      setOptionServicoSelecionado([])
    }
    const ps = servicos.filter((el) => el.projeto_id === projeto_id)
    ps.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
    const ppC = ps.map((item) => {
      let op = {}
      if (item.id) {
        op = {
          value: item.id,
          label: item.nome,
        }
      }
      return op
    })
    setServicosOptions(ppC)
  }

  useEffect(() => {
    if (optionClienteSelecionado) {
      atualizarProjetos(optionClienteSelecionado.value, true)
    }

    if (optionProjetoSelecionado) {
      atualizarServicos(optionProjetoSelecionado.value, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modo, optionClienteSelecionado, optionProjetoSelecionado])

  const validationSchema = Yup.object({
    cliente_id: Yup.number('O valor não é numérico').required(
      'Informe o nome do cliente'
    ),

    projeto_id: Yup.number('O valor não é numérico').required(
      'Informe o projeto'
    ),

    servico_id: Yup.number('O valor não é numérico').required(
      'Informe o serviço'
    ),

    data: Yup.date('O valor não é uma data válida').required(
      'Informe a data de vencimento'
    ),

    valor: Yup.number('O valor informado não é válido (Ex.: 1123,00)').required(
      'Informe o valor do recebimento'
    ),
  })

  const onSubmit = async (values, onSubmitProps) => {
    const res = await dispatch(saveRecebimento(values))

    if (modo.status === 'Alterar Recebimento') {
      //dispatch(setRefreshContasAPagar(true))
      await dispatch(fetchAReceber(intervalo))
    } else if (modo.status !== 'add') {
      dispatch(fetchRecebimentos())
      setModo({ status: 'table', registro: {} })
    } else {
      limparForm(onSubmitProps.resetForm)
    }

    if (!!res?.error) {
      setDialogError(
        `Adicionar recebimento: ${res.error?.code} - ${res.error?.message}`
      )
      setOpenDialogErro(true)
    } else {
      setOpenAviso(true)
      onSubmitProps.resetForm()
      //setAtualizado(true) // Confirmar
      if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
    }
  }

  const limparForm = (resetForm) => {
    resetForm()
    setOptionClienteSelecionado({})
    setOptionProjetoSelecionado({})
    setOptionServicoSelecionado({})
    setOptionStatusSelecionado({})
    setOptionOrigemSelecionado({})
  }

  const initialValues = {
    id: modo.registro.id || '',
    cliente_id: modo.registro?.projeto?.cliente_id || '',
    projeto_id: modo.registro.projeto_id || '',
    servico_id: modo.registro.servico_id || '',
    status_recebimento_id: modo.registro.status_recebimento_id || '',
    origem_recebimento_id: modo.registro.origem_recebimento_id || '',
    numero_parcela: modo.registro.numero_parcela || '1',
    data: modo.registro.data || '',
    data_pagamento: modo.registro.data_pagamento || '',
    valor: modo.registro.valor || '',
    observacoes: modo.registro.observacoes || '',
    nivel_acesso: modo.registro.nivel_acesso || '',
  }
  return (
    <>
      <Card sx={{ width: '100%' }}>
        {modo.status !== 'Alterar Recebimento' ? (
          <CardHeader
            title={
              modo.status === 'add'
                ? 'Incluir um novo recebimento'
                : 'Alterar Recebimento'
            }
            subtitle={props.value}
          ></CardHeader>
        ) : null}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            formik,
            values,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            resetForm,
            defaultValues,
          }) => {
            return (
              <Form>
                <CardContent>
                  <Grid
                    container
                    rowSpacing={{ xs: 2, sm: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                  >
                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="cliente_id"
                        name="cliente_id"
                        size="small"
                        noOptionsText={'Nenhuma opção disponível'}
                        options={clientesOptions}
                        value={optionClienteSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionClienteSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue, option) => {
                          setOptionClienteSelecionado(newValue)
                          setFieldValue(
                            'cliente_id',
                            !!newValue ? newValue.value : ''
                          )
                          atualizarProjetos(
                            !!newValue ? newValue.value : '',
                            false
                          )
                          atualizarServicos('', false)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cliente"
                            value={values.cliente_id}
                            error={
                              touched.cliente_id && Boolean(errors.cliente_id)
                            }
                            helperText={touched.cliente_id && errors.cliente_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        noOptionsText={
                          <Box sx={{ border: '1px solid black', p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="div"
                            >
                              <strong>Nenhuma opção disponível</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom mb={2}>
                              Certifique-se de que o cliente foi selecionado e
                              de que o projeto está cadastrado.
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Button
                                sx={{ textAlign: 'center' }}
                                component="a"
                                href="/cadastro/projetos"
                                variant="contained"
                                size="small"
                              >
                                Cadastrar Projeto
                              </Button>
                            </Box>
                          </Box>
                        }
                        options={projetosOptions}
                        value={optionProjetoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionProjetoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionProjetoSelecionado(newValue)
                          setFieldValue(
                            'projeto_id',
                            !!newValue ? newValue.value : ''
                          )
                          atualizarServicos(
                            !!newValue ? newValue.value : '',
                            false
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Projeto"
                            error={
                              touched.projeto_id && Boolean(errors.projeto_id)
                            }
                            helperText={touched.projeto_id && errors.projeto_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        noOptionsText={
                          <Box sx={{ border: '1px solid black', p: 2 }}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              component="div"
                            >
                              <strong>Nenhuma opção disponível</strong>
                            </Typography>
                            <Typography variant="body2" gutterBottom mb={2}>
                              Certifique-se de que o projeto esteja selecionado
                              e/ou de que o serviço está cadastrado.
                            </Typography>
                            <Box display="flex" justifyContent="center">
                              <Button
                                sx={{ textAlign: 'center' }}
                                component="a"
                                href="/cadastro/servicos"
                                variant="contained"
                                size="small"
                              >
                                Cadastrar Serviço
                              </Button>
                            </Box>
                          </Box>
                        }
                        options={servicosOptions}
                        value={optionServicoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionServicoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionServicoSelecionado(newValue)
                          setFieldValue(
                            'servico_id',
                            !!newValue ? newValue.value : ''
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Servico"
                            error={
                              touched.servico_id && Boolean(errors.servico_id)
                            }
                            helperText={touched.servico_id && errors.servico_id}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {`${option.label} (${option.value})`}
                          </Box>
                        )}
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="date"
                        id="data"
                        name="data"
                        label="Data de Vencimento"
                        fullWidth
                        size="small"
                        format="MM/dd/yyyy"
                        value={values.data}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.data && Boolean(errors.data)}
                        helperText={touched.data && errors.data}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        id="numero_parcela"
                        name="numero_parcela"
                        label="Nº da Parcela"
                        type="number"
                        fullWidth
                        value={values.numero_parcela}
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.numero_parcela &&
                          Boolean(errors.numero_parcela)
                        }
                        helperText={
                          touched.numero_parcela && errors.numero_parcela
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="status_recebimento_id"
                        name="status_recebimento_id"
                        options={statusRecebimentoOptions}
                        value={optionStatusSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionStatusSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionStatusSelecionado(newValue)
                          setFieldValue(
                            'status_recebimento_id',
                            !!newValue ? newValue.value : ''
                          )
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Status" />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="date"
                        id="data_pagamento"
                        name="data_pagamento"
                        label="Data de pagamento"
                        fullWidth
                        size="small"
                        format="MM/dd/yyyy"
                        value={values.data_pagamento}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Autocomplete
                        disablePortal
                        id="origem_recebimento_id"
                        name="origem_recebimento_id"
                        options={origemRecebimentoOptions}
                        value={optionOrigemSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionOrigemSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionOrigemSelecionado(newValue)
                          setFieldValue(
                            'origem_recebimento_id',
                            !!newValue ? newValue.value || '' : ''
                          )
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Origem" />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        id="valor"
                        name="valor"
                        label="Valor"
                        type="number"
                        fullWidth
                        value={values.valor}
                        size="small"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                        error={touched.valor && Boolean(errors.valor)}
                        helperText={touched.valor && errors.valor}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="observacoes"
                        name="observacoes"
                        label="Histórico"
                        fullWidth
                        multiline
                        rows={4}
                        value={values.observacoes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.observacoes && Boolean(errors.observacoes)
                        }
                        helperText={touched.observacoes && errors.observacoes}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Autocomplete
                        disablePortal
                        id="
                        nivel_acesso"
                        name="nivel_acesso"
                        options={nivelAcessoOptions}
                        value={optionNivelDeAcessoSelecionado}
                        isOptionEqualToValue={(option) =>
                          (option = optionNivelDeAcessoSelecionado)
                        }
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(event, newValue) => {
                          setOptionNivelDeAcessoSelecionado(newValue)
                          setFieldValue(
                            'nivel_acesso',
                            !!newValue ? newValue.value || '' : ''
                          )
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.value}>
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Nivel de acesso" />
                        )}
                        size="small"
                        PopperComponent={CustomPopper}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ ml: 1 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    // disabled={!isValid || isSubmitting}
                    disabled={isSubmitting}
                    startIcon={<SaveIcon />}
                  >
                    {modo.status === 'add' ? 'Salvar' : 'Alterar'}
                  </Button>
                  {modo.status === 'add' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => limparForm(resetForm)}
                      startIcon={<ClearIcon />}
                    >
                      Limpar
                    </Button>
                  ) : null}
                  {modo.status !== 'Alterar Recebimento' ? (
                    <Button
                      variant="contained"
                      color="warning"
                      //type="reset"
                      onClick={() => setModo({ status: 'table', registro: {} })}
                      startIcon={<ArrowBackIcon />}
                    >
                      VOLTAR
                    </Button>
                  ) : null}
                </CardActions>
              </Form>
            )
          }}
        </Formik>
      </Card>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Recebimento ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir o recebimento. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </>
  )
}

export default Formulario
