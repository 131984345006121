import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import Formulario from '../../cadastro/pagamentos/Form'
import { Box } from '@mui/system'

export default function Alterar(props) {
  const { registro } = props

  const [modo] = useState({ status: 'Alterar Pagamento', registro: registro })
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Tooltip
        title={
          <Box sx={{ fontSize: '13px', p: 0.5 }}>Classificar Pagamento</Box>
        }
        placement="left"
      >
        <IconButton
          color="primary"
          onClick={() => {
            handleOpen()
          }}
          size="small"
        >
          <EditIcon color="success" />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pr: 1,
            pl: 4,
          }}
          className="dialog-title"
        >
          Alterar Pagamento
          <IconButton aria-label="add to shopping cart" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 1 }}>
          <Formulario modo={modo} />
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            pr: 1,
            pl: 4,
          }}
        >
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
