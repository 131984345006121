import {
  Alert,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  fetchAReceber,
  resetRecebimentos,
} from '../../../redux/cadastro/recebimentoSlice'
import Layout from '../../layouts'
import DialogErro from '../../layouts/DialogErro'
import Loading from '../../layouts/Loading'
import TableContasAReceber from './TableContasAReceber'

export const ContasAReceber = (props) => {
  const {
    loading,
    recebimentos,
    error,
    carregarRecebimentos,
    resetRecebimentos,
  } = props
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [dtInicio, setDtInicio] = useState('')
  const [dtFim, setDtFim] = useState('')
  const [dataInvalida, setDataInvalida] = useState('')

  useEffect(() => {
    if (error) setOpenDialogErro(true)
  }, [error])

  const criarRelatorio = () => {
    const intervalo = { dtInicio, dtFim }
    if (dtInicio && dtFim) {
      carregarRecebimentos(intervalo)
    } else {
      setDataInvalida(
        'Intervalo ou data inválida. Reviste o intervalo e tente novamente.'
      )
    }
  }

  return (
    <Layout
      titulo="Contas a receber"
      subtitulo="Parcelas em aberto aguardando pagamento"
    >
      <Grid item xs={12} md={12} mt={10}>
        <Typography sx={{ mt: 3 }} variant="body2" component="div" mb={1}>
          Lista de parcelas com status "em aberto" ou "em atraso" referentes a
          recebimentos de clientes que ainda não foram pagas.
        </Typography>
        <Divider />
      </Grid>
      <Grid
        container
        rowSpacing={{ xs: 2, sm: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        alignItems="center"
        justifyContent={'center'}
        p={2}
      >
        <Grid item xs={12} md={3} lg={2}>
          <Typography variant="body" component="div">
            Informe o Período:
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            type="date"
            label={'de'}
            InputLabelProps={{ shrink: true }}
            value={dtInicio}
            onChange={(e) => {
              if (recebimentos) {
                resetRecebimentos()
              }
              setDtInicio(e.target.value)
            }}
            onFocus={() => setDataInvalida('')}
          />
        </Grid>
        <Grid item>
          <TextField
            size="small"
            type="date"
            label="até"
            InputLabelProps={{ shrink: true }}
            value={dtFim}
            onChange={(e) => {
              if (recebimentos) {
                resetRecebimentos()
              }
              setDtFim(e.target.value)
            }}
            onFocus={() => setDataInvalida('')}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => criarRelatorio()}>
            Listar
          </Button>
        </Grid>

        {dataInvalida ? (
          <Grid item>
            <Alert severity="error">{dataInvalida}</Alert>
          </Grid>
        ) : null}
      </Grid>
      <Divider />
      {loading ? (
        <Loading />
      ) : error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={error}
          mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : (
        <Grid container>
          <TableContasAReceber
            //recebimentos={recebimentos}
            intervalo={{ dtInicio, dtFim }}
          />
        </Grid>
      )}
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.recebimentos.loading,
    recebimentos: state.recebimentos.registros,
    error: state.recebimentos.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    carregarRecebimentos: (intervalo) => dispatch(fetchAReceber(intervalo)),
    resetRecebimentos: () => dispatch(resetRecebimentos()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContasAReceber)
