import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchPessoa } from '../../../redux/cadastro/pessoa/pessoaSlice'
import FormDadosGerais from './FormDadosGerais'
import FormDadosPF from './FormDadosPF'
import FormDadosPJ from './FormDadosPJ'
import FormEnderecos from './FormEnderecos'
import FormRedesSociais from './FormRedesSociais'
import FormTelefones from './FormTelefones'

function FormAlterar(props) {
  const { pessoaAtual } = props
  const [value, setValue] = useState(0)
  const [alterado, setAlterado] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPessoa(pessoaAtual.id))
  }, [alterado, dispatch, pessoaAtual.id])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        sm={12}
        sx={{ textAlign: 'center', paddingTop: '10px' }}
      >
        Editar {'Pessoa '}
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex', height: '100vh' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            label="Dados Gerais"
            id="vertical-tab-0"
            aria-controls="vertical-tabpanel-0" /*disabled={abasPessoa.status.dadosGerais}*/
          />

          <Tab
            label="Pessoa Física"
            id="vertical-tab-1"
            aria-controls="vertical-tabpanel-1"
            disabled={parseInt(pessoaAtual.tipo_pessoa) === 2}
          />
          <Tab
            label="PessoaJurídica"
            id="vertical-tab-2"
            aria-controls="vertical-tabpanel-2"
            disabled={parseInt(pessoaAtual.tipo_pessoa) === 1}
          />

          <Tab
            label="Endereços"
            id="vertical-tab-3"
            aria-controls="vertical-tabpanel-3" /*disabled={abasPessoa.status.enderecos}*/
          />
          <Tab
            label="Redes Sociais"
            id="vertical-tab-4"
            aria-controls="vertical-tabpanel-4" /*disabled={abasPessoa.status.redesSociais}*/
          />
          <Tab
            label="Telefones"
            id="vertical-tab-5"
            aria-controls="vertical-tabpanel-5" /*disabled={abasPessoa.status.telefones}*/
          />
        </Tabs>

        <Box
          hidden={value !== 0}
          id="vertical-tabpanel-0"
          aria-labelledby="vertical-tab-0"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormDadosGerais
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>

        <Box
          hidden={value !== 1}
          id="vertical-tabpanel-1"
          aria-labelledby="vertical-tab-1"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormDadosPF
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>

        <Box
          hidden={value !== 2}
          id="vertical-tabpanel-2"
          aria-labelledby="vertical-tab-2"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormDadosPJ
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>

        <Box
          hidden={value !== 3}
          id="vertical-tabpanel-3"
          aria-labelledby="vertical-tab-3"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormEnderecos
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>

        <Box
          hidden={value !== 4}
          id="vertical-tabpanel-4"
          aria-labelledby="vertical-tab-4"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormRedesSociais
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>

        <Box
          hidden={value !== 5}
          id="vertical-tabpanel-5"
          aria-labelledby="vertical-tab-5"
          style={{ width: '100%' }}
        >
          <Box sx={{ p: 1, width: '100%' }}>
            <FormTelefones
              pessoaAtual={pessoaAtual}
              modo={props.modo}
              setModo={props.setModo}
              value={value}
              setValue={setValue}
              setAlterado={setAlterado}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FormAlterar
