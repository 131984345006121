import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import ConstructionIcon from '@mui/icons-material/Construction'
import {
  adicionarMes,
  adicionarAno,
  dataHoraBRExibir,
  valorMoeda,
} from '../../../funcoes/data'
import { saveAllPagamento } from '../../../redux/cadastro/pagamentoSlice'
import { useDispatch } from 'react-redux'
import { saveAllRecebimento } from '../../../redux/cadastro/recebimentoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'

export default function ParcelasCriadas(props) {
  const dispatch = useDispatch()
  const { dados } = props

  const [open, setOpen] = useState(false)
  const [maxWidth] = useState('md')
  const [listaRecebimentos, setListaRecebimentos] = useState([])
  const [listaPagamentos, setListaPagamentos] = useState([])
  const [dialogError, setDialogError] = useState('')
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [openAviso, setOpenAviso] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const calcularParcelas = () => {
    let pagamentos = []
    let recebimentos = []
    dados.map((item, index) => {
      let num_parcela = 1
      let proximaData = item.data_referencia
      while (
        new Date(item.data_referencia).getFullYear() ===
          new Date(proximaData).getFullYear() &&
        num_parcela <= item.qtde_parcelas
      ) {
        if (item.tipo_id === 1) {
          const recebimento = {
            id: null,
            projeto_id: item.servico.projeto_id,
            servico_id: item.servico_id,
            status_recebimento_id: 1,
            origem_recebimento_id: null,
            numero_parcela: num_parcela,
            data: proximaData,
            data_pagamento: null,
            valor: item.valor_referencia,
            observacoes: `Parcela criada pela rotina de produção automática em ${dataHoraBRExibir(new Date())}`,
          }
          recebimentos.push(recebimento)
          num_parcela++
          proximaData =
            item.forma_pagamento_id === 3
              ? adicionarMes(proximaData)
              : adicionarAno(proximaData)
        }
        if (item.tipo_id === 2) {
          const pagamento = {
            id: null,
            projeto_id: item.servico.projeto_id,
            servico_id: item.servico_id,
            tipo_pagamento_id: item.tipo_pagamento_id,
            origem_pagamento_id: null,
            status_pagamento_id: 1,
            fornecedor_id: item.fornecedor_id,
            numero_parcela: num_parcela,
            data_vencimento: proximaData,
            data_pagamento: null,
            historico: `Parcela criada pela rotina de produção automática em ${dataHoraBRExibir(new Date())}`,
            valor: item.valor_referencia,
          }
          pagamentos.push(pagamento)
          num_parcela++
          proximaData =
            item.forma_pagamento_id === 3
              ? adicionarMes(proximaData)
              : adicionarAno(proximaData)
        }
      }
      return true
    })
    console.log('recebimentos: ', recebimentos)
    console.log('pagamentos: ', pagamentos)
    setListaRecebimentos(recebimentos)
    setListaPagamentos(pagamentos)
  }

  const total = (lista) => {
    let total = parseFloat(
      lista
        .map((item) => parseFloat(item.valor))
        .reduce((prev, curr) => prev + curr, 0.0)
    )
    return total.toFixed(2)
  }

  const salvarParcelas = async () => {
    if (listaRecebimentos) {
      const res = await dispatch(saveAllRecebimento(listaRecebimentos))
      if (!!res?.error) {
        setDialogError(
          `Adicionar parcelas de recebimento automáticas: ${res.error?.code} - ${res.error?.message}`
        )
        setOpenDialogErro(true)
        setOpen(false)
      } else {
        setOpenAviso(true)
        setOpen(false)
      }
    }
    if (listaPagamentos) {
      const resp = await dispatch(saveAllPagamento(listaPagamentos))
      if (!!resp?.error) {
        setDialogError(
          `Adicionar parcelas de pagamento automáticas: ${resp.error?.code} - ${resp.error?.message}`
        )
        setOpenDialogErro(true)
        setOpen(false)
      } else {
        setOpenAviso(true)
        setOpen(false)
      }
    }
  }

  return (
    <div>
      <Tooltip title="Criar Parcelas" placement="top">
        <Button
          variant="outlined"
          startIcon={<ConstructionIcon />}
          onClick={handleClickOpen}
        >
          Criar Novas Parcelas
        </Button>
      </Tooltip>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth={maxWidth}
      >
        <DialogTitle
          sx={{ display: 'flex', gap: 2, backgroundColor: '#262626' }}
          color="white"
        >
          Gerador de Parcelas
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box mt={2} mb={2}>
            <Typography component={'div'} variant="body1" gutterBottom>
              <strong>Totalização das parcelas produzidas pelo sistema.</strong>
            </Typography>
            <Typography component={'div'} variant="body2" gutterBottom>
              Confira a totalização e confirme, ao final, para que as parcelas
              sejam efetivamente gravadas no banco de dados do sistema.
            </Typography>
          </Box>
          <Box mb={2}>
            <Button variant="outlined" onClick={calcularParcelas} mb={2}>
              Iniciar
            </Button>
          </Box>
          <Divider />
          <Box mt={1}>
            <strong>Recebimentos </strong>(entradas)
          </Box>
          <Box>Quantidade de Parcelas Criadas: {listaRecebimentos.length}</Box>
          <Box mb={2}>
            Valor total das parcelas: {valorMoeda(total(listaRecebimentos))}
          </Box>
          <Divider />
          <Box mt={1}>
            <strong>Pagamentos </strong>(saídas)
          </Box>
          <Box>Quantidade de Parcelas Criadas: {listaPagamentos.length}</Box>
          <Box mb={2}>
            Valor total das parcelas: {valorMoeda(total(listaPagamentos))}
          </Box>
          <Divider />
          <Box mt={2}>
            <Button variant="outlined" onClick={salvarParcelas} mb={2}>
              Salvar Parcelas
            </Button>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClose}
            startIcon={<CancelIcon fontSize="small" />}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Parcelas adicionadas com sucesso!!!`}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir as parcelas. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </div>
  )
}
