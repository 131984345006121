import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useRoutes } from 'hookrouter'
import { Provider } from 'react-redux'
import './App.css'
import Auxiliar from './auxiliar/components'
import Cadastro from './components/cadastro'
import Pagamentos from './components/cadastro/pagamentos'
import Pessoas from './components/cadastro/pessoas'
import Projetos from './components/cadastro/projetos'
import Recebimentos from './components/cadastro/recebimentos'
import Servicos from './components/cadastro/servicos'
import Tabelas from './components/cadastro/tabelas'
import FormasPagamento from './components/cadastro/tabelas/FormasPagamento'
import GruposPessoas from './components/cadastro/tabelas/GruposPessoas'
import OrigensPagamentos from './components/cadastro/tabelas/OrigensPagamentos'
import OrigensRecebimentos from './components/cadastro/tabelas/OrigensRecebimentos'
import PeriodicidadesServicos from './components/cadastro/tabelas/PeriodicidadesServicos'
import StatusPagamentos from './components/cadastro/tabelas/StatusPagamentos'
import StatusProjetos from './components/cadastro/tabelas/StatusProjetos'
import StatusRecebimentos from './components/cadastro/tabelas/StatusRecebimentos'
import StatusServicos from './components/cadastro/tabelas/StatusServicos'
import TiposEndereco from './components/cadastro/tabelas/TiposEndereco'
import TiposPagamento from './components/cadastro/tabelas/TiposPagamento'
import TiposProjeto from './components/cadastro/tabelas/TiposProjeto'
import TiposRedesSociais from './components/cadastro/tabelas/TiposRedesSociais'
import TiposServico from './components/cadastro/tabelas/TiposServico'
import TiposTelefone from './components/cadastro/tabelas/TiposTelefone'
import Usuarios from './components/cadastro/usuarios'
import Dashboard from './components/dashboard'
import Home from './components/home'
import Login from './components/login'
import AlterarSenha from './components/login/alterarSenha'
import NaoAutorizado from './components/login/naoAutorizado'
import PaginaNaoEncontrada from './components/login/paginaNaoEncontrada'
import Preferencias from './components/login/preferencias'
import Producao from './components/producao'
import Parcelas from './components/producao/parcelas'
import Relatorios from './components/relatorios'
import ContasAReceber from './components/relatorios/contasAReceber'
import ControleGeral from './components/relatorios/controleGeral'
import FechamentoPeriodo from './components/relatorios/fechamentoPeriodo'
import PagamentosAClassificar from './components/relatorios/pagamentosAClassificar'
import Configuracao from './components/sistema/configuracao'
import Contas from './components/sistema/contas'
import Documentacao from './components/sistema/documentacao'
import Tarefas from './components/sistema/tarefas'
import Atividades from './components/sistema/tarefas/atividades'
import Equipes from './components/sistema/tarefas/equipes'
import MinhasTarefas from './components/sistema/tarefas/paineis/minhasTarefas'
import Versoes from './components/sistema/versoes'
import autorizacao from './middleware/autorizacao'
import store from './redux/store'

const theme = createTheme({
  palette: {
    primary: {
      main: '#262626',
      dark: '#000000',
      light: '#4e4e4e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fae10b',
      dark: '#c3b000',
      light: '#ffff57',
      contrastText: '#000',
    },
    third: {
      main: '#50a19d',
      dark: '#18726f',
      light: '#82d2ce',
      contrastText: '#fff',
    },
    alternativo: {
      main: '#0d47a1',
      dark: '#002171',
      light: '#5472d3',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
      paper: '#f5f5f5',
      error: '#D33131',
      warning: '#ff9800',
      alternativo: '#0d47a1',
    },
  },
})

const routes = {
  '/': () => <Login />,
  '/home': () => <Home />,
  '/dashboard': () => (autorizacao() ? <Dashboard /> : <NaoAutorizado />),
  '/usuarios': () => (autorizacao() ? <Usuarios /> : <NaoAutorizado />),
  '/usuarios/alterarsenha': () => <AlterarSenha />,
  '/usuarios/preferencias': () => <Preferencias />,
  '/cadastro': () => (autorizacao() ? <Cadastro /> : <NaoAutorizado />),
  '/cadastro/pessoas': () => (autorizacao() ? <Pessoas /> : <NaoAutorizado />),
  '/cadastro/projetos': () =>
    autorizacao() ? <Projetos /> : <NaoAutorizado />,
  '/cadastro/servicos': () =>
    autorizacao() ? <Servicos /> : <NaoAutorizado />,
  '/cadastro/recebimentos': () =>
    autorizacao() ? <Recebimentos /> : <NaoAutorizado />,
  '/cadastro/pagamentos': () =>
    autorizacao() ? <Pagamentos /> : <NaoAutorizado />,
  '/auxiliar': () => (autorizacao() ? <Auxiliar /> : <NaoAutorizado />),
  '/relatorios': () => (autorizacao([1]) ? <Relatorios /> : <NaoAutorizado />),
  '/relatorios/fechamentoPeriodo': () =>
    autorizacao([1]) ? <FechamentoPeriodo /> : <NaoAutorizado />,
  '/relatorios/pagamentosAClassificar': () =>
    autorizacao([1]) ? <PagamentosAClassificar /> : <NaoAutorizado />,
  '/relatorios/contasAReceber': () =>
    autorizacao([1]) ? <ContasAReceber /> : <NaoAutorizado />,
  '/relatorios/controleGeral': () =>
    autorizacao([1]) ? <ControleGeral /> : <NaoAutorizado />,
  '/producao': () => (autorizacao() ? <Producao /> : <NaoAutorizado />),
  '/producao/parcelas': () =>
    autorizacao() ? <Parcelas /> : <NaoAutorizado />,
  '/sistema/versoes': () => (autorizacao() ? <Versoes /> : <NaoAutorizado />),
  '/sistema/documentacao': () =>
    autorizacao() ? <Documentacao /> : <NaoAutorizado />,
  '/sistema/configuracao': () =>
    autorizacao() ? <Configuracao /> : <NaoAutorizado />,
  '/naoautorizado': () => <NaoAutorizado />,
  '/cadastro/tabelas': () => (autorizacao() ? <Tabelas /> : <NaoAutorizado />),
  '/cadastro/tabelas/formaspagamento': () =>
    autorizacao() ? <FormasPagamento /> : <NaoAutorizado />,
  '/cadastro/tabelas/grupospessoas': () =>
    autorizacao() ? <GruposPessoas /> : <NaoAutorizado />,
  '/cadastro/tabelas/origenspagamento': () =>
    autorizacao() ? <OrigensPagamentos /> : <NaoAutorizado />,
  '/cadastro/tabelas/origensrecebimento': () =>
    autorizacao() ? <OrigensRecebimentos /> : <NaoAutorizado />,
  '/cadastro/tabelas/periodicidadeservico': () =>
    autorizacao() ? <PeriodicidadesServicos /> : <NaoAutorizado />,
  '/cadastro/tabelas/statuspagamento': () =>
    autorizacao() ? <StatusPagamentos /> : <NaoAutorizado />,
  '/cadastro/tabelas/statusrecebimento': () =>
    autorizacao() ? <StatusRecebimentos /> : <NaoAutorizado />,
  '/cadastro/tabelas/statusprojeto': () =>
    autorizacao() ? <StatusProjetos /> : <NaoAutorizado />,
  '/cadastro/tabelas/statusservico': () =>
    autorizacao() ? <StatusServicos /> : <NaoAutorizado />,
  '/cadastro/tabelas/tiposendereco': () =>
    autorizacao() ? <TiposEndereco /> : <NaoAutorizado />,
  '/cadastro/tabelas/tipospagamento': () =>
    autorizacao() ? <TiposPagamento /> : <NaoAutorizado />,
  '/cadastro/tabelas/tiposprojeto': () =>
    autorizacao() ? <TiposProjeto /> : <NaoAutorizado />,
  '/cadastro/tabelas/tiposredessociais': () =>
    autorizacao() ? <TiposRedesSociais /> : <NaoAutorizado />,
  '/cadastro/tabelas/tiposservico': () =>
    autorizacao() ? <TiposServico /> : <NaoAutorizado />,
  '/cadastro/tabelas/tipostelefone': () =>
    autorizacao() ? <TiposTelefone /> : <NaoAutorizado />,
  '/sistema/contas': () => (autorizacao() ? <Contas /> : <NaoAutorizado />),

  '/sistema/tarefas': () => (autorizacao() ? <Tarefas /> : <NaoAutorizado />),
  '/sistema/tarefas/equipes': () =>
    autorizacao() ? <Equipes /> : <NaoAutorizado />,
  '/sistema/tarefas/atividades': () =>
    autorizacao() ? <Atividades /> : <NaoAutorizado />,
  '/sistema/tarefas/minhasTarefas': () =>
    autorizacao() ? <MinhasTarefas /> : <NaoAutorizado />,
}

function App() {
  const routeResult = useRoutes(routes)
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {routeResult || <PaginaNaoEncontrada />}
      </Provider>
    </ThemeProvider>
  )
}

export default App
