import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import LinkIcon from '@mui/icons-material/Link'
import SaveIcon from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ErrorIcon from '@mui/icons-material/Error'
import TableRecebimentos from './tableRecebimentos'
import TablePagamentos from './tablePagamentos'
import { salvarVinculos } from '../../../../redux/sistemas/contas/movimentosSlice'
import { baixarVinculados } from '../../../../redux/cadastro/recebimentoSlice'
import { baixarVinculadosPagamentos } from '../../../../redux/cadastro/pagamentoSlice'

export const FormVinculos = (props) => {
  const {
    registro,
    salvarVinculos,
    salvarRecebimentos,
    salvarPagamentos,
    setAtualizaListaMovimentos,
  } = props

  const [open, setOpen] = useState(false)
  const [vlrVinculado, setVlrVinculado] = useState(0.0)
  const [vinculosSelecionados, setVinculosSelecionados] = useState([])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleVincular = async () => {
    let registrosVinculos = []
    const tipo = registro.natureza
    const dataMovimento = registro.data
    let vinculos = []
    if (tipo === 'D') {
      vinculos = vinculosSelecionados.map((p) => {
        return {
          movimento_id: registro.id,
          pagamento_id: p.id,
        }
      })
    } else {
      vinculos = vinculosSelecionados.map((p) => {
        return {
          movimento_id: registro.id,
          recebimento_id: p.id,
        }
      })
    }
    registrosVinculos = { tipo, dataMovimento, vinculos }
    await salvarVinculos(registrosVinculos)
    await baixarParcelas(registrosVinculos)
    setAtualizaListaMovimentos(true)
    handleClose()
  }

  const baixarParcelas = (registro) => {
    if (registro.tipo === 'C') {
      salvarRecebimentos(registro)
    } else {
      salvarPagamentos(registro)
    }
    return null
  }

  return (
    <div>
      <Tooltip
        title={<Box sx={{ fontSize: '0.9rem' }}>Vincular Valores</Box>}
        placement="top"
      >
        <IconButton color="primary" onClick={handleClickOpen} size="small">
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        sx={{ minHeight: '100vh', maxHeight: '100vh' }}
      >
        <DialogTitle sx={{ p: 3 }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>Vincular Valores de Movimento</Box>
            <Box sx={{ fontSize: '12px' }}>
              <Box>
                Movimento:{' '}
                {Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(registro.valor)}
              </Box>
              <Box>
                Vinculado:{' '}
                {Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(vlrVinculado)}
              </Box>
            </Box>
            <Box sx={{ fontSize: '12px' }}>
              Diferença:{' '}
              {Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(registro.valor - vlrVinculado)}
            </Box>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={4}>
              {registro.natureza === 'D'
                ? 'Pagamentos em aberto'
                : 'Recebimentos em aberto'}
            </Grid>
          </Grid>
          {registro.natureza === 'D' ? (
            <Box>
              <TablePagamentos
                setVlrVinculado={setVlrVinculado}
                setVinculosSelecionados={setVinculosSelecionados}
              />
            </Box>
          ) : (
            <Box>
              <TableRecebimentos
                setVlrVinculado={setVlrVinculado}
                setVinculosSelecionados={setVinculosSelecionados}
              />
            </Box>
          )}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ px: 3, py: 2, gap: 1 }}>
          <Grid container>
            <Grid item xs={8}>
              {registro.valor - vlrVinculado !== 0.0 && vlrVinculado ? (
                <Box display={'flex'} gap={2} alignItems="center">
                  <ErrorIcon sx={{ color: 'red' }} />
                  <Box sx={{ color: 'red', fontSize: '13px' }}>
                    O valor do movimento na conta não corresponde à soma dos
                    valores selecionados para a vinculação.
                  </Box>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              <Box display={'flex'} gap={2}>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  startIcon={<SaveIcon />}
                  disabled={
                    !vlrVinculado || registro.valor - vlrVinculado !== 0.0
                  }
                  onClick={() => handleVincular()}
                >
                  Vincular
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  type="reset"
                  onClick={handleClose}
                  startIcon={<ArrowBackIcon />}
                >
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
  return {
    salvarVinculos: (registro) => dispatch(salvarVinculos(registro)),
    salvarRecebimentos: (registro) => dispatch(baixarVinculados(registro)),
    salvarPagamentos: (registro) =>
      dispatch(baixarVinculadosPagamentos(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormVinculos)
