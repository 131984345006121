import { AddCommentTwoTone, DeleteTwoTone } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'
import { Field, FieldArray, Form, Formik } from 'formik'
import { Select } from 'formik-mui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  changeAbasPessoa,
  selectAbasPessoa,
} from '../../../redux/auxiliar/abasPessoaSlice'
import { saveEndereco } from '../../../redux/cadastro/pessoa/EnderecoSlice'
import {
  fetchPessoa,
  selectPessoaAtual,
} from '../../../redux/cadastro/pessoa/pessoaSlice'
import {
  fetchTiposEndereco,
  selectTiposEndereco,
} from '../../../redux/cadastro/tabelas/tiposEnderecoSlice'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const FormEnderecos = (props) => {
  const dispatch = useDispatch()
  const pessoaAtual = useSelector(selectPessoaAtual)
  const tiposEndereco = useSelector(selectTiposEndereco)
  const abasPessoa = useSelector(selectAbasPessoa)
  let abasPessoaCopia = JSON.parse(JSON.stringify(abasPessoa))

  useEffect(() => {
    dispatch(fetchPessoa(pessoaAtual?.id))
    dispatch(fetchTiposEndereco())
  }, [dispatch, pessoaAtual?.id])

  let initialValues = []

  if (pessoaAtual?.enderecos?.length > 0) {
    initialValues = { enderecos: pessoaAtual?.enderecos }
  } else {
    initialValues = {
      enderecos: [
        {
          id: '',
          grupo_pessoa_id: pessoaAtual?.grupo_pessoa_id || '',
          pessoa_id: pessoaAtual?.id || '',
          tipo_endereco_id: '',
          cep: '',
          logradouro: '',
          numero: '',
          complemento: '',
          cidade: '',
          bairro: '',
          uf: '',
        },
      ],
    }
  }

  const validationSchema = Yup.object({
    enderecos: Yup.array().of(
      Yup.object({
        tipo_endereco_id: Yup.string().required('Informe o tipo de endereço'),
        cep: Yup.string().required('Informe o CEP'),
        logradouro: Yup.string().required('Informe o logradouro'),
        numero: Yup.string().required('Informe o numero'),
        cidade: Yup.string().required('Informe a cidade'),
        bairro: Yup.string().required('Informe o bairro'),
        uf: Yup.string().required('Informe o estado(uf)'),
      })
    ),
  })

  const tiposEnderecoOptions = tiposEndereco.map((item) => {
    return {
      value: item.id,
      label: item.nome,
    }
  })

  const onSubmit = (values) => {
    const ends = values.enderecos
    ends.forEach((element) => {
      dispatch(saveEndereco(element))
    })
    props.setAlterado(true)
    abasPessoaCopia.status.redesSociais = false
    dispatch(changeAbasPessoa(abasPessoaCopia.status))
    dispatch(fetchPessoa(pessoaAtual?.id))
    initialValues = { enderecos: pessoaAtual?.enderecos }
    props.setValue(4)
  }

  return (
    <Grid container justify="center" flexDirection="column" spacing={1}>
      <Grid item sx={{ width: '100%' }}>
        <Card sx={{ width: '100%' }}>
          <CardHeader title="Endereços" subtitle={props.value}></CardHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              setFieldValue,
              helperText,
            }) => {
              return (
                <Form>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '15px',
                      }}
                    >
                      <FieldArray name="enderecos">
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps
                          const {
                            values,
                            handleBlur,
                            handleChange,
                            touched,
                            errors,
                          } = form
                          const { enderecos } = values

                          return (
                            <Stack>
                              {enderecos && enderecos.length > 0
                                ? enderecos.map((endereco, index) => {
                                    return (
                                      <Box key={index}>
                                        <Grid
                                          container
                                          flexDirection="row"
                                          spacing={1}
                                        >
                                          <Grid item sx={{ mt: -1 }}>
                                            <Field
                                              label="Tipo de Endereço"
                                              component={Select}
                                              size="small"
                                              name={`enderecos[${index}].tipo_endereco_id`}
                                              id={`enderecos[${index}].tipo_endereco_id`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              sx={{ mt: 1, width: '222px' }}
                                              value={
                                                values.enderecos?.[index]
                                                  .tipo_endereco_id || ''
                                              }
                                              defaultValue={
                                                values.enderecos?.[index]
                                                  .tipo_endereco_id || ''
                                              }
                                            >
                                              {tiposEnderecoOptions.map(
                                                (option) => {
                                                  return (
                                                    <MenuItem
                                                      key={option.value}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </MenuItem>
                                                  )
                                                }
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid item>
                                            <>
                                              <Field
                                                size="small"
                                                label="CEP"
                                                name={`enderecos[${index}].cep`}
                                                id={`enderecos[${index}].cep`}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={
                                                  values.enderecos[index].cep ||
                                                  ''
                                                }
                                                component={TextField}
                                                error={
                                                  Boolean(
                                                    touched.enderecos?.[index]
                                                      ?.cep
                                                  ) &&
                                                  Boolean(
                                                    errors.enderecos?.[index]
                                                      ?.cep
                                                  )
                                                }
                                                helperText={
                                                  touched.enderecos?.[index]
                                                    ?.cep &&
                                                  errors.enderecos?.[index]?.cep
                                                }
                                              />
                                            </>
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Logradouro"
                                              name={`enderecos[${index}].logradouro`}
                                              id={`enderecos[${index}].logradouro`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index]
                                                  .logradouro || ''
                                              }
                                              component={TextField}
                                              error={
                                                touched.enderecos?.[index]
                                                  ?.logradouro &&
                                                Boolean(
                                                  errors.enderecos?.[index]
                                                    ?.logradouro
                                                )
                                              }
                                              helperText={
                                                touched.enderecos?.[index]
                                                  ?.logradouro &&
                                                errors.enderecos?.[index]
                                                  ?.logradouro
                                              }
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Número"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index]
                                                  .numero || ''
                                              }
                                              component={TextField}
                                              name={`enderecos[${index}].numero`}
                                              id={`enderecos[${index}].numero`}
                                              error={
                                                touched.enderecos?.[index]
                                                  ?.numero &&
                                                Boolean(
                                                  errors.enderecos?.[index]
                                                    ?.numero
                                                )
                                              }
                                              helperText={
                                                touched.enderecos?.[index]
                                                  ?.numero &&
                                                errors.enderecos?.[index]
                                                  ?.numero
                                              }
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Complemento"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index]
                                                  .complemento || ''
                                              }
                                              component={TextField}
                                              name={`enderecos[${index}].complemento`}
                                              id={`enderecos[${index}].complemento`}
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Município"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index]
                                                  .cidade || ''
                                              }
                                              component={TextField}
                                              name={`enderecos[${index}].cidade`}
                                              id={`enderecos[${index}].cidade`}
                                              error={
                                                touched.enderecos?.[index]
                                                  ?.cidade &&
                                                Boolean(
                                                  errors.enderecos?.[index]
                                                    ?.cidade
                                                )
                                              }
                                              helperText={
                                                touched.enderecos?.[index]
                                                  ?.cidade &&
                                                errors.enderecos?.[index]
                                                  ?.cidade
                                              }
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Bairro"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index]
                                                  .bairro || ''
                                              }
                                              component={TextField}
                                              name={`enderecos[${index}].bairro`}
                                              id={`enderecos[${index}].bairro`}
                                              error={
                                                touched.enderecos?.[index]
                                                  ?.bairro &&
                                                Boolean(
                                                  errors.enderecos?.[index]
                                                    ?.bairro
                                                )
                                              }
                                              helperText={
                                                touched.enderecos?.[index]
                                                  ?.bairro &&
                                                errors.enderecos?.[index]
                                                  ?.bairro
                                              }
                                            />
                                          </Grid>

                                          <Grid item>
                                            <Field
                                              size="small"
                                              label="Estado (UF)"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={
                                                values.enderecos[index].uf || ''
                                              }
                                              component={TextField}
                                              name={`enderecos[${index}].uf`}
                                              id={`enderecos[${index}].uf`}
                                              error={
                                                touched.enderecos?.[index]
                                                  ?.uf &&
                                                Boolean(
                                                  errors.enderecos?.[index]?.uf
                                                )
                                              }
                                              helperText={
                                                touched.enderecos?.[index]
                                                  ?.uf &&
                                                errors.enderecos?.[index]?.uf
                                              }
                                            />
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{ m: 2, ml: 0 }}
                                        >
                                          <Button
                                            type="button"
                                            onClick={() =>
                                              push({
                                                id: '',
                                                grupo_pessoa_id:
                                                  pessoaAtual?.grupo_pessoa_id ||
                                                  '',
                                                pessoa_id:
                                                  pessoaAtual?.id || '',
                                              })
                                            }
                                          >
                                            <AddCommentTwoTone />
                                          </Button>
                                          {index > 0 && (
                                            <Button
                                              type="button"
                                              onClick={() => remove(index)}
                                            >
                                              <DeleteTwoTone />
                                            </Button>
                                          )}
                                        </Grid>
                                      </Box>
                                    )
                                  })
                                : null}
                            </Stack>
                          )
                        }}
                      </FieldArray>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ ml: 1 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      startIcon={<SaveIcon />}
                    >
                      Salvar
                    </Button>
                    {props.modo !== 'edicao' ? (
                      <Button
                        type="reset"
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                      >
                        Limpar
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="warning"
                      type="reset"
                      onClick={() => props.setModo('table')}
                      startIcon={<ArrowBackIcon />}
                    >
                      VOLTAR
                    </Button>
                  </CardActions>
                </Form>
              )
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FormEnderecos
