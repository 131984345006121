import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PasswordIcon from '@mui/icons-material/Password'
import {
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
//import FactoryIcon from '@mui/icons-material/Factory';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import EditIcon from '@mui/icons-material/Edit'
import HomeIcon from '@mui/icons-material/Home'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MoneyIcon from '@mui/icons-material/Money'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TaskIcon from '@mui/icons-material/Task'
import { navigate } from 'hookrouter'
import { useState } from 'react'

import Logo from '../../img/logo_ideiahub_white.png'

export default function DrawerMenu() {
  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [openMenu1, setOpenMenu1] = useState(false)

  const user = JSON.parse(localStorage.getItem('stateAutenticacao'))

  const handleClickMenu = () => {
    setOpenMenu(!openMenu)
  }
  const handleClickMenu1 = () => {
    setOpenMenu1(!openMenu1)
  }

  return (
    <div>
      <IconButton
        color="secondary"
        component="label"
        onClick={() => {
          setOpen(true)
        }}
      >
        <MenuOpenIcon />
      </IconButton>

      <Drawer open={open} anchor={'left'} onClose={() => setOpen(false)}>
        <Grid
          display={'flex'}
          justifyContent={'center'}
          sx={{ p: 1, width: '250px' }}
        >
          <img src={Logo} alt="Logotipo Ideia Go" loading="lazy" width={140} />
        </Grid>

        <Divider />

        <List dense>
          <ListItem button key={1} onClick={() => navigate('/home')}>
            <ListItemIcon>
              <HomeIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <Divider />
          {user.login.user?.tipo === 1 ? (
            <>
              <ListItem button key={2} onClick={() => navigate('/cadastro')}>
                <ListItemIcon>
                  <AppRegistrationIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={'Cadastro'} />
              </ListItem>

              <ListItem button key={3} onClick={() => navigate('/relatorios')}>
                <ListItemIcon>
                  <SummarizeIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={'Relatórios'} />
              </ListItem>
              {/* <ListItem button key={4} onClick={() => navigate('/producao')}>
                                <ListItemIcon><FactoryIcon sx={{color:'blue'}}/></ListItemIcon>
                                <ListItemText primary={'Produção1'} />
                            </ListItem> */}
            </>
          ) : null}

          <ListItem button onClick={handleClickMenu}>
            <ListItemIcon>
              <SettingsSuggestIcon sx={{ color: 'blue' }} />
            </ListItemIcon>
            <ListItemText primary="Sistema" />
            {openMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ ml: 4 }} dense>
              <ListItem button onClick={() => navigate('/sistema/contas')}>
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary="Contas" />
              </ListItem>
              <ListItem button onClick={() => navigate('/sistema/tarefas')}>
                <ListItemIcon>
                  <TaskIcon />
                </ListItemIcon>
                <ListItemText primary="Tarefas" />
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => navigate('/sistema/configuracao')}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Configuração" />
              </ListItem>
              <ListItem
                button
                onClick={() => navigate('/sistema/documentacao')}
              >
                <ListItemIcon>
                  <LocalLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Documentação" />
              </ListItem>
              <ListItem button onClick={() => navigate('/sistema/versoes')}>
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Versões" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={handleClickMenu1}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Perfil" />
            {openMenu1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMenu1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ ml: 4 }} dense>
              <ListItem
                button
                onClick={() => navigate('/usuarios/alterarsenha')}
              >
                <ListItemIcon>
                  <PasswordIcon sx={{ color: 'RebeccaPurple' }} />
                </ListItemIcon>
                <ListItemText primary="Trocar Senha" />
              </ListItem>
              {/* <ListItem button >
                            <ListItemIcon><StarBorder /></ListItemIcon>
                            <ListItemText primary="Teste 1" />
                        </ListItem> */}
            </List>
          </Collapse>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <LogoutIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <ListItemText>
              <Link
                href="/"
                color="inherit"
                // variant='body2'
                underline="none"
                sx={{
                  padding: '8px 0px 0px 0px',
                }}
              >
                Sair
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
    </div>
  )
}
