import { Box, Button, Divider, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../layouts'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import StoreIcon from '@mui/icons-material/Store'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import ListAltIcon from '@mui/icons-material/ListAlt'
import Bancos from './banco'
import Agencias from './agencia'
import ContaCorrente from './conta'
import TiposConta from './tiposConta'
import TiposMovimento from './tiposMovimento'

import { styled, alpha } from '@mui/material/styles'
import Movimento from './movimento'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 250,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 4px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const Contas = (props) => {
  //const [contas, setConta] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [itemCadastro, setItemCadastro] = useState(null)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Layout titulo="Gestão das Contas" subtitulo="Cadastro e Movimentos">
      <Box mt={12} sx={{ '& button': { mr: 4 } }}>
        <Button
          //color='inherit'
          id="sistema-button"
          onClick={handleClick}
          aria-controls={open ? 'sistema-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          endIcon={<KeyboardArrowDownIcon />}
          variant="contained"
          size="large"
          startIcon={<AppRegistrationIcon />}
        >
          Cadastro
        </Button>

        <StyledMenu
          id="sistema-menu"
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{
            'aria-labelledby': 'sistema-button',
          }}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              setItemCadastro(1)
              setAnchorEl(null)
            }}
          >
            <AccountBalanceIcon /> Bancos
          </MenuItem>
          <MenuItem
            onClick={() => {
              setItemCadastro(2)
              setAnchorEl(null)
            }}
          >
            <StoreIcon /> Agências
          </MenuItem>
          <MenuItem
            onClick={() => {
              setItemCadastro(3)
              setAnchorEl(null)
            }}
          >
            <CreditCardIcon />
            Contas
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setItemCadastro(4)
              setAnchorEl(null)
            }}
          >
            Tipo de Conta
          </MenuItem>
          <MenuItem
            onClick={() => {
              setItemCadastro(5)
              setAnchorEl(null)
            }}
          >
            Tipo de Movimento
          </MenuItem>
        </StyledMenu>

        <Button
          variant="contained"
          size="large"
          startIcon={<ListAltIcon />}
          onClick={() => {
            setItemCadastro(6)
            setAnchorEl(null)
          }}
        >
          Movimentos
        </Button>
        <Divider sx={{ mt: 2, mr: -4 }} />
      </Box>
      <Box>
        {itemCadastro === 1 ? <Bancos /> : null}
        {itemCadastro === 2 ? <Agencias /> : null}
        {itemCadastro === 3 ? <ContaCorrente /> : null}
        {itemCadastro === 4 ? <TiposConta /> : null}
        {itemCadastro === 5 ? <TiposMovimento /> : null}
        {itemCadastro === 6 ? <Movimento /> : null}
      </Box>
    </Layout>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Contas)
