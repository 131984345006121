import { Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import './form.css'
import FormikControl from './FormikControl'

function FormikContainer() {
  const dropdownOptions = [
    { key: 'Option 1', value: 'option1' },
    { key: 'Option 2', value: 'option2' },
    { key: 'Option 3', value: 'option3' },
    { key: 'Option 4', value: 'option4' },
  ]

  const radioOptions = [
    { key: 'Option 1', value: 'Option1' },
    { key: 'Option 2', value: 'Option2' },
    { key: 'Option 3', value: 'Option3' },
  ]

  const checkboxOptions = [
    { key: 'Option 1', value: 'cOption1' },
    { key: 'Option 2', value: 'cOption2' },
    { key: 'Option 3', value: 'cOption3' },
  ]

  const initialValues = {
    email: '',
    description: '',
    selectOption: '',
    radioOption: '',
    checkboxOption: [],
    birthDate: null,
  }
  const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Formato de email inválido'),
    description: Yup.string().required('Required'),
    selectOption: Yup.string().required('Required'),
    radioOption: Yup.string().required('Required'),
    checkboxOption: Yup.array().required('Required'),
    birthDate: Yup.date().required('Required').nullable(),
  })

  const onSubmit = (values) => {
    console.log('Form data', values)
    console.log('Saved data', JSON.parse(JSON.stringify(values)))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={3} sx={{ pt: 2 }}>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="input"
                type="email"
                label="E-Mail"
                name="email"
                xs
              />
            </Grid>

            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="select"
                label="Selecione um tópico"
                name="selectOption"
                options={dropdownOptions}
              />
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="radio"
                label="Radio topic"
                name="radioOption"
                options={radioOptions}
              />
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="checkbox"
                label="Checkbox topics"
                name="checkboxOption"
                options={checkboxOptions}
              />
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="textarea"
                label="Description"
                name="description"
                rows="4"
              />
            </Grid>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <FormikControl
                control="date"
                label="Selecione a data"
                name="birthDate"
              />
            </Grid>
          </Grid>
          <button type="submit">Salvar</button>
        </Form>
      )}
    </Formik>
  )
}

export default FormikContainer
