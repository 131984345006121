import { Avatar, Box } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'

export const BarraSuperior = (props) => {
  const { atividade } = props
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Box display="flex" justifyContent={'center'} alignItems="center">
        <Box>
          <Avatar />
        </Box>
        <Box display={'flex'} flexDirection={'column'} ml={2}>
          <Box sx={{ typography: 'title1', fontWeight: 'bold' }}>
            {atividade.titulo}
          </Box>
          <Box sx={{ typography: 'subtitle2' }}>{atividade.subTitulo}</Box>
        </Box>
      </Box>
      <Box>Menu</Box>
      <Box>Equipe</Box>
      <Box>Ações</Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BarraSuperior)
