import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  registros: [],
  error: {},
}

export const listarTiposMovimento = createAsyncThunk(
  'contas/listar/tiposMovimento',
  () => {
    const autorizacao = header()
    return api.get('/tiposMovimento', autorizacao).then((res) => res.data)
  }
)

export const salvarTipoMovimento = createAsyncThunk(
  'contas/salvar/tipoMovimento',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/tiposMovimento/salvar', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const excluirTipoMovimento = createAsyncThunk(
  'contas/excluir/tipoMovimento',
  (id) => {
    const autorizacao = header()
    return api
      .get(`/tiposMovimento/excluir/${id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'tiposMovimento',
  initialState: initialState,
  extraReducers: (builder) => {
    //Listar Tipos de Movimento
    builder.addCase(listarTiposMovimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(listarTiposMovimento.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(listarTiposMovimento.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Salvar Tipos de Movimento
    builder.addCase(salvarTipoMovimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarTipoMovimento.fulfilled, (state, action) => {
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )
      if (index === -1) {
        state.registros.push(action.payload.registro)
      } else {
        state.registros[index] = {
          ...state.registros[index],
          ...action.payload.registro,
        }
      }

      state.loading = false
    })
    // Excluir Tipo de Movimento
    builder.addCase(excluirTipoMovimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(excluirTipoMovimento.fulfilled, (state, action) => {
      const id = parseInt(action.payload)
      state.registros = state.registros.filter((registro) => registro.id !== id)
      state.loading = false
    })
  },
})

export default slice.reducer
