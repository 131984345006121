import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { selectPessoaAtual } from '../../../redux/cadastro/pessoa/pessoaSlice'
import TextError from '../../layouts/TextError'
import { savePessoaFisica } from '../../../redux/cadastro/pessoa/pessoaFisicaSlice'
import {
  changeAbasPessoa,
  selectAbasPessoa,
} from '../../../redux/auxiliar/abasPessoaSlice'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const FormDadosPF = (props) => {
  const dispatch = useDispatch()

  let pessoaAtual = {}
  pessoaAtual = useSelector(selectPessoaAtual)

  const abasPessoa = useSelector(selectAbasPessoa)
  let abasPessoaCopia = JSON.parse(JSON.stringify(abasPessoa))

  let initialValues = {
    id: '',
    pessoa_id: pessoaAtual?.id || '',
    cpf: '',
    sexo: '',
    data_nascimento: '',
  }

  if (pessoaAtual?.pessoaFisica) {
    initialValues = {
      id: pessoaAtual?.pessoaFisica?.id || '',
      pessoa_id: pessoaAtual?.pessoaFisica?.pessoa_id || pessoaAtual?.id,
      cpf: pessoaAtual?.pessoaFisica?.cpf || '',
      sexo: pessoaAtual?.pessoaFisica?.sexo || '',
      data_nascimento: pessoaAtual?.pessoaFisica?.data_nascimento,
    }
  }

  const validationSchema = Yup.object({
    cpf: Yup.string()
      .min(11, 'Minimo de 11 caracteres')
      .max(15, 'Máximo de 15 caracteres')
      .required('Informe o número do CPF'),
    sexo: Yup.number('O valor não é numérico').required(
      'Informe o sexo da pessoa'
    ),
  })

  const onSubmit = async (values) => {
    await dispatch(savePessoaFisica(values))
    props.setAlterado(true)
    abasPessoaCopia.status.enderecos = false
    dispatch(changeAbasPessoa(abasPessoaCopia.status))
    props.setValue(3)
  }

  return (
    <Grid container justify="center" flexDirection="column" spacing={1}>
      <Grid item sx={{ width: '100%' }}>
        <Card sx={{ width: '100%' }}>
          <CardHeader
            title="Dados da pessoa física"
            subtitle={props.value}
          ></CardHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '15px',
                      }}
                    >
                      <Field
                        size="small"
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        name="cpf"
                        id="cpf"
                        component={TextField}
                        value={values.cpf}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.cpf && Boolean(errors.cpf)}
                        helperText={touched.cpf && errors.cpf}
                      />

                      <Field
                        size="small"
                        fullWidth
                        component={TextField}
                        id="data_nascimento"
                        name="data_nascimento"
                        type="date"
                        label="Data de Nascimento"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.data_nascimento || ''}
                        format="MM/dd/yyyy"
                      />

                      <FormLabel component="legend">Sexo:</FormLabel>
                      <label>
                        <Field
                          type="radio"
                          name="sexo"
                          id="sexo1"
                          value="1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          selected={values.sexo === 1}
                        />
                        Masculino
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="sexo"
                          id="sexo2"
                          value="2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          selected={values.sexo === 2}
                        />
                        Feminino
                      </label>
                      <ErrorMessage
                        name="data_nascimento"
                        component={TextError}
                      />
                    </Box>
                  </CardContent>
                  <CardActions sx={{ ml: 1 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      startIcon={<SaveIcon />}
                    >
                      Salvar
                    </Button>
                    {props.modo !== 'edicao' ? (
                      <Button
                        type="reset"
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                      >
                        Limpar
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="warning"
                      type="reset"
                      onClick={() => props.setModo('table')}
                      startIcon={<ArrowBackIcon />}
                    >
                      VOLTAR
                    </Button>
                  </CardActions>
                </Form>
              )
            }}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FormDadosPF
