import { checarData, dataBRExibir } from '../../../funcoes/data'

export default function COLUMNS() {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      Footer: 'Id',
      disableFilters: true,
      sticky: 'left',
    },
    {
      Header: 'Nome',
      accessor: 'name',
      Footer: 'Nome',
      sticky: 'left',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      Footer: 'E-mail',
      sticky: 'left',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Footer: 'Status',
      sticky: 'left',
      Cell: (row) => {
        if (row.value === 1) return 'Ativo'
        if (row.value === 2) return 'Inativo'
        if (row.value === 3) return 'Excluído'
      },
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      Footer: 'Tipo',
      sticky: 'left',
      Cell: (row) => {
        if (row.value === 1) return 'Administrador'
        if (row.value === 2) return 'Gerente'
        if (row.value === 3) return 'Operador'
      },
    },
    {
      Header: 'Inclusão',
      accessor: 'created_at',
      Footer: 'Inclusão',
      //disableFilters: true,
      Cell: ({ value }) => {
        if (checarData(value)) {
          return dataBRExibir(value)
        }
      },
    },
    {
      Header: 'Alteração',
      accessor: 'updated_at',
      Footer: 'Alteração',
      //disableFilters: true,
      Cell: ({ value }) => {
        if (checarData(value)) {
          return dataBRExibir(value)
        }
      },
    },
  ]
}
