import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart'
import { navigate } from 'hookrouter'

function ItemTabela(props) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemButton
        component="a"
        //href={props.item.rota}
        onClick={() => navigate(props.item.rota)}
      >
        <ListItemIcon>
          <PivotTableChartIcon />
        </ListItemIcon>
        <ListItemText
          primary={props.item.titulo}
          secondary={props.item.descricao}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default ItemTabela
