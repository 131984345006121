import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { listarTiposMovimento } from '../../../../redux/sistemas/contas/tiposMovimentoSlice'
import { atualizarSaldo } from '../../../../redux/sistemas/contas/contasSlice'
import { useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { salvarMovimento } from '../../../../redux/sistemas/contas/movimentosSlice'

const validationSchema = yup.object({
  tipo_movimento_id: yup
    .string('Selecione o Tipo de Movimento')
    .required('Um Tipo de Movimento precisa ser selecionado'),
  data: yup
    .string('Informe a data do Movimento')
    .required('Uma Data de Movimento precisa ser informada'),
  natureza: yup
    .string('Selecione a Natureza do Movimento')
    .required('Uma Natureza de Movimento precisa ser selecionada'),
  valor: yup
    .string('Informe o Valor do Movimento')
    .required('Um Valor de Movimento é obrigatório'),
})

export const FormMovimento = (props) => {
  const {
    acao,
    listarTiposMovimento,
    tiposMovimento,
    contaSelecionada,
    atualizarSaldo,
    setSaldo,
    salvarMovimento,
    registro,
  } = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    listarTiposMovimento()
  }, [listarTiposMovimento])

  const formik = useFormik({
    initialValues: {
      id: registro?.id || '',
      conta_id: registro?.conta_id || '',
      tipo_movimento_id: registro?.tipo_movimento_id || '',
      data: registro?.data.substring(0, 10) || '',
      natureza: registro?.natureza || '',
      valor: registro?.valor || '',
      historico: registro?.historico || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      atualizarMovimento(values, registro)
      handleClose()
    },
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const atualizarMovimento = async (registroAtual, registroAnterior) => {
    const registro = {
      registroAtual: { ...registroAtual, conta_id: contaSelecionada },
      registroAnterior,
      conta_id: contaSelecionada,
    }
    salvarMovimento(registro)
    const result = await atualizarSaldo(registro)
    setSaldo(result.payload.registro.saldo)
  }

  return (
    <div>
      {acao === 'add' ? (
        <Tooltip
          title={<Box sx={{ fontSize: '0.9rem' }}>Adicionar Movimento</Box>}
          placement="top"
        >
          <IconButton color="primary" onClick={handleClickOpen} size="small">
            <AddCircleIcon color="success" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Box sx={{ fontSize: '0.9rem' }}>Alterar Movimento</Box>}
          placement="top"
        >
          <IconButton color="primary" onClick={handleClickOpen} size="small">
            <EditIcon color="warning" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth>
        <Grid component="form" onSubmit={formik.handleSubmit}>
          <DialogTitle>
            {acao === 'alt' ? 'Alterar Movimento' : 'Adicionar Movimento'}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box mt={2} display="flex" flexDirection={'column'} gap={3}>
              <TextField
                select
                size="small"
                fullWidth
                value={formik.values.tipo_movimento_id}
                name="tipo_movimento_id"
                label="Tipo de Movimento"
                onChange={formik.handleChange}
                error={
                  formik.touched.tipo_movimento_id &&
                  Boolean(formik.errors.tipo_movimento_id)
                }
                helperText={
                  formik.touched.tipo_movimento_id &&
                  formik.errors.tipo_movimento_id
                }
              >
                {tiposMovimento.registros.map((banco, index) => (
                  <MenuItem value={banco.id} key={index}>
                    {banco.nome}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                size="small"
                type="date"
                label={'Data do Movimento'}
                InputLabelProps={{ shrink: true }}
                value={formik.values.data}
                onChange={(e) => formik.setFieldValue('data', e.target.value)}
                error={formik.touched.data && Boolean(formik.errors.data)}
                helperText={formik.touched.data && formik.errors.data}
              />

              <TextField
                select
                size="small"
                fullWidth
                value={formik.values.natureza}
                name="natureza"
                label="Natureza do Movimento (D/C)"
                onChange={formik.handleChange}
                error={
                  formik.touched.natureza && Boolean(formik.errors.natureza)
                }
                helperText={formik.touched.natureza && formik.errors.natureza}
              >
                <MenuItem value="C" key="C">
                  Crédito
                </MenuItem>
                <MenuItem value="D" key="D">
                  Débito
                </MenuItem>
              </TextField>

              <TextField
                size="small"
                type="number"
                fullWidth
                name="valor"
                label="Valor do Movimento"
                value={formik.values.valor}
                onChange={formik.handleChange}
                error={formik.touched.valor && Boolean(formik.errors.valor)}
                helperText={formik.touched.valor && formik.errors.valor}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
              <TextField
                multiline
                rows={4}
                label="Histórico"
                fullWidth
                value={formik.values.historico}
                name="historico"
                onChange={formik.handleChange}
                error={
                  formik.touched.historico && Boolean(formik.errors.historico)
                }
                helperText={formik.touched.historico && formik.errors.historico}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              color="warning"
              type="reset"
              onClick={handleClose}
              startIcon={<ArrowBackIcon />}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    tiposMovimento: {
      loading: state.tiposMovimento.loading,
      registros: state.tiposMovimento.registros,
      error: state.tiposMovimento.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listarTiposMovimento: () => dispatch(listarTiposMovimento()),
    atualizarSaldo: (registro) => dispatch(atualizarSaldo(registro)),
    salvarMovimento: (registro) => dispatch(salvarMovimento(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormMovimento)
