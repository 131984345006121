function jwtInterceptor() {
  const auth = JSON.parse(localStorage.getItem('stateAutenticacao')) || ''
  let config = {
    headers: {
      Authorization: 'Bearer ' + auth.login?.token,
    },
  }
  return config
}
export default jwtInterceptor
