import api from '../../services/api'

import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGOUT,
} from './authTypes'

export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST,
    payload: true,
  }
}

export const fetchLoginSuccess = (login) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: login,
  }
}

export const fetchUsersLogout = (error) => {
  return {
    type: FETCH_LOGOUT,
    payload: error,
  }
}

export const authLogin = (login) => {
  return (dispatch) => {
    dispatch(fetchLoginRequest)
    try {
      api
        .post('/users/authLogin', login)
        .then((response) => {
          //setTimeout(() => {
          const login = response.data
          dispatch(fetchLoginSuccess(login))
          localStorage.setItem('stateAutenticacao', JSON.stringify(login))
          //}, 1000)
        })
        .catch((error) => {
          const errorMsg = error.message
          dispatch(fetchUsersLogout(errorMsg))
        })
    } catch (e) {
      console.log(e)
    }
  }
}
