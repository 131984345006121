import { Box, FormControl } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import React from 'react'

const Textarea = (props) => {
  const { label, name, rows, ...rest } = props
  return (
    <Box>
      <FormControl fullWidth>
        <Field
          component={TextField}
          id={name}
          name={name}
          label={label}
          size="small"
          rows={rows}
          multiline
          {...rest}
        />
      </FormControl>
    </Box>
    // <FormControl fullWidth sx={{marginBottom:'20px',}}>
    //     <TextField
    //         id={name}
    //         name={name}
    //         label={label}
    //         {...rest }
    //         multiline
    //         rows={rows}
    //     />
    //     <ErrorMessage name={name} component={TextError}/>
    // </FormControl>
  )
}

export default Textarea
