import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { connect } from 'react-redux'
import {
  excluirTipoMovimento,
  listarTiposMovimento,
} from '../../../../redux/sistemas/contas/tiposMovimentoSlice'
import { useEffect } from 'react'
import { useState } from 'react'
import { Box } from '@mui/material'
import Loading from '../../../layouts/Loading'
import DialogErro from '../../../layouts/DialogErro'
import { dataHoraBRExibir } from '../../../../funcoes/data'
import FormConta from './formTiposMovimento'
import DialogExclusao from '../../../layouts/DialogExclusao'

export const TableTiposMovimento = (props) => {
  const { tiposMovimento, error, listarTiposMovimento, excluirTipoMovimento } =
    props
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [atualizarLista, setAtualizarLista] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [idExclusao, setIdExclusao] = useState('')

  useEffect(() => {
    if (atualizarLista) {
      listarTiposMovimento()
      setAtualizarLista(false)
    }
    if (error) setOpenDialogErro(true)
  }, [atualizarLista, error, listarTiposMovimento])

  const rows = tiposMovimento.registros

  const handleExcluir = (idExclusao) => {
    excluirTipoMovimento(idExclusao)
  }

  return (
    <Box>
      {tiposMovimento.loading ? (
        <Loading />
      ) : tiposMovimento.error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={error}
          mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : (
        <>
          <FormConta
            registro={null}
            acao="add"
            tiposMovimento={tiposMovimento.registros}
          />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'Silver' }}>
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>NOME</TableCell>
                  <TableCell>DESCRIÇÃO</TableCell>
                  <TableCell align="center">INCLUSÃO</TableCell>
                  <TableCell align="center">ALTERAÇÃO</TableCell>
                  <TableCell align="center">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.nome}</TableCell>
                    <TableCell>{row.descricao}</TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.updated_at)}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FormConta
                          registro={row}
                          acao="alt"
                          tiposMovimento={tiposMovimento.registros}
                        />
                        <DialogExclusao
                          title="o Tipo de Movimento "
                          referencia={`Tipo de Movimento: ${row.nome} / ${row.descricao}`}
                          idExclusao={row.id}
                          setIdExclusao={setIdExclusao}
                          handleExcluir={handleExcluir}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    tiposMovimento: {
      loading: state.tiposMovimento.loading,
      registros: state.tiposMovimento.registros,
      error: state.tiposMovimento.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listarTiposMovimento: () => dispatch(listarTiposMovimento()),
    excluirTipoMovimento: (id) => dispatch(excluirTipoMovimento(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableTiposMovimento)
