import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGOUT,
} from './authTypes'

const initialState = {
  loading: true,
  isAuthenticated: false,
  login: {},
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: true,
      }
    case FETCH_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        login: action.payload,
        error: '',
      }

    case FETCH_LOGOUT:
      return {
        loading: false,
        isAuthenticated: false,
        login: {},
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
