import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchSalariosHistorico } from '../../../redux/controleGeral/controleGeralSlice'
import { valorMoeda } from '../../../funcoes/data'

export const SalarioHistorico = (props) => {
  const { salarioHistorico, atualizarSalariosHistorico } = props

  useEffect(() => {
    atualizarSalariosHistorico()
  }, [atualizarSalariosHistorico])

  const anos = [
    ...new Set(
      salarioHistorico.dados.map((el) => {
        return el.ANO
      })
    ),
  ]

  return (
    <Box>
      {salarioHistorico.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : salarioHistorico.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {salarioHistorico.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Salários da equipe - Itens marcados com o status CONTA
          </Typography>
          {anos.map((ano, index) => {
            let subJAN = 0.0
            let subFEV = 0.0
            let subMAR = 0.0
            let subABR = 0.0
            let subMAI = 0.0
            let subJUN = 0.0
            let subJUL = 0.0
            let subAGO = 0.0
            let subSET = 0.0
            let subOUT = 0.0
            let subNOV = 0.0
            let subDEZ = 0.0
            return (
              <Box key={index}>
                <Box>
                  <Typography variant="h5" sx={{ my: 2 }}>
                    {ano}
                  </Typography>
                </Box>
                <TableContainer component={Paper} key={ano}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            minWidth: 175,
                            backgroundColor: '#F2F2F2',
                          }}
                        >
                          NOME
                        </TableCell>
                        <TableCell align="right">JAN</TableCell>
                        <TableCell align="right">FEV</TableCell>
                        <TableCell align="right">MAR</TableCell>
                        <TableCell align="right">ABR</TableCell>
                        <TableCell align="right">MAI</TableCell>
                        <TableCell align="right">JUN</TableCell>
                        <TableCell align="right">JUL</TableCell>
                        <TableCell align="right">AGO</TableCell>
                        <TableCell align="right">SET</TableCell>
                        <TableCell align="right">OUT</TableCell>
                        <TableCell align="right">NOV</TableCell>
                        <TableCell align="right">DEZ</TableCell>
                        <TableCell align="right">TOTAL</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {salarioHistorico.dados
                        .filter((el) => el.ANO === ano)
                        .map((sal, index) => {
                          subJAN += parseFloat(sal.JANEIRO)
                          subFEV += parseFloat(sal.FEVEREIRO)
                          subMAR += parseFloat(sal.MARÇO)
                          subABR += parseFloat(sal.ABRIL)
                          subMAI += parseFloat(sal.MAIO)
                          subJUN += parseFloat(sal.JUNHO)
                          subJUL += parseFloat(sal.JULHO)
                          subAGO += parseFloat(sal.AGOSTO)
                          subSET += parseFloat(sal.SETEMBRO)
                          subOUT += parseFloat(sal.OUTUBRO)
                          subNOV += parseFloat(sal.NOVEMBRO)
                          subDEZ += parseFloat(sal.DEZEMBRO)

                          return (
                            <TableRow
                              key={String(ano).concat(String(index))}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  position: 'sticky',
                                  left: 0,
                                  backgroundColor: '#F2F2F2',
                                }}
                              >
                                {sal.TITULO}
                              </TableCell>
                              <TableCell align="right">
                                {sal.JANEIRO !== '0.00'
                                  ? valorMoeda(sal.JANEIRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.FEVEREIRO !== '0.00'
                                  ? valorMoeda(sal.FEVEREIRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.MARÇO !== '0.00'
                                  ? valorMoeda(sal.MARÇO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.ABRIL !== '0.00'
                                  ? valorMoeda(sal.ABRIL)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.MAIO !== '0.00'
                                  ? valorMoeda(sal.MAIO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.JUNHO !== '0.00'
                                  ? valorMoeda(sal.JUNHO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.JULHO !== '0.00'
                                  ? valorMoeda(sal.JULHO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.AGOSTO !== '0.00'
                                  ? valorMoeda(sal.AGOSTO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.SETEMBRO !== '0.00'
                                  ? valorMoeda(sal.SETEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.OUTUBRO !== '0.00'
                                  ? valorMoeda(sal.OUTUBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.NOVEMBRO !== '0.00'
                                  ? valorMoeda(sal.NOVEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {sal.DEZEMBRO !== '0.00'
                                  ? valorMoeda(sal.DEZEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {valorMoeda(
                                  parseFloat(sal.JANEIRO) +
                                    parseFloat(sal.FEVEREIRO) +
                                    parseFloat(sal.MARÇO) +
                                    parseFloat(sal.ABRIL) +
                                    parseFloat(sal.MAIO) +
                                    parseFloat(sal.JUNHO) +
                                    parseFloat(sal.JULHO) +
                                    parseFloat(sal.AGOSTO) +
                                    parseFloat(sal.SETEMBRO) +
                                    parseFloat(sal.OUTUBRO) +
                                    parseFloat(sal.NOVEMBRO) +
                                    parseFloat(sal.DEZEMBRO)
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: '#F2F2F2',
                          }}
                        >
                          TOTAL {ano}
                        </TableCell>
                        <TableCell>{valorMoeda(subJAN)}</TableCell>
                        <TableCell>{valorMoeda(subFEV)}</TableCell>
                        <TableCell>{valorMoeda(subMAR)}</TableCell>
                        <TableCell>{valorMoeda(subABR)}</TableCell>
                        <TableCell>{valorMoeda(subMAI)}</TableCell>
                        <TableCell>{valorMoeda(subJUN)}</TableCell>
                        <TableCell>{valorMoeda(subJUL)}</TableCell>
                        <TableCell>{valorMoeda(subAGO)}</TableCell>
                        <TableCell>{valorMoeda(subSET)}</TableCell>
                        <TableCell>{valorMoeda(subOUT)}</TableCell>
                        <TableCell>{valorMoeda(subNOV)}</TableCell>
                        <TableCell>{valorMoeda(subDEZ)}</TableCell>
                        <TableCell>
                          {valorMoeda(
                            subJAN +
                              subFEV +
                              subMAR +
                              subABR +
                              subMAI +
                              subJUN +
                              subJUL +
                              subAGO +
                              subSET +
                              subOUT +
                              subNOV +
                              subDEZ
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    salarioHistorico: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarSalariosHistorico: () => dispatch(fetchSalariosHistorico()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalarioHistorico)
