import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { valorInteiro, valorMoeda } from '../../../funcoes/data'
import { fetchConta } from '../../../redux/controleGeral/controleGeralSlice'

export const Conta = (props) => {
  const { conta, atualizarConta } = props
  const [valorInvestimento] = useState(0)

  useEffect(() => {
    atualizarConta()
  }, [atualizarConta])

  const qtdeTotal = () => {
    return conta.dados.reduce((a, b) => a + b.QTDE, 0)
  }

  const valorTotal = () => {
    const entradas = parseFloat(conta.dados[0]?.VALOR)
    const debitos = parseFloat(conta.dados[1]?.VALOR)

    console.log(entradas, debitos)

    return entradas - debitos
  }

  return (
    <Box>
      {conta.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : conta.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {conta.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Status atual da conta
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell>ID</TableCell>
                  <TableCell>TITULO</TableCell>
                  <TableCell>DESCRIÇÃO</TableCell>
                  <TableCell align="right">QTDE</TableCell>
                  <TableCell align="right">VALOR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conta.dados &&
                  conta.dados.map((row) => (
                    <TableRow
                      key={row.ID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.ID}</TableCell>
                      <TableCell>{row.TITULO}</TableCell>
                      <TableCell>{row.DESCRICAO}</TableCell>
                      <TableCell align="right">
                        {valorInteiro(row.QTDE)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALOR)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow
                  py={10}
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell colSpan={3}>TOTAL</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorInteiro(qtdeTotal())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(valorTotal())}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>Conta Corrente (INTER)</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(valorTotal() - valorInvestimento)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>Investimento (INTER)</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(valorInvestimento)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    conta: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarConta: () => dispatch(fetchConta()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conta)
