import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: false,
  registros: [],
  registroAtual: {},
  error: '',
}

export const fetchStatusPagamento = createAsyncThunk(
  'tabelas/statusPagamento',
  () => {
    const autorizacao = header()
    return api
      .get('cadastro/statusPagamento', autorizacao)
      .then((res) =>
        res.data.registros.sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))
      )
  }
)

export const salvar = createAsyncThunk(
  'cadastro/statusPagamento/salvar',
  (registro) => {
    const autorizacao = header()
    return api
      .post(`cadastro/statusPagamento/salvar`, registro, autorizacao)
      .then((res) => res.data)
  }
)

export const excluir = createAsyncThunk(
  'cadastro/statusPagamento/excluir',
  (id) => {
    const autorizacao = header()
    return api
      .delete(`/cadastro/statusPagamento/excluir/${id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'statusPagamento',
  initialState,
  reducers: {
    addRegistro: (state, action) => {
      state.registros.push(action.payload)
    },
    deleteRegistro: (state, action) => {
      state.registros = state.registros.filter(
        (registro) => registro.id !== action.payload
      )
    },
    resetRegistros: (state, action) => {
      state.registros = []
    },
  },
  extraReducers: (builder) => {
    //Listar
    builder.addCase(fetchStatusPagamento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchStatusPagamento.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchStatusPagamento.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    //Salvar
    builder.addCase(salvar.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvar.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(salvar.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })
    //Excluir
    builder.addCase(excluir.pending, (state) => {
      state.loading = true
    })
    builder.addCase(excluir.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(excluir.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })
  },
})

export default slice.reducer
export const { addRegistro, deleteRegistro, resetRegistros } = slice.actions
export const selectStatusPagamento = (state) => state.statusPagamento.registros
