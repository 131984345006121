import React, { useEffect, useMemo, useState } from 'react'
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table'
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import '../../../layouts/table.css'
import { FiltroGlobal } from './filtroGlobal'
import { FiltroColuna } from './filtroColuna'
import { CheckboxInput } from './checkbox'
import {
  Add,
  KeyboardArrowDown,
  Download,
  FileDownload,
  PictureAsPdf,
  Grid4x4,
  Edit,
} from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPessoa,
  fetchPessoas,
} from '../../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchGruposPessoas } from '../../../../redux/cadastro/tabelas/gruposPessoasSlice'
import { fetchTiposPessoa } from '../../../../redux/cadastro/tabelas/tiposPessoaSlice'
import { fetchStatus } from '../../../../redux/cadastro/tabelas/statusSlice'
import { format } from 'date-fns'
import DialogDetalhes from '../DialogDetalhes'
import '../../../ninja.css'
import DialogExcluir from '../DialogExcluir'
import ExibirColunas from './exibirColunas'
import { useExportData } from 'react-table-plugins'
import Papa from 'papaparse'
import { utils, writeFile } from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Historico from '../Historico'

export const Table = (props) => {
  const { setModo } = props
  const dispatch = useDispatch()
  const [atualizarTabelas, setAtualizarTabelas] = useState(true)
  const [exibirExcluidos, setExibirExcluidos] = useState(false)
  const [registros, setRegistros] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (atualizarTabelas) {
      dispatch(fetchPessoa())
      dispatch(fetchPessoas())
      dispatch(fetchGruposPessoas())
      dispatch(fetchTiposPessoa())
      dispatch(fetchStatus())
      setAtualizarTabelas(false)
    }
  }, [atualizarTabelas, dispatch])

  const pessoas = useSelector((state) => state.pessoas)

  const COLUMNS = [
    {
      Header: 'Id',
      accessor: 'id',
      Footer: 'Id',
      disableFilters: true,
      sticky: 'left',
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      Footer: 'Nome',
      sticky: 'left',
    },
    {
      Header: 'Grupo',
      accessor: 'grupoPessoas.nome',
      Footer: 'Grupo',
    },
    {
      Header: 'Tipo',
      accessor: 'tiposPessoa.nome',
      Footer: 'Tipo',
    },
    {
      Header: 'Status',
      accessor: 'status.nome',
      Footer: 'Status',
    },
    {
      Header: 'Criado em',
      accessor: 'created_at',
      Footer: 'Criado em',
      disableFilters: true,
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/MM/yyyy')
      },
    },
    {
      Header: 'Ações',
      Footer: 'Ações',
      Cell: (props) => {
        return (
          <Box display="flex" alignContent={'center'} alignItems={'center'}>
            <DialogDetalhes pessoa={props.row.original} />
            <Historico pessoa_id={props.row.original.id} />
            <span onClick={() => handleAlterar(props.row.original)}>
              <Edit color="success" sx={{ cursor: 'pointer' }} />
            </span>
            <DialogExcluir pessoa={props.row.original} />
          </Box>
        )
      },
    },
  ]

  const handleAlterar = (pessoa) => {
    setModo('edicao')

    props.setPessoaAtual(pessoa)
  }

  useEffect(() => {
    let reg = pessoas.registros.slice(0)
    if (!exibirExcluidos) {
      setLoading(true)
      reg = reg.filter((r) => r.deleted_at === null)
    }
    setRegistros(reg)
    setLoading(false)
  }, [exibirExcluidos, pessoas.registros])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  registros.sort((r1, r2) => (r1.id < r2.id ? 1 : -1))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => registros, [registros])

  const defaultColumn = useMemo(() => {
    return {
      Filter: FiltroColuna,
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData
  )

  const { globalFilter, pageIndex, pageSize } = state

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === 'csv') {
      const headerNames = columns.map((col) => col.exportValue)
      const csvString = Papa.unparse({ fields: headerNames, data })
      return new Blob(['\uFEFF' + csvString], {
        type: 'text/csv;charset=utf-8',
      })
    } else if (fileType === 'xlsx') {
      const header = columns.map((c) => c.exportValue)
      const compatibleData = data.map((row) => {
        const obj = {}
        header.forEach((col, index) => {
          obj[col] = row[index]
        })
        return obj
      })
      let wb = utils.book_new()
      let ws1 = utils.json_to_sheet(compatibleData, {
        header,
      })
      utils.book_append_sheet(wb, ws1, 'React Table Data')
      writeFile(wb, `${fileName}.xlsx`)

      return false
    }

    if (fileType === 'pdf') {
      const headerNames = columns.map((column) => column.exportValue)
      const doc = new jsPDF()
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 15 },
        styles: {
          minCelHeight: 15,
          halign: 'left',
          fontSize: 9,
        },
      })
      doc.save(`${fileName}.pdf`)
      return false
    }
    return false
  }
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const renderFiltroGlobal = () => {
    return <FiltroGlobal filter={globalFilter} setFilter={setGlobalFilter} />
  }

  const renderExibirExcluidos = () => {
    return (
      <FormControlLabel
        value={exibirExcluidos}
        control={
          <Switch
            color="secondary"
            checked={exibirExcluidos}
            onChange={() => setExibirExcluidos(!exibirExcluidos)}
          />
        }
        label={
          <span style={{ fontSize: '0.8rem', color: 'black' }}>Excluídos</span>
        }
        labelPlacement="end"
        sx={{ fontSize: '15px' }}
      />
    )
  }

  const renderExportar = () => {
    return (
      <ExibirColunas title="Exportar" endIcon={<Download />}>
        <Box display="flex" flexDirection="column" sx={{ px: 2 }}>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Selecione o tipo de arquivo
              </ListSubheader>
            }
          >
            <ListItemButton
              onClick={() => {
                exportData('xlsx', false)
              }}
            >
              <ListItemIcon>
                <Grid4x4 />
              </ListItemIcon>
              <ListItemText primary="XLSX" secondary="Planilha Excel" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                exportData('pdf', false)
              }}
            >
              <ListItemIcon>
                <PictureAsPdf />
              </ListItemIcon>
              <ListItemText primary="PDF" secondary="Documento em PDF" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                exportData('csv', false)
              }}
            >
              <ListItemIcon>
                <FileDownload />
              </ListItemIcon>
              <ListItemText
                primary="CSV"
                secondary="Campos separados por vírgula"
              />
            </ListItemButton>
          </List>
        </Box>
      </ExibirColunas>
    )
  }

  const renderExibirColunas = () => {
    return (
      <ExibirColunas title="Exibir" endIcon={<KeyboardArrowDown />}>
        <FormGroup sx={{ display: 'flex' }}>
          <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
            <FormControlLabel
              control={<CheckboxInput {...getToggleHideAllColumnsProps()} />}
              label="Mostrar Todas"
            />
            {allColumns.map((column) => (
              <FormControlLabel
                key={column.id}
                control={
                  <Checkbox
                    size="small"
                    {...column.getToggleHiddenProps()}
                    color="primary"
                  />
                }
                label={column.Header}
              />
            ))}
          </Box>
        </FormGroup>
      </ExibirColunas>
    )
  }

  const renderAdicionar = () => {
    return (
      <>
        <Hidden mdDown>
          <Button
            variant="contained"
            color="third"
            startIcon={<Add />}
            onClick={() => props.setModo('adicao')}
          >
            Adicionar
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="primary"
            component="span"
            sx={{ bgcolor: 'third' }}
            onClick={() => props.setModo('adicao')}
          >
            <Add />
          </IconButton>
        </Hidden>
      </>
    )
  }

  return (
    <Box>
      {loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : pessoas.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {pessoas.error})
        </Typography>
      ) : (
        <Box>
          <AppBar position="static" sx={{ mt: 9, mb: 2, bgcolor: '#fff' }}>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Grid
                  container
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {renderFiltroGlobal()}
                  {renderAdicionar()}
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="primary"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                  >
                    <MenuItem>{renderExibirExcluidos()}</MenuItem>
                    <MenuItem>{renderExportar()}</MenuItem>
                    <MenuItem sx={{ width: '100%' }}>
                      {renderExibirColunas()}
                    </MenuItem>
                  </Menu>
                </Grid>

                <Grid
                  container
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {renderFiltroGlobal()}
                  {renderExibirExcluidos()}
                  {renderExportar()}
                  {renderExibirColunas()}
                  {renderAdicionar()}
                </Grid>
              </Toolbar>
            </Container>
          </AppBar>

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Box display="flex" justifyContent="space-between">
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDropDownIcon
                                sx={{ fontSize: '13px', color: 'yellow' }}
                              />
                            ) : (
                              <ArrowDropUpIcon
                                sx={{ fontSize: '13px', color: 'yellow' }}
                              />
                            )
                          ) : null}
                        </span>
                      </Box>
                    </th>
                  ))}
                </tr>
              ))}
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                const isDeleted = Boolean(row.original.deleted_at)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          style={
                            isDeleted ? { color: 'red' } : { color: '#000' }
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '50px',
            }}
          >
            <Typography>
              Página {pageIndex + 1} de {pageOptions.length}
            </Typography>

            <Box>
              <Button
                size="small"
                variant="outlined"
                onClick={() => gotoPage(0)}
                color="primary"
                disabled={!canPreviousPage}
              >
                {'<<'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => previousPage()}
                color="primary"
                disabled={!canPreviousPage}
              >
                Anterior
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => nextPage()}
                color="primary"
                disabled={!canNextPage}
              >
                Próxima
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => gotoPage(pageCount - 1)}
                color="primary"
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </Box>

            <TextField
              size="small"
              type="number"
              label="Ir para..."
              sx={{
                width: '100px',
              }}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
            ></TextField>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="select-small">Itens por página</InputLabel>
              <Select
                labelId="select-small"
                id="select-small"
                value={pageSize}
                label="Itens por página"
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 25, 50, 100, 500].map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    {pageSize} linhas
                  </MenuItem>
                ))}
                <MenuItem value={pageOptions.length * pageSize}>Tudo</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  )
}

//https://www.youtube.com/watch?v=2U9eVClAqh0&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz&index=9
