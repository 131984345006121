import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { connect } from 'react-redux'
import {
  excluirConta,
  listarContas,
} from '../../../../redux/sistemas/contas/contasSlice'
import { useEffect } from 'react'
import { useState } from 'react'
import { Box } from '@mui/material'
import Loading from '../../../layouts/Loading'
import DialogErro from '../../../layouts/DialogErro'
import { dataHoraBRExibir } from '../../../../funcoes/data'
import FormConta from './formConta'
import DialogExclusao from '../../../layouts/DialogExclusao'
import { listarBancos } from '../../../../redux/sistemas/contas/bancosSlice'
import { listarAgencias } from '../../../../redux/sistemas/contas/agenciasSlice'
import { listarTiposConta } from '../../../../redux/sistemas/contas/tiposContaSlice'

export const TableConta = (props) => {
  const {
    contas,
    bancos,
    agencias,
    tiposConta,
    error,
    listarContas,
    excluirConta,
    listarBancos,
    listarAgencias,
    listarTiposConta,
  } = props
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [atualizarLista, setAtualizarLista] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [idExclusao, setIdExclusao] = useState('')

  useEffect(() => {
    if (atualizarLista) {
      listarContas()
      listarBancos()
      listarAgencias()
      listarTiposConta()
      setAtualizarLista(false)
    }
    if (error) setOpenDialogErro(true)
  }, [
    atualizarLista,
    error,
    listarContas,
    listarBancos,
    listarAgencias,
    listarTiposConta,
  ])

  const rows = contas.registros

  const handleExcluir = (idExclusao) => {
    excluirConta(idExclusao)
  }

  return (
    <Box>
      {contas.loading ? (
        <Loading />
      ) : contas.error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={error}
          mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : (
        <>
          <FormConta
            registro={null}
            acao="add"
            bancos={bancos.registros}
            agencias={agencias.registros}
            tiposConta={tiposConta.registros}
          />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'Silver' }}>
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>COD BANCO</TableCell>
                  <TableCell>BANCO</TableCell>
                  <TableCell>COD AGÊNCIA</TableCell>
                  <TableCell>AGENCIA</TableCell>
                  <TableCell>TIPO</TableCell>
                  <TableCell>NÚMERO</TableCell>
                  <TableCell align="right">SALDO</TableCell>
                  <TableCell align="center">INCLUSÃO</TableCell>
                  <TableCell align="center">ALTERAÇÃO</TableCell>
                  <TableCell align="center">AÇÕES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.banco.codigo}</TableCell>
                    <TableCell>{row.banco.nome}</TableCell>
                    <TableCell>{row.agencia?.codigo}</TableCell>
                    <TableCell>{row.agencia?.nome}</TableCell>
                    <TableCell>{row.tipo_conta.nome}</TableCell>
                    <TableCell>{row.numero}</TableCell>
                    <TableCell align="right">
                      {Intl.NumberFormat('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(row.saldo)}
                    </TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {dataHoraBRExibir(row.updated_at)}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <FormConta
                          registro={row}
                          acao="alt"
                          bancos={bancos.registros}
                          agencias={agencias.registros}
                          tiposConta={tiposConta.registros}
                        />
                        <DialogExclusao
                          title="a Conta "
                          referencia={`Conta: ${row.numero} / Banco: ${row.banco.nome} / Agencia: ${row.agencia.nome} `}
                          idExclusao={row.id}
                          setIdExclusao={setIdExclusao}
                          handleExcluir={handleExcluir}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    agencias: {
      loading: state.agencias.loading,
      registros: state.agencias.registros,
      error: state.agencias.error,
    },
    bancos: {
      loading: state.bancos.loading,
      registros: state.bancos.registros,
      error: state.bancos.error,
    },
    contas: {
      loading: state.contas.loading,
      registros: state.contas.registros,
      error: state.contas.error,
    },
    tiposConta: {
      loading: state.tiposConta.loading,
      registros: state.tiposConta.registros,
      error: state.tiposConta.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listarContas: () => dispatch(listarContas()),
    excluirConta: (id) => dispatch(excluirConta(id)),
    listarBancos: () => dispatch(listarBancos()),
    listarAgencias: () => dispatch(listarAgencias()),
    listarTiposConta: () => dispatch(listarTiposConta()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableConta)
