import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  registros: [],
  registroAtual: {},
  error: {},
}

export const listarContas = createAsyncThunk('contas/listar/contas', () => {
  const autorizacao = header()
  return api.get('/contas', autorizacao).then((res) => res.data)
})

export const obterConta = createAsyncThunk('contas/obterConta', (id) => {
  const autorizacao = header()
  console.log('obterConta......')
  return api
    .get(`/contas/obterconta/${id}`, autorizacao)
    .then((res) => res.data)
})

export const salvarConta = createAsyncThunk(
  'contas/salvar/conta',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/contas/salvar', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const excluirConta = createAsyncThunk('contas/excluir/conta', (id) => {
  const autorizacao = header()
  return api.get(`/contas/excluir/${id}`, autorizacao).then((res) => res.data)
})

export const atualizarSaldo = createAsyncThunk(
  'contas/atualizarSaldo',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/contas/atualizarsaldo', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'contas',
  initialState: initialState,
  extraReducers: (builder) => {
    //Listar Conta
    builder.addCase(listarContas.pending, (state) => {
      state.loading = true
    })
    builder.addCase(listarContas.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(listarContas.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Obter uma conta
    builder.addCase(obterConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(obterConta.fulfilled, (state, action) => {
      console.log('slice success: ', action.payload)
      state.loading = false
      state.registroAtual = action.payload
      state.error = []
    })
    builder.addCase(obterConta.rejected, (state, action) => {
      console.log('slice error: ', action.payload)
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })

    // Salvar Conta
    builder.addCase(salvarConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarConta.fulfilled, (state, action) => {
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )
      if (index === -1) {
        state.registros.push(action.payload.registro)
      } else {
        state.registros[index] = {
          ...state.registros[index],
          ...action.payload.registro,
        }
      }

      state.loading = false
    })

    // Excluir Conta
    builder.addCase(excluirConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(excluirConta.fulfilled, (state, action) => {
      const id = parseInt(action.payload)
      state.registros = state.registros.filter((registro) => registro.id !== id)
      state.loading = false
    })

    // Atualizar Saldo
    builder.addCase(atualizarSaldo.pending, (state) => {
      state.loading = true
    })

    builder.addCase(atualizarSaldo.fulfilled, (state, action) => {
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )
      state.registros[index] = {
        ...state.registros[index],
        ...action.payload.registro,
      }
    })
  },
})

export default slice.reducer
