import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'
const autorizacao = header()

const initialState = {
  loading: true,
  registros: [],
  error: '',
}

export const fetchRedesSociais = createAsyncThunk(
  'redesSociais/fetchRedesSociais',
  (pessoa_id) => {
    return api
      .get(`pessoas/redesSociais/${pessoa_id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const saveRedesSociais = createAsyncThunk(
  'redesSociais/saveRedesSociais',
  (registro) => {
    return api
      .post(`pessoas/redesSociais/salvar`, registro, autorizacao)
      .then((res) => res.data.registro)
  }
)

export const slice = createSlice({
  name: 'redesSociais',
  initialState,
  reducers: {
    changeRedesSociais(state, { payload }) {
      return { ...state, loading: false, registros: payload }
    },
    addRedesSociais: (state, action) => {
      state.registros.push(action.payload)
    },
    deleteRedesSociais: (state, action) => {
      state.registros = state.registros.filter(
        (el) => el.id !== action.payload.id
      )
    },
    updateRedeSocial: (state, action) => {
      // eslint-disable-next-line array-callback-return
      state.registros.map((r) => {
        if (r.id === action.payload.id) {
          return (r = action.payload)
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedesSociais.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchRedesSociais.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchRedesSociais.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    builder.addCase(saveRedesSociais.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveRedesSociais.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(saveRedesSociais.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
  },
})

export default slice.reducer
export const {
  changeRedesSociais,
  addRedesSociais,
  deleteRedesSociais,
  updateRedesSociais,
} = slice.actions
export const selectRedesSociais = (state) => state.redesSociais.registros

//https://www.youtube.com/watch?v=bml92jhF4t8
