import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'
const autorizacao = header()

const initialState = {
  loading: true,
  registro: {},
  error: '',
}

export const fetchPessoaFisica = createAsyncThunk(
  'pessoaFisica/fetchPessoaFisica',
  (pessoa_id) => {
    return api
      .get(`pessoas/pessoaFisica/${pessoa_id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const savePessoaFisica = createAsyncThunk(
  'pessoaFisica/savePessoaFisica',
  (registro) => {
    return api
      .post('pessoas/pessoaFisica/salvar', registro, autorizacao)
      .then((res) => res.data.registro)
  }
)

export const slice = createSlice({
  name: 'pessoaFisica',
  initialState,
  reducers: {
    changePessoaFisica(state, { payload }) {
      return { ...state, loading: false, registro: payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPessoaFisica.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPessoaFisica.fulfilled, (state, action) => {
      state.loading = false
      state.registro = action.payload
      state.error = ''
    })
    builder.addCase(fetchPessoaFisica.rejected, (state, action) => {
      state.loading = false
      state.registro = {}
      state.error = action.error.message
    })
    builder.addCase(savePessoaFisica.pending, (state) => {
      state.loading = true
    })
    builder.addCase(savePessoaFisica.fulfilled, (state, action) => {
      state.loading = false
      state.registro = action.payload
      state.error = ''
    })
    builder.addCase(savePessoaFisica.rejected, (state, action) => {
      state.loading = false
      state.registro = {}
      state.error = action.error.message
    })
  },
})

export const { changePessoaFisica } = slice.actions
export const selectPessoaFisica = (state) => state.pessoaFisica.registro
export default slice.reducer
