export const atividades = [
  {
    id: '1',
    titulo: 'Ideia Ninja',
    subTitulo: 'Ideia Ninja > Geral',
    descricao: 'Tarefas Gerais do ideia Ninja',
    fixado: true,
  },
  {
    id: '2',
    titulo: 'Imobiliário Ninja',
    subTitulo: '',
    descricao: 'Tarefas Imobiliários Ninja',
    fixado: true,
  },
  {
    id: '3',
    titulo: 'Marketing Mensal',
    subTitulo: 'Ideia Ninja - Marketing Mensal > Geral',
    descricao: 'Tarefas Imobiliários Ninja',
    fixado: true,
  },
  {
    id: '4',
    titulo: 'Ideia Ninja - Marketing Mensal',
    subTitulo: '',
    descricao: '',
    fixado: false,
  },
  {
    id: '5',
    titulo: 'Ideia Ninja - Marketing Mensal',
    subTitulo: '',
    descricao: '',
    fixado: true,
  },
]
