import { Alert, Snackbar } from '@mui/material'

const Aviso = (props) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    props.setOpenAviso(false)
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.openSnakbar}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={props.tipo}
        sx={{ width: '100%' }}
      >
        {props.mensagem}
      </Alert>
    </Snackbar>
  )
}

export default Aviso
