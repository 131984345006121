import { DeleteForever } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import { checarData, dataBRExibir, valorMoeda } from '../../../funcoes/data'
import {
  fetchFechamentoPagamento,
  resetPagamentos,
} from '../../../redux/cadastro/pagamentoSlice'
import {
  deleteRecebimento,
  fetchFechamentoRecebimento,
  resetRecebimentos,
} from '../../../redux/cadastro/recebimentoSlice'
import Layout from '../../layouts'
import DialogErro from '../../layouts/DialogErro'
import Loading from '../../layouts/Loading'
import AlterarRecebimento from './AlterarRecebimento'
import AlterarPagamento from './AlterarPagamento'

const sxTableHeader = {
  color: 'black',
  fontSize: '0.9rem',
  backgroundColor: '#DCDCDC',
  fontWeight: 'bold',
}

const sxTableCell = {
  fontSize: '0.8rem',
}

export const FechamentoPeriodo = (props) => {
  const {
    carregarFechamentoRecebimento,
    recebimentos,
    carregarFechamentoPagamento,
    pagamentos,
    excluirRecebimento,
    limparRecebimentos,
    limparPagamentos,
  } = props

  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [dtInicio, setDtInicio] = useState('')
  const [dtFim, setDtFim] = useState('')
  const [erroData, setErroData] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [atualizado, setAtualizado] = useState(false)

  useEffect(() => {
    if (recebimentos.error || pagamentos.error) setOpenDialogErro(true)
  }, [pagamentos, recebimentos])

  useEffect(() => {
    limparRecebimentos()
    limparPagamentos()
    validarPeriodo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtInicio, dtFim])

  const criarRelatorio = () => {
    const intervalo = { dtInicio, dtFim }
    if (validarPeriodo()) {
      carregarFechamentoRecebimento(intervalo)
      carregarFechamentoPagamento(intervalo)
    } else {
      setOpenDialogErro(true)
      exibirErro()
    }
  }

  const validarPeriodo = () => {
    setErroData('')
    let validado = true
    if (dtInicio === '') {
      setErroData('Data inicial não informada ou inválida.')
      validado = false
    } else if (dtFim === '') {
      setErroData('Data final não informada ou inválida.')
      validado = false
    } else if (dtInicio > dtFim) {
      setErroData('A data final é anterior a data de inicio')
      validado = false
    }
    return validado
  }

  const exibirErro = () => {
    return (
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={erroData}
        mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    )
  }

  const handleExcluirRecebimento = (registro) => {
    excluirRecebimento(registro)
  }

  const calcularTotalGeralRecebimentos = (parcelas) => {
    return parcelas
      .map(({ valor }) => valor)
      .reduce((sum, i) => sum + parseFloat(i), 0.0)
  }

  const calcularTotalMovimentoRecebimentos = (parcelas) => {
    const parcelasFilter = parcelas.filter((p) => p.status_recebimento_id !== 1)
    return parcelasFilter
      .map(({ valor }) => valor)
      .reduce((sum, i) => sum + parseFloat(i), 0.0)
  }

  const calcularTotalGeralPagamentos = (parcelas) => {
    return parcelas
      .map(({ valor }) => valor)
      .reduce((sum, i) => sum + parseFloat(i), 0.0)
  }

  const calcularTotalMovimentoPagamentos = (parcelas) => {
    const parcelasFilter = parcelas.filter((p) => p.status_pagamento_id !== 1)
    return parcelasFilter
      .map(({ valor }) => valor)
      .reduce((sum, i) => sum + parseFloat(i), 0.0)
  }

  return (
    <Layout titulo="Fechamento" subtitulo="Fechamento do período informado">
      <Grid container>
        <Grid item xs={12} md={12} mb={0}>
          <Typography sx={{ mt: 4 }} variant="h6" component="div">
            Fechamento dos valores informados por período
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body2" component="div" mb={1}>
            Relatório de conferência e fechamento dos valores informados
            referentes a pagamentos (entradas) e recebimentos (saída) do período
            informado.
          </Typography>
          <Divider />
        </Grid>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 2 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          alignItems="center"
          justifyContent={'center'}
          p={2}
        >
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body" component="div">
              Informe o Período:
            </Typography>
          </Grid>

          <Grid item>
            <TextField
              size="small"
              type="date"
              label={'de'}
              InputLabelProps={{ shrink: true }}
              value={dtInicio}
              onChange={(e) => {
                setDtInicio(e.target.value)
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              type="date"
              label="até"
              InputLabelProps={{ shrink: true }}
              value={dtFim}
              onChange={(e) => setDtFim(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => criarRelatorio()}>
              Listar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {recebimentos.loading && pagamentos.loading ? (
        <Loading />
      ) : recebimentos.error || pagamentos.error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={recebimentos.error || pagamentos.error}
          mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : !!erroData ? (
        exibirErro()
      ) : null}
      {recebimentos.dados.length > 0 ? (
        <TableContainer sx={{ mb: 10 }}>
          <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
            Recebimentos (Entradas)
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={sxTableHeader}>OR</TableCell>
                <TableCell sx={sxTableHeader} align="right">
                  ID
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Cliente
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Projeto
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Serviço
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Nº
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Venc.
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Pag.
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Status
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Origem
                </TableCell>
                <TableCell sx={sxTableHeader} align="right">
                  Valor
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recebimentos.dados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={sxTableCell} align="right">
                    {index + 1}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{row.projeto.cliente.nome}</TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.projeto.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.servico.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.numero_parcela}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {checarData(row.data) ? dataBRExibir(row.data) : null}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {checarData(row.data_pagamento)
                      ? dataBRExibir(row.data_pagamento)
                      : null}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.statusRecebimento.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.origemRecebimento?.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    {valorMoeda(row.valor)}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    <Box sx={{ display: 'flex' }}>
                      <AlterarRecebimento
                        registro={row}
                        setAtualizado={setAtualizado}
                      />
                      <Tooltip
                        title={
                          <Box sx={{ fontSize: '13px', p: 0.5 }}>
                            Excluir Registro
                          </Box>
                        }
                        placement="left"
                      >
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                          onClick={() => {
                            handleExcluirRecebimento(row)
                          }}
                        >
                          <DeleteForever sx={{ color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={7}></TableCell>
                <TableCell colSpan={3} align="right">
                  <strong>TOTAL GERAL</strong>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <strong>
                    {valorMoeda(
                      calcularTotalGeralRecebimentos(recebimentos.dados)
                    )}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}></TableCell>
                <TableCell colSpan={3} align="right">
                  <strong>TOTAL MOVIMENTO</strong>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <strong>
                    {valorMoeda(
                      calcularTotalMovimentoRecebimentos(recebimentos.dados)
                    )}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {pagamentos.dados.length > 0 ? (
        <TableContainer sx={{ mb: 10 }}>
          <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
            Pagamentos (Saídas)
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={sxTableHeader}>OR</TableCell>
                <TableCell sx={sxTableHeader} align="right">
                  ID
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Fornecedor
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Cliente
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Projeto
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Serviço
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Nº
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Venc.
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Pag.
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Status
                </TableCell>
                <TableCell sx={sxTableHeader} align="left">
                  Origem
                </TableCell>
                <TableCell sx={sxTableHeader} align="right">
                  Valor
                </TableCell>
                <TableCell sx={sxTableHeader} align="right">
                  Ação
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagamentos.dados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={sxTableCell} align="right">
                    {index + 1}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">{row.fornecedor?.nome}</TableCell>
                  <TableCell align="left">{row.projeto.cliente.nome}</TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.projeto.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.servico.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {row.numero_parcela}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {checarData(row.data_vencimento)
                      ? dataBRExibir(row.data_vencimento)
                      : null}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="left">
                    {checarData(row.data_pagamento)
                      ? dataBRExibir(row.data_pagamento)
                      : null}
                  </TableCell>
                  <TableCell sx={sxTableCell} width={'6%'} align="left">
                    {row.statusPagamento.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} width={'6%'} align="left">
                    {row.origemPagamento?.nome}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    {valorMoeda(row.valor)}
                  </TableCell>
                  <TableCell sx={sxTableCell} align="right">
                    <Box>
                      <AlterarPagamento registro={row} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={8}></TableCell>
                <TableCell colSpan={3} align="right">
                  <strong>TOTAL GERAL</strong>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <strong>
                    {valorMoeda(calcularTotalGeralPagamentos(pagamentos.dados))}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={8}></TableCell>
                <TableCell colSpan={3} align="right" sx={{ py: 10 }}>
                  <strong>TOTAL MOVIMENTO</strong>
                </TableCell>
                <TableCell colSpan={2} align="right" py={10}>
                  <strong>
                    {valorMoeda(
                      calcularTotalMovimentoPagamentos(pagamentos.dados)
                    )}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    recebimentos: {
      loading: state.recebimentos.loading,
      dados: state.recebimentos.registros,
      error: state.recebimentos.error,
    },
    pagamentos: {
      loading: state.pagamentos.loading,
      dados: state.pagamentos.registros,
      error: state.pagamentos.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    carregarFechamentoRecebimento: (intervalo) =>
      dispatch(fetchFechamentoRecebimento(intervalo)),
    carregarFechamentoPagamento: (intervalo) =>
      dispatch(fetchFechamentoPagamento(intervalo)),
    excluirRecebimento: (registro) => dispatch(deleteRecebimento(registro)),
    limparRecebimentos: () => dispatch(resetRecebimentos()),
    limparPagamentos: () => dispatch(resetPagamentos()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FechamentoPeriodo)
