import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ClearIcon from '@mui/icons-material/Clear'
import SaveIcon from '@mui/icons-material/Save'
import {
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import {
  fetchUsuarios,
  saveUsuario,
  updateRegistro,
} from '../../../redux/cadastro/usuarioSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'

const validationSchema = yup.object({
  name: yup
    .string('Informe o nome do usuário')
    .min(3, 'O nome precisa ter no mínimo 3 caracteres')
    .max(255, 'o nome pode ter no máximo 255 caracteres')
    .required('Um nome precisa ser informado'),
  email: yup
    .string('Informe o e-mail')
    .email('Informe um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: yup
    .string('Informe a senha')
    .min(5, 'A senha precisa ter no mínimo 5 caracteres')
    .max(15, 'A senha precisa ter no máximo 15 carcateres')
    .required('A senha é obrigatória'),
  password_confirmado: yup
    .string('Informe a senha para confirmação')
    .min(5, 'A senha precisa ter no mínimo 5 caracteres')
    .max(15, 'A senha precisa ter no máximo 15 carcateres')
    .required('A senha para confirmação é obrigatória')
    .oneOf([yup.ref('password')], 'As senhas não conferem'),
  status: yup
    .number('Informe o status do usuário')
    .required('Um status é obrigatório'),
  tipo: yup
    .number('Informe o tipo de usuário')
    .required('Um tipo de usuário precisa ser definido'),
})

const FormUsuario = (props) => {
  const {
    modo,
    setModo,
    salvarUsuario,
    usuarios,
    atualizarUsuarios,
    //atualizarRegistro,
  } = props

  const registro = modo.registro

  const [openAviso, setOpenAviso] = useState(false)
  const [dialogError, setDialogError] = useState('')
  const [openDialogErro, setOpenDialogErro] = useState(false)

  const formik = useFormik({
    initialValues: {
      id: registro?.id || '',
      name: registro?.name || '',
      email: registro?.email || '',
      password_confirmado: '',
      password: '',
      status: registro?.status || '',
      tipo: registro?.tipo || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSalvarUsuario(values)
    },
  })

  const handleSalvarUsuario = async (values) => {
    // Verificar se o e-mail já existe
    if (verificarEmail(values.email)) {
      formik.errors.email = 'Este e-mail já existe no sistema'
      formik.touched.email = true
      formik.validateForm = false
      setDialogError(`Novo Usuário: Este e-mail já existe no sistema`)
      setOpenDialogErro(true)
    } else {
      const reg = {
        id: values.id,
        name: values.name,
        email: values.email,
        password: values.password,
        status: values.status,
        tipo: values.tipo,
      }
      //console.log('Registro enviado: ', reg)
      const res = await salvarUsuario(reg)
      //atualizarRegistro(res.payload)
      //console.log('Resposta ao salvar: ', res)
      if (!!res?.error) {
        setDialogError(
          `Novo Usuário: ${res.error?.code} - ${res.error?.message}`
        )
        setOpenDialogErro(true)
      } else {
        setOpenAviso(true)
        formik.resetForm()
        if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
      }
      atualizarUsuarios()
    }
  }

  const verificarEmail = (email) => {
    const buscarEmail = usuarios.dados?.filter((el) => el.email === email)
    let existeEmail = false
    if (buscarEmail.length > 0 && modo.status === 'add') {
      existeEmail = true
    }
    return existeEmail
  }

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h5" gutterBottom mb={4} align="center">
        {modo.status === 'add' ? 'Novo Usuário' : 'Editar Usuário'}
      </Typography>
      <Grid
        container
        component="form"
        onSubmit={formik.handleSubmit}
        rowSpacing={{ xs: 2, sm: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
        // sx = {{'& .MuiTextField-root': {m:1, width:'40%'}}}
      >
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            size="small"
            fullWidth
            name="name"
            label="Nome"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            size="small"
            fullWidth
            name="email"
            label="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            select
            name="tipo"
            label="Tipo de Usuário"
            size="small"
            value={formik.values.tipo}
            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
            helperText={formik.touched.tipo && formik.errors.tipo}
            onChange={formik.handleChange}
            fullWidth
          >
            <MenuItem value={1}>Administrador</MenuItem>
            <MenuItem value={2}>Gerente</MenuItem>
            <MenuItem value={3}>Operador</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            select
            name="status"
            label="Status"
            size="small"
            value={formik.values.status}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
            fullWidth
          >
            <MenuItem value={1}>Ativo</MenuItem>
            <MenuItem value={2}>Inativo</MenuItem>
            <MenuItem value={3}>Excluído</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            size="small"
            fullWidth
            name="password"
            label="Senha"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            size="small"
            fullWidth
            name="password_confirmado"
            label="Confirmar Senha"
            type="password"
            value={formik.values.password_confirmado}
            onChange={formik.handleChange}
            error={
              formik.touched.password_confirmado &&
              Boolean(formik.errors.password_confirmado)
            }
            helperText={
              formik.touched.password_confirmado &&
              formik.errors.password_confirmado
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Grid sx={{ display: 'flex', gap: 2, mt: 3 }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
            >
              {modo.status === 'add' ? 'Adicionar' : 'Alterar'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => formik.handleReset(formik.resetForm)}
              startIcon={<ClearIcon />}
            >
              Limpar
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setModo({ status: 'table', registro: {} })}
              startIcon={<ArrowBackIcon />}
            >
              VOLTAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Aviso
        openSnakbar={openAviso}
        setOpenAviso={setOpenAviso}
        mensagem={`Novo usuário ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
        tipo="success"
      />
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={dialogError}
        mensagem="Não foi possível incluir o novo usuário. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    usuarios: {
      loading: state.usuarios.loading,
      dados: state.usuarios.registros,
      error: state.usuarios.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    salvarUsuario: (registro) => dispatch(saveUsuario(registro)),
    atualizarUsuarios: () => dispatch(fetchUsuarios),
    atualizarRegistro: (registro) => dispatch(updateRegistro(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormUsuario)
