import { Box, IconButton, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../layouts'
import SaveIcon from '@mui/icons-material/Save'
import {
  listarPreferencias,
  salvarPreferencia,
} from '../../redux/sistemas/sain/preferenciasSlice'
import { useEffect } from 'react'
import Loading from '../layouts/Loading'

export const Preferencias = (props) => {
  const { preferencias, listarPreferencias, salvarPreferencia } = props

  const user = JSON.parse(localStorage.getItem('stateAutenticacao'))
  const user_id = user.login.user.id
  const [qtdeMesesExtrato, setQtdeMesesExtrato] = useState(1)

  useEffect(() => {
    listarPreferencias(user_id)
  }, [listarPreferencias, user_id])

  const handleSalvar = (id, preferencia_id, valor) => {
    const dados = { id, preferencia_id, user_id, valor }
    salvarPreferencia(dados)
  }

  useEffect(() => {
    if (preferencias) {
      setQtdeMesesExtrato(
        preferencias.registros.find((el) => el.preferencia_id === 1)?.valor
      )
    } else {
      setQtdeMesesExtrato(1)
    }
  }, [preferencias])

  return (
    <Layout titulo="Preferências" subtitulo="Personalização do sistema">
      {preferencias.loading ? (
        <Loading />
      ) : (
        <>
          <Box mt={12} mb={2}>
            Gestão de Contas
          </Box>
          <TextField
            size="small"
            type="number"
            label="Quantidade de meses do extrato"
            value={qtdeMesesExtrato || 1}
            onChange={(e) => setQtdeMesesExtrato(e.target.value)}
            InputProps={{ inputProps: { min: 1 } }}
          />
          <IconButton
            onClick={() => handleSalvar(1, 1, qtdeMesesExtrato)} // diasExtrato
          >
            <SaveIcon />
          </IconButton>
        </>
      )}
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    preferencias: {
      loading: state.preferencias.loading,
      registros: state.preferencias.registros,
      error: state.preferencias.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listarPreferencias: (user_id) => dispatch(listarPreferencias(user_id)),
    salvarPreferencia: (registro) => dispatch(salvarPreferencia(registro)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferencias)
