import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../middleware/jwtInterceptor'
import api from '../../services/api'

const initialState = {
  loading: false,
  registros: [],
  registrosSalvos: [],
  registroAtual: {},
  error: '',
  refresh: false,
}

export const fetchRecebimentos = createAsyncThunk(
  'cadastro/recebimentos',
  () => {
    const autorizacao = header()
    return api.get('recebimentos/lista', autorizacao).then((res) => res.data)
  }
)

export const fetchAReceber = createAsyncThunk(
  'cadastro/receber',
  (intervalo) => {
    const autorizacao = header()
    return api
      .post('recebimentos/lista/receber', intervalo, autorizacao)
      .then((res) => res.data)
  }
)

export const fetchAReceberTodos = createAsyncThunk(
  'cadastro/receber/todos',
  () => {
    const autorizacao = header()
    return api
      .get('recebimentos/lista/receber/todos', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchAVincularTodos = createAsyncThunk(
  'cadastro/aVincular/todos',
  () => {
    const autorizacao = header()
    return api
      .get('recebimentos/lista/aVincular/todos', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchFechamentoRecebimento = createAsyncThunk(
  'cadastro/receber/fechamento',
  (intervalo) => {
    const autorizacao = header()
    return api
      .post(
        'recebimentos/lista/fechamento/recebimentos',
        intervalo,
        autorizacao
      )
      .then((res) => res.data)
  }
)

export const fetchRecebimentosTodos = createAsyncThunk(
  'cadastro/recebimentos/todos',
  () => {
    const autorizacao = header()
    return api
      .get('recebimentos/lista/todos', autorizacao)
      .then((res) => res.data)
  }
)

export const saveRecebimento = createAsyncThunk(
  'cadastro/recebimentos/saveRecebimento',
  (registro) => {
    const autorizacao = header()
    return api
      .post(`/recebimentos/salvar`, registro, autorizacao)
      .then((res) => res.data)
  }
)

export const saveAllRecebimento = createAsyncThunk(
  'cadastro/recebimentos/saveAllRecebimento',
  (registros) => {
    const autorizacao = header()
    return api
      .post(`/recebimentos/salvarAll`, registros, autorizacao)
      .then((res) => res.data)
  }
)

export const deleteRecebimento = createAsyncThunk(
  'cadastro/recebimentos/delete',
  (registro) => {
    const autorizacao = header()
    return api
      .delete(`/recebimentos/excluir/${registro.id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const baixarRecebidos = createAsyncThunk(
  'cadastro/recebimentos/baixarRecebidos',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/recebimentos/confirmar', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const baixarVinculados = createAsyncThunk(
  'cadastro/recebimentos/baixarVinculados',
  (registro) => {
    const autorizacao = header()
    return api
      .post('recebimentos/baixarVinculado', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'recebimentos',
  initialState,
  reducers: {
    resetRecebimentos: (state, action) => {
      state.registros = []
    },
    deleteRegistros: (state, action) => {
      const lista = action.payload.itens
      lista.map((el) => {
        return (state.registros = state.registros.filter(
          (registro) => registro.id !== el
        ))
      })
    },
  },
  extraReducers: (builder) => {
    // Listar Recebimentos
    builder.addCase(fetchRecebimentos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchRecebimentos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchRecebimentos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    // A Receber
    builder.addCase(fetchAReceber.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAReceber.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
      state.refresh = false
    })
    builder.addCase(fetchAReceber.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    // A Receber Todos
    builder.addCase(fetchAReceberTodos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAReceberTodos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
      state.refresh = false
    })
    builder.addCase(fetchAReceberTodos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    // A Vincular Todos
    builder.addCase(fetchAVincularTodos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAVincularTodos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
      state.refresh = false
    })
    builder.addCase(fetchAVincularTodos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    // Listar Recebimentos Todos
    builder.addCase(fetchRecebimentosTodos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchRecebimentosTodos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchRecebimentosTodos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    // Salvar Recebimento
    builder.addCase(saveRecebimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveRecebimento.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
      state.refresh = true
    })
    builder.addCase(saveRecebimento.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })

    // Baixar Recebidos
    builder.addCase(baixarRecebidos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(baixarRecebidos.fulfilled, (state, action) => {
      state.loading = false
      state.registrosSalvos = action.payload
      state.error = ''
    })
    builder.addCase(baixarRecebidos.rejected, (state, action) => {
      state.loading = false
      state.registrosSalvos = {}
      state.error = action.error.message
    })

    // Salvar vários recebimentos
    builder.addCase(saveAllRecebimento.pending, (state) => {
      state.loading = true
    })

    builder.addCase(saveAllRecebimento.fulfilled, (state, action) => {
      state.loading = false
      state.registrosSalvos = action.payload
      state.error = ''
    })
    builder.addCase(saveAllRecebimento.rejected, (state, action) => {
      state.loading = false
      state.registrosSalvos = []
      state.error = action.error.message
    })

    // Excluir Recebimento
    builder.addCase(deleteRecebimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteRecebimento.fulfilled, (state, action) => {
      state.loading = false
      state.registroAtual = action.payload
      state.error = ''
    })
    builder.addCase(deleteRecebimento.rejected, (state, action) => {
      state.loading = false
      state.registroAtual = {}
      state.error = action.error.message
    })

    // Fechamento
    builder.addCase(fetchFechamentoRecebimento.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchFechamentoRecebimento.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchFechamentoRecebimento.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
  },
})
export const { resetRecebimentos, deleteRegistros } = slice.actions
export default slice.reducer
