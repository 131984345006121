import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from '@emotion/styled'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxConcluir from './ckeckBoxConcluir'
import { makeStyles } from '@material-ui/styles'
import TaskDialog from './TaskDialog'
import { useState } from 'react'
// import CustomAvatar from '../TableComponents/CustomAvatar'
// import { ReactComponent as RedArrow } from '../../assets/icons/High.svg'
// import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg'
// import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg'

const LINES_TO_SHOW = 2

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': LINES_TO_SHOW,
    '-webkit-box-orient': 'vertical',
  },
})

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const TaskCard = ({ item, index }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Card
              sx={{
                maxWidth: 250,
                marginBottom: '6px',
                '& .MuiCardHeader-avatar': { marginRight: '4px' },
              }}
            >
              <Link underline="none" href="#" onClick={() => setOpen(true)}>
                <CardHeader
                  avatar={
                    <CheckBoxConcluir
                      tarefa={item}
                      sx={{ zIndex: 9 }}
                      open={open}
                      setOpen={setOpen}
                    />
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={
                    <Box
                      //variant="subtitle2"
                      className={classes.multiLineEllipsis}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {item.nome}
                    </Box>
                  }
                  // title={
                  //     <Box component="div" whiteSpace="normal" >
                  //         Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  //     </Box>
                  // }
                  subheader="24/03/2023"
                  sx={{ p: 1 }}
                />
                {/* <CardMedia
                component="img"
                height="194"
                image="/static/images/cards/paella.jpg"
                alt="Paella dish"
            /> */}
                {/* <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {item.descricao}
                            </Typography>
                        </CardContent> */}
                {item.situacao !== 'I' ? (
                  <>
                    <CardActions disableSpacing sx={{ p: 1 }}>
                      <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        <ShareIcon />
                      </IconButton>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography paragraph>Histórico da tarefa</Typography>
                        <Typography paragraph>
                          24/03/2023 09:53 Criação do Módulo de Tarefas
                          24/03/2023 09:54 Criação do Card Tarefa
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </>
                ) : null}
              </Link>
            </Card>
          </div>
        )}
      </Draggable>
      <TaskDialog open={open} setOpen={setOpen} />
    </>
  )
}

export default TaskCard

// <span className="priority">
// {item.Priority === 'High' ? (<RedArrow />) : item.Priority === 'Medium' ? (<YellowArrow />) : (<BlueArrow />)}
// </span>
// <div><CustomAvatar name={item.Assignee} isTable={false} size={16} /></div>
