import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'
const autorizacao = header()

const initialState = {
  loading: true,
  registros: [],
  error: '',
}

export const fetchTelefones = createAsyncThunk(
  'telefones/fetchTelefones',
  (pessoa_id) => {
    return api
      .get(`pessoas/telefones/${pessoa_id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const saveTelefones = createAsyncThunk(
  'telefones/saveTelefones',
  (registro) => {
    return api
      .post(`pessoas/telefones/salvar`, registro, autorizacao)
      .then((res) => res.data.registro)
  }
)

export const slice = createSlice({
  name: 'telefones',
  initialState,
  reducers: {
    changeTelefones(state, { payload }) {
      return { ...state, loading: false, registros: payload }
    },
    addTelefones: (state, action) => {
      state.registros.push(action.payload)
    },
    deleteTelefones: (state, action) => {
      state.registros = state.registros.filter(
        (el) => el.id !== action.payload.id
      )
    },
    updateTelefones: (state, action) => {
      // eslint-disable-next-line array-callback-return
      state.registros.map((r) => {
        if (r.id === action.payload.id) {
          return (r = action.payload)
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTelefones.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchTelefones.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchTelefones.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    builder.addCase(saveTelefones.pending, (state) => {
      state.loading = true
    })
    builder.addCase(saveTelefones.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(saveTelefones.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
  },
})

export default slice.reducer
export const {
  changeTelefones,
  addTelefones,
  deleteTelefones,
  updateTelefones,
} = slice.actions
export const selectTelefones = (state) => state.telefones.registros

//https://www.youtube.com/watch?v=bml92jhF4t8
