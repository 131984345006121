import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dataBRExibir, valorMoeda } from '../../../funcoes/data'
import { fetchNegociacoes } from '../../../redux/cadastro/negociacaoSlice'
import { fetchPagamentos } from '../../../redux/cadastro/pagamentoSlice'
import { fetchRecebimentos } from '../../../redux/cadastro/recebimentoSlice'
import Layout from '../../layouts'
import Excluir from './Excluir'
import Editar from './Editar'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchFormasPagamento } from '../../../redux/cadastro/tabelas/formasPagamentoSlice'
import { fetchTiposPagamento } from '../../../redux/cadastro/tabelas/tiposPagamentoSlice'
import { fetchStatusNegociacao } from '../../../redux/cadastro/tabelas/statusNegociacaoSlice'
import ParcelasCriadas from './ParcelasCriadas'

function Parcelas() {
  const dispatch = useDispatch()

  const [atualizarLista, setAtualizarLista] = useState(true)
  const [atualizarNegociacoes, setAtualizarNegociacoes] = useState(true)

  useEffect(() => {
    if (atualizarLista) {
      dispatch(fetchNegociacoes())
      dispatch(fetchRecebimentos())
      dispatch(fetchPagamentos())
      dispatch(fetchPessoas())
      dispatch(fetchFormasPagamento())
      dispatch(fetchTiposPagamento())
      dispatch(fetchStatusNegociacao())
    }
    setAtualizarLista(false)
  }, [atualizarLista, dispatch])

  useEffect(() => {
    if (atualizarNegociacoes) {
      dispatch(fetchNegociacoes())
    }
    setAtualizarNegociacoes(false)
  }, [atualizarNegociacoes, dispatch])

  const negociacoes = useSelector((state) => state.negociacoes.registros)
    .filter(
      (el) =>
        el.status_id === 1 &&
        (el.forma_pagamento_id === 3 || el.forma_pagamento_id === 4)
    )
    .sort((r1, r2) =>
      r1.servico.cliente.nome > r2.servico.cliente.nome ? 1 : -1
    )
  const recebimentos = useSelector((state) => state.recebimentos.registros)
  const recebimentosSort = [...recebimentos]
  recebimentosSort.sort((r1, r2) => (r1.data < r2.data ? 1 : -1))

  const pagamentos = useSelector((state) => state.pagamentos.registros)
  const pagamentosSort = [...pagamentos]
  pagamentosSort.sort((r1, r2) =>
    r1.data_vencimento < r2.data_vencimento ? 1 : -1
  )

  const formasPagamento = useSelector(
    (state) => state.formasPagamento.registros
  )

  const fornecedores = useSelector((state) => state.pessoas.registros)
    .filter((p) => p.grupo_pessoa_id === 3 || p.grupo_pessoa_id === 4)
    .sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))

  const tiposPagamento = useSelector((state) => state.tiposPagamento.registros)
    .filter((t) => t.id > 0)
    .sort((r1, r2) => (r1.nome > r2.nome ? 1 : -1))

  const statusNegociacao = useSelector(
    (state) => state.statusNegociacao.registros
  )

  const calcularUltimoRecebimento = (row) => {
    let ultimoRecebimento = {}
    let dados = recebimentosSort.find((el) => el.servico_id === row.servico_id)
    ultimoRecebimento = {
      data: dados?.data || '',
      valor: dados?.valor || '',
    }

    return ultimoRecebimento
  }

  const calcularUltimoPagamento = (row) => {
    let ultimoPagamento = {}
    let dados = pagamentosSort.find((el) => el.servico_id === row.servico_id)
    ultimoPagamento = {
      data: dados?.data_vencimento || '',
      valor: dados?.valor || '',
      fornecedor: dados?.fornecedor.nome || '',
    }

    return ultimoPagamento
  }

  return (
    <Layout titulo="Produção" subtitulo="Rotinas de produção do sistema">
      <Typography variant="h6" gutterBottom component="div">
        Geração automática de parcelas
      </Typography>
      <Typography variant="body2" gutterBottom>
        Aqui você poderá gerar as parcelas de recebimento e pagamento, de forma
        automática, para os serviços com periodicidade mensal ou anual. Confira
        os dados da lista, em especial a data da última parcela gerada e o valor
        e em seguida clique no botão "Criar Novas Parcelas".
      </Typography>
      <ParcelasCriadas dados={negociacoes} />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>OR</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Período</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Projeto</TableCell>
              <TableCell>Serviço</TableCell>
              <TableCell>Fornecedor</TableCell>
              <TableCell>Qtde</TableCell>
              <TableCell>Data</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell rowSpan={2}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {negociacoes.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>
                  {row.tipo_id === 1 ? 'Recebimento' : 'Pagamento'}
                </TableCell>
                <TableCell>{row.formasPagamento.nome}</TableCell>
                <TableCell>{row.servico?.cliente?.nome}</TableCell>
                <TableCell>{row.servico.projeto.nome}</TableCell>
                <TableCell>{row.servico.nome}</TableCell>
                <TableCell>
                  {row.tipo_id === 2 ? row.fornecedor?.nome : ''}
                </TableCell>
                <TableCell>{row.qtde_parcelas}</TableCell>
                <TableCell>{dataBRExibir(row.data_referencia)}</TableCell>
                <TableCell>{valorMoeda(row.valor_referencia)}</TableCell>
                <TableCell>
                  <Box display={'flex'}>
                    <Excluir registro={row} />
                    <Editar
                      registro={row}
                      fornecedores={fornecedores}
                      formasPagamento={formasPagamento}
                      ultimoRecebimento={calcularUltimoRecebimento(row)}
                      ultimoPagamento={calcularUltimoPagamento(row)}
                      tiposPagamento={tiposPagamento}
                      statusNegociacao={statusNegociacao}
                      setAtualizarNegociacoes={setAtualizarNegociacoes}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default Parcelas
