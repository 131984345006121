import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../../layouts'
import { navigate } from 'hookrouter'

function Equipes() {
  return (
    <Layout
      titulo="Gestão das Tarefas"
      subtitulo="Cadastro e Gestão das Tarefas"
    >
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography paragraph variant="h4">
          Equipes
        </Typography>
        <Typography paragraph>Página em construção</Typography>
        <Button variant="outlined" onClick={() => navigate('/sistema/tarefas')}>
          Voltar
        </Button>
      </Box>
    </Layout>
  )
}

export default Equipes
