import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  registros: [],
  error: {},
}

export const listarTiposConta = createAsyncThunk(
  'contas/listar/tiposConta',
  () => {
    const autorizacao = header()
    return api.get('/tiposConta', autorizacao).then((res) => res.data)
  }
)

export const salvarTipoConta = createAsyncThunk(
  'contas/salvar/tipoConta',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/tiposConta/salvar', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const excluirTipoConta = createAsyncThunk(
  'contas/excluir/tipoConta',
  (id) => {
    const autorizacao = header()
    return api
      .get(`/tiposConta/excluir/${id}`, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'tiposConta',
  initialState: initialState,
  extraReducers: (builder) => {
    //Listar Tipos de Conta
    builder.addCase(listarTiposConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(listarTiposConta.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(listarTiposConta.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Salvar Tipos de Conta
    builder.addCase(salvarTipoConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarTipoConta.fulfilled, (state, action) => {
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )
      if (index === -1) {
        state.registros.push(action.payload.registro)
      } else {
        state.registros[index] = {
          ...state.registros[index],
          ...action.payload.registro,
        }
      }

      state.loading = false
    })
    // Excluir Tipo de Conta
    builder.addCase(excluirTipoConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(excluirTipoConta.fulfilled, (state, action) => {
      const id = parseInt(action.payload)
      state.registros = state.registros.filter((registro) => registro.id !== id)
      state.loading = false
    })
  },
})

export default slice.reducer
