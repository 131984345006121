import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  registros: [],
  error: {},
}

export const listarBancos = createAsyncThunk('contas/listar/bancos', () => {
  const autorizacao = header()
  return api.get('/bancos', autorizacao).then((res) => res.data)
})

export const salvarBanco = createAsyncThunk(
  'contas/salvar/banco',
  (registro) => {
    const autorizacao = header()
    return api
      .post('/bancos/salvar', registro, autorizacao)
      .then((res) => res.data)
  }
)

export const excluirBanco = createAsyncThunk('contas/excluir/banco', (id) => {
  const autorizacao = header()
  return api.get(`/bancos/excluir/${id}`, autorizacao).then((res) => res.data)
})

export const slice = createSlice({
  name: 'contas',
  initialState: initialState,
  extraReducers: (builder) => {
    //Listar Banco
    builder.addCase(listarBancos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(listarBancos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(listarBancos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Salvar Banco
    builder.addCase(salvarBanco.pending, (state) => {
      state.loading = true
    })
    builder.addCase(salvarBanco.fulfilled, (state, action) => {
      const index = state.registros.findIndex(
        (registro) => registro.id === action.payload.registro.id
      )

      if (index === -1) {
        state.registros.push(action.payload.registro)
      } else {
        state.registros[index] = {
          ...state.registros[index],
          ...action.payload.registro,
        }
      }

      state.loading = false
    })
    // Excluir Banco
    builder.addCase(excluirBanco.pending, (state) => {
      state.loading = true
    })
    builder.addCase(excluirBanco.fulfilled, (state, action) => {
      const id = parseInt(action.payload)
      state.registros = state.registros.filter((registro) => registro.id !== id)
      state.loading = false
    })
  },
})

export default slice.reducer
