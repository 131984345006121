import { Box } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../layouts'
import Formulario from './Form'
import Table from './Table'

const Projetos = (props) => {
  const [modo, setModo] = useState({ status: 'table', registro: {} })
  const [projetoAtual, setProjetoAtual] = useState({})
  const tiposProjeto = useSelector((state) => state.tiposProjeto.registros)

  return (
    <Layout titulo="Projetos" subtitulo="Cadastro de projetos">
      <Box>
        {modo.status === 'table' ? (
          <Table
            setModo={setModo}
            modo={modo}
            projetoAtual={projetoAtual}
            setProjetoAtual={setProjetoAtual}
          />
        ) : (
          <Formulario
            setModo={setModo}
            modo={modo}
            tiposProjeto={tiposProjeto}
          />
        )}
      </Box>
    </Layout>
  )
}

export default Projetos
