import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { valorMoeda } from '../../../funcoes/data'
import { fetchEntradasSaidas } from '../../../redux/controleGeral/controleGeralSlice'

export const EntradasSaidas = (props) => {
  const { entradasSaidas, atualizarEntradasSaidas } = props

  useEffect(() => {
    atualizarEntradasSaidas()
  }, [atualizarEntradasSaidas])

  const anos = [
    ...new Set(
      entradasSaidas.dados.map((el) => {
        return el.ANO
      })
    ),
  ]

  const styleNegativo = (value) => {
    if (value < 0.0) {
      return 'red'
    }
  }

  return (
    <Box>
      {entradasSaidas.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : entradasSaidas.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {entradasSaidas.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Valores totais de entradas e débitos - Itens marcados com o status
            CONTA
          </Typography>
          {anos.map((ano, index) => {
            let subJAN = 0.0
            let subFEV = 0.0
            let subMAR = 0.0
            let subABR = 0.0
            let subMAI = 0.0
            let subJUN = 0.0
            let subJUL = 0.0
            let subAGO = 0.0
            let subSET = 0.0
            let subOUT = 0.0
            let subNOV = 0.0
            let subDEZ = 0.0
            let subTOT = 0.0

            return (
              <Box key={index}>
                <Box>
                  <Typography variant="h5" sx={{ my: 2 }}>
                    {ano}
                  </Typography>
                </Box>
                <TableContainer component={Paper} key={ano}>
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            fontWeight: 'bold',
                            minWidth: 90,
                          },
                        }}
                      >
                        <TableCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: '#F2F2F2',
                          }}
                        >
                          TITULO
                        </TableCell>
                        <TableCell align="right">JAN</TableCell>
                        <TableCell align="right">FEV</TableCell>
                        <TableCell align="right">MAR</TableCell>
                        <TableCell align="right" sx={{ minWidth: 100 }}>
                          ABR
                        </TableCell>
                        <TableCell align="right">MAI</TableCell>
                        <TableCell align="right">JUN</TableCell>
                        <TableCell align="right">JUL</TableCell>
                        <TableCell align="right">AGO</TableCell>
                        <TableCell align="right">SET</TableCell>
                        <TableCell align="right">OUT</TableCell>
                        <TableCell align="right">NOV</TableCell>
                        <TableCell align="right">DEZ</TableCell>
                        <TableCell align="right">TOTAL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {entradasSaidas.dados
                        .filter((el) => el.ANO === ano)
                        .map((dado, index) => {
                          subJAN += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.JANEIRO
                              : dado.JANEIRO * -1
                          )
                          subFEV += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.FEVEREIRO
                              : dado.FEVEREIRO * -1
                          )
                          subMAR += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.MARÇO
                              : dado.MARÇO * -1
                          )
                          subABR += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.ABRIL
                              : dado.ABRIL * -1
                          )
                          subMAI += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.MAIO
                              : dado.MAIO * -1
                          )
                          subJUN += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.JUNHO
                              : dado.JUNHO * -1
                          )
                          subJUL += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.JULHO
                              : dado.JULHO * -1
                          )
                          subAGO += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.AGOSTO
                              : dado.AGOSTO * -1
                          )
                          subSET += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.SETEMBRO
                              : dado.SETEMBRO * -1
                          )
                          subOUT += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.OUTUBRO
                              : dado.OUTUBRO * -1
                          )
                          subNOV += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.NOVEMBRO
                              : dado.NOVEMBRO * -1
                          )
                          subDEZ += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.DEZEMBRO
                              : dado.DEZEMBRO * -1
                          )
                          subTOT += parseFloat(
                            dado.TITULO === 'ENTRADA'
                              ? dado.TOTAL
                              : dado.TOTAL * -1
                          )

                          return (
                            <TableRow
                              key={String(ano).concat(String(index))}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{
                                  position: 'sticky',
                                  left: 0,
                                  backgroundColor: '#F2F2F2',
                                  fontWeight: 'bold',
                                }}
                              >
                                {dado.TITULO}
                              </TableCell>
                              <TableCell align="right">
                                {dado.JANEIRO !== '0.00'
                                  ? valorMoeda(dado.JANEIRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.FEVEREIRO !== '0.00'
                                  ? valorMoeda(dado.FEVEREIRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.MARÇO !== '0.00'
                                  ? valorMoeda(dado.MARÇO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.ABRIL !== '0.00'
                                  ? valorMoeda(dado.ABRIL)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.MAIO !== '0.00'
                                  ? valorMoeda(dado.MAIO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.JUNHO !== '0.00'
                                  ? valorMoeda(dado.JUNHO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.JULHO !== '0.00'
                                  ? valorMoeda(dado.JULHO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.AGOSTO !== '0.00'
                                  ? valorMoeda(dado.AGOSTO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.SETEMBRO !== '0.00'
                                  ? valorMoeda(dado.SETEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.OUTUBRO !== '0.00'
                                  ? valorMoeda(dado.OUTUBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.NOVEMBRO !== '0.00'
                                  ? valorMoeda(dado.NOVEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.DEZEMBRO !== '0.00'
                                  ? valorMoeda(dado.DEZEMBRO)
                                  : ''}
                              </TableCell>
                              <TableCell align="right">
                                {dado.TOTAL !== '0.00'
                                  ? valorMoeda(dado.TOTAL)
                                  : ''}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: '#F2F2F2',
                            fontWeight: 'bold',
                          }}
                        >
                          TOTAL {ano}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subJAN),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subJAN)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subFEV),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subFEV)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subMAR),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subMAR)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subABR),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subABR)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subMAI),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subMAI)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subJUN),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subJUN)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subJUL),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subJUL)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subAGO),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subAGO)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subSET),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subSET)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subOUT),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subOUT)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subNOV),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subNOV)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subDEZ),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subDEZ)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: styleNegativo(subTOT),
                            fontWeight: 'bold',
                          }}
                        >
                          {valorMoeda(subTOT)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    entradasSaidas: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarEntradasSaidas: () => dispatch(fetchEntradasSaidas()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntradasSaidas)
