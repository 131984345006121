import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../../middleware/jwtInterceptor'
import api from '../../../services/api'

const initialState = {
  loading: true,
  aba: 0,
  pessoaAtual: {},
  upload: {},
  registros: [],
  error: '',
}

export const uploadImagem = createAsyncThunk(
  'cadastro/pessoa/upload',
  (formData) => {
    const autorizacao = header()
    return api
      .post('/upload-image', formData, autorizacao)
      .then((res) => res.data)
  }
)

export const fetchPessoa = createAsyncThunk('cadastro/pessoa', (pessoa_id) => {
  const autorizacao = header()
  if (pessoa_id) {
    return api.get(`pessoas/${pessoa_id}`, autorizacao).then((res) => res.data)
  }
})

export const fetchPessoas = createAsyncThunk('cadastro/pessoas', () => {
  const autorizacao = header()
  return api.get('/pessoas/cadastro/lista', autorizacao).then((res) => res.data)
})

export const savePessoa = createAsyncThunk(
  'cadastro/pessoas/savePessoa',
  (registro) => {
    const autorizacao = header()
    return api.post(`/pessoas`, registro, autorizacao).then((res) => res.data)
  }
)

export const deletePessoa = createAsyncThunk(
  'cadastro/pessoa/delete',
  (registro) => {
    const autorizacao = header()
    return api
      .put(`/pessoas/exclusaoLogica/${registro.id}`, registro, autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'pessoa',
  initialState,
  reducers: {
    changePessoa(state, { payload }) {
      return { ...state, loading: false, dados: payload }
    },
    deleteStatePessoa: (state, action) => {
      state.registros = state.registros.filter(
        (el) => el.id !== action.payload.id
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPessoa.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPessoa.fulfilled, (state, action) => {
      state.loading = false
      state.pessoaAtual = action.payload
      state.error = ''
    })
    builder.addCase(fetchPessoa.rejected, (state, action) => {
      state.loading = false
      state.pessoaAtual = {}
      state.error = action.error.message
    })

    builder.addCase(savePessoa.pending, (state) => {
      state.loading = true
    })
    builder.addCase(savePessoa.fulfilled, (state, action) => {
      state.loading = false
      state.pessoaAtual = action.payload
      state.error = ''
    })
    builder.addCase(savePessoa.rejected, (state, action) => {
      state.loading = false
      state.pessoaAtual = {}
      state.error = action.error.message
    })

    builder.addCase(fetchPessoas.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPessoas.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchPessoas.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })

    builder.addCase(uploadImagem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(uploadImagem.fulfilled, (state, action) => {
      state.loading = false
      state.upload = action.payload
      state.error = ''
    })
    builder.addCase(uploadImagem.rejected, (state, action) => {
      state.loading = false
      state.upload = {}
      state.error = action.error.message
    })

    builder.addCase(deletePessoa.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deletePessoa.fulfilled, (state, action) => {
      state.loading = false
      state.upload = action.payload
      state.error = ''
    })
    builder.addCase(deletePessoa.rejected, (state, action) => {
      state.loading = false
      state.upload = {}
      state.error = action.error.message
    })
  },
})

export const { changePessoa, deleteStatePessoa } = slice.actions
export const selectPessoas = (state) => state.pessoas.registros
export const selectArquivo = (state) => state.pessoas.upload
export const selectPessoaAtual = (state) => state.pessoas.pessoaAtual
export default slice.reducer
