import { Box, Checkbox, Tooltip } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

export const CheckBoxConcluir = (props) => {
  const { setOpen } = props
  const [checked, setChecked] = useState(false)

  const handleChange = (event) => {
    setChecked(event.target.checked)
    setOpen(false)
  }
  return (
    <Box>
      <Tooltip
        title={checked ? 'Reativar a tarefa' : 'Concluir a tarefa'}
        placement="top"
      >
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<TaskAltIcon sx={{ color: 'green' }} />}
          checked={checked}
          onChange={handleChange}
        />
      </Tooltip>
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CheckBoxConcluir)
