import * as React from 'react'
import Button from '@mui/material/Button'
//import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function TaskDialog(props) {
  const { open, setOpen } = props
  //const [open, setOpen] = React.useState(true);

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Tarefa</DialogTitle>
        <DialogContent>
          <DialogContentText>Em construção.</DialogContentText>
          {/* <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  )
}
