import { Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Logo from '../../img/logo_ideiahub.png'
import Layout from '../layouts'

function Home() {
  const [user, setUser] = useState({})

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('stateAutenticacao'))
    console.log(user)
    setUser(user)
  }, [])

  const [mensagem, setMensagem] = useState('')

  useEffect(() => {
    const mensagens = [
      'Lembre-se, o sucesso não é a chave para a felicidade. A felicidade é a chave para o sucesso. Se você ama o que está fazendo, você será bem-sucedido.',
      'Não espere por grandes oportunidades, crie suas próprias oportunidades.',
      'O tempo que leva para realizar seus sonhos vai passar de qualquer maneira.',
      'Não compare seu início com o fim de alguém. Cada pessoa tem seu próprio ritmo.',
      'A persistência é o caminho do êxito. Não desista nunca.',
      'O sucesso é a soma de pequenos esforços repetidos dia após dia.',
      'O sucesso é a capacidade de ir de fracasso em fracasso sem perder o entusiasmo.',
      'Somos o que fazemos repetidamente. Excelência não é um ato, mas sim um hábito.',
      'Se você não consegue tolerar críticas de jeito algum, então não faça nada novo ou interessante.',
      'Eu acho que a frugalidade impulsiona a inovação, assim como outras restrições também fazem isso. Uma das únicas maneiras de sair de uma caixa apertada é inventar sua saída.',
      'Trabalhe até que seus rivais se tornem ídolos.',
      'O sucesso é um péssimo professor. Ele leva pessoas inteligentes a pensarem que não podem perder.',
      'Você não é pago pela hora. Você é pago pelo valor que traz para a hora. ',
      'Você nunca chegará ao seu destino se parar para atirar pedras em cada cachorro que late.',
      'Objetivo é um sonho com prazo de entrega.',
      'Se você não encontrar um jeito de ganhar dinheiro enquanto dorme, você vai trabalhar até morrer.',
      'O dinheiro é só uma consequência. Eu sempre digo à minha equipe: ‘Não se preocupem com a rentabilidade. Se você fizer seu trabalho direito, o lucro virá’.',
      'Primeiro, lembre-se de olhar para as estrelas e não para os pés. Dois, nunca desista do trabalho. O trabalho lhe dá sentido e propósito e a vida é vazia sem ele. Três, se você tiver sorte o suficiente para encontrar o amor, lembre-se de que ele está lá e não o jogue fora. ',
      'O maior risco é não assumir nenhum risco. Em um mundo que muda rapidamente, a única estratégia garantida a falhar é não assumir riscos.',
      'Continuarei trabalhando até o fim.',
      'O sucesso nem sempre tem a ver com grandeza. É uma questão de consistência. O trabalho árduo consistente leva ao sucesso. A grandeza virá. ',
      'Devemos nos esforçar incansavelmente para criar produtos novos e melhores e produzi-los com mais eficiência do que as alternativas que já existem.',
      'Eu acredito que você precisa seguir os seus sonhos. Foi o que eu fiz.',
      'Muitas empresas não conseguem sucesso com o tempo. O que elas fazem de errado? Elas geralmente não pensam no futuro.',
      'Eu aprendi com o meu pai que mudança e experimentação são constantes e importantes. Você precisa continuar tentando coisas novas.',
      'Se devemos assumir nossas perdas, então sejamos adultos. Não devemos ficar chorando.',
      'Eu não vim para este mundo para trabalhar. Eu vim para aproveitar a minha vida. Eu não quero morrer no meu escritório. Eu quero morrer na praia.',
      'Só adie para amanhã o que você estiver disposto a morrer deixando inacabado.',
      'Pense grande, mire alto, trabalhe duro e nunca desista.',
      'Ser líder é alcançar resultados com o time certo, da maneira correta.',
      'A melhor vingança é o sucesso estrondoso.',
      'Liderar é fazer nascer as palavras que promovem vida e entusiasmo.',
      'As dificuldades fortalecem a mente da mesma forma que o trabalho faz com o corpo.',
      'Nenhum fracasso significa nenhum risco, o que significa nada de novo.',
      'Melhorar a si mesmo é muito mais lucrativo do que tentar melhorar os outros.',
      'O sucesso não é determinado por quantas vezes você ganha, mas por como você joga na semana após a derrota.',
      'De longe, o melhor prêmio que a vida tem a oferecer é a chance de trabalhar duro em algo que valha a pena.',
      'Apenas acredite em si mesmo. Se não, finja que sim e, em algum momento, vai ser verdade.',
      'A vida lhe dará a experiência que for mais útil para a evolução de sua consciência.',
      'A única jornada impossível é aquela que você nunca começa.',
      'Sucesso é a capacidade de ir de um fracasso a outro sem perder o entusiasmo.',
      'Comece onde está. Use o que tem. Faça o que pode.',
      'Caia para a frente. Cada experimento com falhas é um passo mais perto do sucesso. Você tem que correr riscos.',
      'Para realizar grandes coisas, devemos não apenas agir, mas também sonhar, não apenas planejar, mas também acreditar.',
      'Não julgue os dias pelas colheitas que você faz, mas pelas sementes que você planta.',
      'Uma centena de fracassos não importam, quando um único sucesso pode mudar o destino do mundo.',
      'Diga-me quem são seus heróis, e eu direi quem você se tornará.',
      'Meu maior erro foi pensar que eu não deveria mostrar meus erros. Eu aprendi que deveria.',
      'Você não pode forçar que algo aconteça na sua vida. Você deve ser aberto e aceitar quando for a hora certa.',
      'Se você contratou os melhores profissionais, não há razões para não permitir que eles tomem as melhores decisões.',
      'Você não pode enterrar seus fracassos, mas sim falar sobre eles abertamente e analisar o que deu errado para poder aprender novas regras de tomada de decisão.',
      'Comece com boas pessoas, estabeleça as regras, comunique-se com seus funcionários, motive-os e recompense-os. Se você fizer todas essas coisas de forma eficaz, não pode errar.',
      'Sempre faça o que é certo e inteligente no longo prazo, independentemente da sabedoria convencional.',
      'Ser o homem mais rico do cemitério não me interessa. Ir para a cama sabendo que fiz algo sensacional é o que me importa.',
      'Devemos prometer somente aquilo que podemos entregar e entregar mais do que prometemos',
      'Profissional nota 10 é aquele que tem 2 pontos de esforço, 3 pontos de talento e 5 pontos de caráter',
      'O importante é mudar sempre, mesmo que estejamos crescendo e ganhando o jogo',
      'Se existe uma forma de fazer melhor, descubra-a',
      'A sorte favorece a mente preparada',
      'Pessoas com meta têm sucesso pois sabem onde estão indo',
      'Não há sucesso sem dificuldade',
      'Toda glória advém de ousar começar',
      'Os satisfeitos nada mais fazem. Os insatisfeitos são os únicos propulsores do mundo',
      'O que merece ser feito, merece ser bem feito',
      'Planeje o seu trabalho hoje para todos os dias trabalhar o seu plano',
      'Não atrase o seu progresso com medo de fracassar',
      'O verdadeiro risco é não fazer nada',
      'Se você não sabe para onde vai, provavelmente terminará em algum outro lugar',
      'Todo mundo tem talento, é só uma questão de se mexer até descobrir qual',
      'Os problemas são oportunidades para se mostrar o que sabe.',
      'Ler, ler, ler. Leia tudo.',
      'Aqueles que não fazem nada estão sempre dispostos a criticar os que fazem algo.',
      'Todo o trabalho é vazio a não ser que haja amor.',
      'O trabalho só nos cansa se não nos dedicarmos a ele com alegria.',
      'Trabalho é amor tornado visível.',
      'A sabedoria consiste em compreender que o tempo dedicado ao trabalho nunca é perdido.',
      'A alegria e o trabalho são duas coisas sãs e que atraem reciprocamente.',
    ]

    const hoje = new Date()
    const diaDoAno = hoje.getDate()
    const mensagemIndex = diaDoAno % mensagens.length

    setMensagem(mensagens[mensagemIndex])
  }, [])

  return (
    <Layout titulo="Home" subtitulo="Página inicial do sistema">
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Grid
          item
          display={'flex'}
          justifyContent={'center'}
          sx={{
            p: 1,
            width: { lg: '500px', md: '400px', sm: '350px', xs: '300px' },
          }}
        >
          <img
            src={Logo}
            alt="Logotipo Ideia Ninja"
            loading="lazy"
            width={'100%'}
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: { lg: '30px', md: '25px', sm: '20px', xs: '18px' },
            }}
          >
            Data atual ({new Date().toLocaleDateString()})
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: { lg: '30px', md: '25px', sm: '20px', xs: '18px' },
            }}
          >
            Seja Bem Vindo{' '}
            <span
              style={{
                color: '#003399',
                fontWeight: 'bold',
              }}
            >
              {user?.login?.user?.name || ''}{' '}
            </span>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: { lg: '22px', md: '16px', sm: '12px', xs: '14px' },
              textAlign: 'center',
            }}
          >
            Mensagem do dia: <br />
            {mensagem}
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Home
