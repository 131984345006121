import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { valorInteiro, valorMoeda } from '../../../funcoes/data'
import { fetchInadimplencia } from '../../../redux/controleGeral/controleGeralSlice'

export const Inadimplencia = (props) => {
  const { inadimplencia, atualizarInadimplencia } = props

  useEffect(() => {
    atualizarInadimplencia()
  }, [atualizarInadimplencia])

  const totalizarQtde = () => {
    return inadimplencia.dados.reduce((a, b) => a + parseInt(b.QTDE), 0)
  }

  const totalizarValor = () => {
    return inadimplencia.dados.reduce((a, b) => a + parseFloat(b.VALOR), 0.0)
  }

  return (
    <Box>
      {inadimplencia.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : inadimplencia.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {inadimplencia.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Inadimplência
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell>ID</TableCell>
                  <TableCell>TITULO</TableCell>
                  <TableCell>DESCRIÇÃO</TableCell>
                  <TableCell align="right">QTDE</TableCell>
                  <TableCell align="right">VALOR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inadimplencia.dados &&
                  inadimplencia.dados.map((row) => (
                    <TableRow
                      key={row.ID}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.ID}</TableCell>
                      <TableCell>{row.TITULO}</TableCell>
                      <TableCell>{row.DESCRICAO}</TableCell>
                      <TableCell align="right">
                        {valorInteiro(row.QTDE)}
                      </TableCell>
                      <TableCell align="right">
                        {valorMoeda(row.VALOR)}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow
                  py={10}
                  sx={{
                    '&:last-child td, &:last-child th': { fontWeight: 'bold' },
                  }}
                >
                  <TableCell colSpan={3}>TOTAL</TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorInteiro(totalizarQtde())}
                  </TableCell>
                  <TableCell align="right">
                    {' '}
                    {valorMoeda(totalizarValor())}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    inadimplencia: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarInadimplencia: () => dispatch(fetchInadimplencia()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inadimplencia)
