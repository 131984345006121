import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import header from '../../middleware/jwtInterceptor'
import api from '../../services/api'

const initialState = {
  loading: true,
  registros: [],
  error: '',
}

export const fetchEntradasPorOrigem = createAsyncThunk(
  'controleGeral/entradasPorOrigem',
  () => {
    const autorizacao = header()
    return api
      .get('controleGeral/entradaOrigem', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchDebitosPorOrigem = createAsyncThunk(
  'controleGeral/debitosPorOrigem',
  () => {
    const autorizacao = header()
    return api
      .get('controleGeral/debitoOrigem', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchConta = createAsyncThunk('controleGeral/conta', async () => {
  const autorizacao = header()
  return api.get('controleGeral/conta', autorizacao).then((res) => res.data)
})

export const fetchInadimplencia = createAsyncThunk(
  'controleGeral/inadimplencia',
  () => {
    const autorizacao = header()
    return api
      .get('controleGeral/inadimplencia', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchServicos = createAsyncThunk('controleGeral/servicos', () => {
  const autorizacao = header()
  return api.get('controleGeral/servicos', autorizacao).then((res) => res.data)
})

export const fetchSalarios = createAsyncThunk('controleGeral/salarios', () => {
  const autorizacao = header()
  return api.get('controleGeral/salarios', autorizacao).then((res) => res.data)
})

export const fetchSalariosHistorico = createAsyncThunk(
  'controleGeral/salarios/historico',
  () => {
    const autorizacao = header()
    return api
      .get('controleGeral/salarios/historico', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchEntradasSaidas = createAsyncThunk(
  'controleGeral/entradaSaida',
  () => {
    const autorizacao = header()
    return api
      .get('controleGeral/entradasSaidas', autorizacao)
      .then((res) => res.data)
  }
)

export const fetchClientes = createAsyncThunk('controleGeral/clientes', () => {
  const autorizacao = header()
  return api.get('/controleGeral/clientes', autorizacao).then((res) => res.data)
})

export const fetchFornecedores = createAsyncThunk(
  'controleGeral/fornecedores',
  () => {
    const autorizacao = header()
    return api
      .get('/controleGeral/fornecedores', autorizacao)
      .then((res) => res.data)
  }
)

export const slice = createSlice({
  name: 'entradaOrigem',
  initialState,
  extraReducers: (builder) => {
    // Entradas
    builder.addCase(fetchEntradasPorOrigem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchEntradasPorOrigem.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchEntradasPorOrigem.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Debitos
    builder.addCase(fetchDebitosPorOrigem.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDebitosPorOrigem.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchDebitosPorOrigem.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Conta
    builder.addCase(fetchConta.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchConta.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchConta.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Inadimplência
    builder.addCase(fetchInadimplencia.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchInadimplencia.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchInadimplencia.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Serviço
    builder.addCase(fetchServicos.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchServicos.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchServicos.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Salarios
    builder.addCase(fetchSalarios.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchSalarios.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchSalarios.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Historico de Salarios
    builder.addCase(fetchSalariosHistorico.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchSalariosHistorico.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchSalariosHistorico.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Entradas e Saídas
    builder.addCase(fetchEntradasSaidas.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchEntradasSaidas.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchEntradasSaidas.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Clientes
    builder.addCase(fetchClientes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchClientes.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchClientes.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
    // Fornecedores
    builder.addCase(fetchFornecedores.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchFornecedores.fulfilled, (state, action) => {
      state.loading = false
      state.registros = action.payload
      state.error = ''
    })
    builder.addCase(fetchFornecedores.rejected, (state, action) => {
      state.loading = false
      state.registros = []
      state.error = action.error.message
    })
  },
})

export default slice.reducer
