import * as React from 'react'
//import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import { red } from '@mui/material/colors'
import { Box, IconButton, Typography } from '@mui/material'
//import { navigate } from 'hookrouter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DialogErro(props) {
  const handleClose = () => {
    props.setOpenDialogErro(false)
    //window.history.go(-1)
    //navigate('/')
  }

  return (
    <Dialog
      open={props.openDialogErro}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ display: 'flex', gap: 2 }}>
        <ErrorIcon sx={{ color: red[900], fontSize: 30 }} />
        {'  '}
        {'Erro'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          {props?.mensagem}
        </Typography>
        <Box sx={{ mt: 2, border: 1, padding: 1, borderRadius: '5px' }}>
          {' '}
          {props.dialogError ? (
            <Typography variant="body2" gutterBottom>
              {props.dialogError}
            </Typography>
          ) : (
            <Typography variant="body2" gutterBottom>
              O Sistema não apresentou mensagem de erro.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ mr: 2, mb: 1 }}>
        <Button variant="contained" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
