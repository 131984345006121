import { Box } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../../layouts'
import Formulario from './Formulario'
import Tabela from './Tabela'

export const StatusRecebimentos = (props) => {
  const [modo, setModo] = useState({ status: 'table', registro: {} })

  return (
    <Layout
      titulo="Status dos Recebimentos"
      subtitulo="Situação atual dos recebimentos."
    >
      <Box>
        {modo.status === 'table' ? (
          <Tabela setModo={setModo} modo={modo} />
        ) : (
          <Formulario setModo={setModo} modo={modo} />
        )}
      </Box>
    </Layout>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StatusRecebimentos)
