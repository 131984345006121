import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material/styles'
import { fetchClientes } from '../../../redux/controleGeral/controleGeralSlice'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { valorMoeda } from '../../../funcoes/data'
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'

import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'

function TablePaginationActions(props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export const Clientes = (props) => {
  const { clientes, atualizarClientes } = props

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  useEffect(() => {
    atualizarClientes()
  }, [atualizarClientes])

  const columns = [
    {
      id: 'id',
      label: 'ID',
      style: {
        minWidth: 30,
        position: 'sticky',
        left: 0,
        top: 0,
        backgroundColor: '#F2F2F2',
      },
      styleHeader: { position: 'sticky', left: 0, zIndex: 3 },
    },
    {
      id: 'CLIENTE',
      label: 'CLIENTE',
      style: {
        width: 150,
        position: 'sticky',
        left: 42,
        backgroundColor: '#F2F2F2',
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      styleHeader: { position: 'sticky', left: 42, zIndex: 3 },
    },
    {
      id: 'TOTAL',
      label: 'TOTAL',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'CONTA',
      label: 'CONTA',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'COBRANCA',
      label: 'COBRANÇA',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'ORCAMENTO',
      label: 'ORÇAMENTO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'PAUSADO',
      label: 'PAUSADO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'DEVEDOR',
      label: 'DEVEDOR',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },

    {
      id: 'BOLETO',
      label: 'BOLETO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'NOTAFISCAL',
      label: 'NOTA FISCAL',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'BOLETOANDRE',
      label: 'BOLETO ANDRÉ',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'BOLETOMARCIO',
      label: 'BOLETO MÁRCIO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'ATRASADO',
      label: 'ATRASADO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },

    {
      id: 'COMANDRE',
      label: 'COM ANDRÉ',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'COMMARCIO',
      label: 'COM MÁRCIO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'PERMUTA',
      label: 'PERMUTA',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },

    {
      id: 'AGUARDANDO',
      label: 'AGUARDANDO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'FINALIZADO',
      label: 'FINALIZADO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },
    {
      id: 'DEPAUTOMATICO',
      label: 'DEPÓSITO AUTOMÁTICO',
      style: { minWidth: 80 },
      align: 'right',
      type: 'number',
      format: (value) => (value !== '0.00' ? valorMoeda(value) : ''),
    },

    // {
    //     id: 'population',
    //     label: 'Population',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('pt-BR'),
    // },
    // {
    //     id: 'size',
    //     label: 'Size\u00a0(km\u00b2)',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('pt-BR'),
    // },
    // {
    //     id: 'density',
    //     label: 'Density',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value) => value.toLocaleString('pt-BR'),
    // },
  ]

  const rows = clientes.dados

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Box>
      {clientes.loading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      ) : clientes.error ? (
        <Typography variant="body">
          Não foi possivel acessar os dados. Informe ao administrador do
          sistema. (Erro: {clientes.error})
        </Typography>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ my: 2 }}>
            Clientes
          </Typography>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={column.styleHeader}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          {columns.map((column) => {
                            const value = row[column.id]
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={column.style}
                              >
                                {column.format && column.type === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, { value: -1, label: 'Todos' }]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    clientes: {
      loading: state.controleGeral.loading,
      dados: state.controleGeral.registros,
      error: state.controleGeral.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    atualizarClientes: () => dispatch(fetchClientes()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Clientes)

// import * as React from 'react'
// import { DataGrid } from '@mui/x-data-grid'
// import { connect } from 'react-redux'
// import { useEffect } from 'react'
// import { fetchClientes } from '../../../redux/controleGeral/controleGeralSlice'
// import { Backdrop, Box, CircularProgress, Typography } from '@mui/material'

// const columns = [
//     { field: 'id', headerName: 'id', width: 50 },
//     { field: 'CLIENTE', headerName: 'CLIENTE', width: 180 },
//     { field: 'CONTA', headerName: 'CONTA', width: 130, type: 'number' },
//     { field: 'BOLETO', headerName: 'BOLETO', width: 130, type: 'number' },
//     { field: 'NOTAFISCAL', headerName: 'NOTA FISCAL', width: 130, type: 'number' },
//     { field: 'BOLETOANDRE', headerName: 'BOLETO ANDRE', width: 130, type: 'number' },
//     { field: 'BOLETOMARCIO', headerName: 'BOLETO MARCIO', width: 130, type: 'number' },
//     { field: 'ATRASADO', headerName: 'ATRASADO', width: 130, type: 'number' },
//     { field: 'COBRANCA', headerName: 'COBRANÇA', width: 130, type: 'number' },
//     { field: 'DEVEDOR', headerName: 'DEVEDOR', width: 130, type: 'number' },
//     { field: 'COMANDRE', headerName: 'COM ANDRÉ', width: 130, type: 'number' },
//     { field: 'COMMARCIO', headerName: 'COM MÁRCIO', width: 130, type: 'number' },
//     { field: 'PERMUTA', headerName: 'PERMUTA', width: 130, type: 'number' },
//     { field: 'PAUSADO', headerName: 'PAUSADO', width: 130, type: 'number' },
//     { field: 'ORCAMENTO', headerName: 'ORÇAMENTO', width: 130, type: 'number' },
//     { field: 'AGUARDANDO', headerName: 'AGUARDANDO', width: 130, type: 'number' },
//     { field: 'FINALIZADO', headerName: 'FINALIZADO', width: 130, type: 'number' },
//     { field: 'DEPAUTOMATICO', headerName: 'DEPÓSITO AUTOMÁTICO', width: 130, type: 'number' },
//     { field: 'TOTAL', headerName: 'TOTAL', width: 130, type: 'number' },
// ];

// // const rows = [
// //     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
// //     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
// //     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
// //     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
// //     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
// //     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
// //     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
// //     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
// //     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// // ];

// export const Clientes = (props) => {
//     const { clientes, atualizarClientes } = props

//     useEffect(() => {
//         atualizarClientes()
//     }, [atualizarClientes])

//     console.log('clientes', clientes)

//     const rows = clientes.dados

//     return (
//         <Box>
//             {
//                 clientes.loading ? (
//                     <Backdrop
//                         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//                         open={true}
//                     >
//                         <CircularProgress color="secondary" />
//                     </Backdrop>
//                 ) : clientes.error ? (
//                     <Typography variant='body'>Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {clientes.error})</Typography>
//                 ) : (
//                     <Box>
//                         <Typography variant='h5' sx={{ my: 2 }}>Clientes</Typography>
//                         <Box style={{ height: 490, width: '100%' }}>
//                             <DataGrid
//                                 rows={rows}
//                                 columns={columns}
//                                 pageSize={5}
//                                 rowsPerPageOptions={[5]}
//                             />
//                         </Box>
//                     </Box >
//                 )
//             }
//         </Box>
//     )
// }

// const mapStateToProps = (state) => {
//     return {
//         clientes: {
//             loading: state.controleGeral.loading,
//             dados: state.controleGeral.registros,
//             error: state.controleGeral.error
//         }
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         atualizarClientes: () => dispatch(fetchClientes())
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Clientes)

// import { Backdrop, Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
// import React from 'react'
// import { useEffect } from 'react'
// import { connect } from 'react-redux'
// import { valorMoeda } from '../../../funcoes/data'
// import { fetchClientes } from '../../../redux/controleGeral/controleGeralSlice'

// export const Clientes = (props) => {
//     const { clientes, atualizarClientes } = props

//     useEffect(() => {
//         atualizarClientes()
//     }, [atualizarClientes])
//     console.log('clientes', clientes)

//     return (
//         <Box>
//             {
//                 clientes.loading ? (
//                     <Backdrop
//                         open={true}
//                         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//                     >
//                         <CircularProgress color="secondary" />
//                     </Backdrop>
//                 ) : clientes.error ? (
//                     <Typography variant='body'>Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {clientes.error})</Typography>
//                 ) : (
//                     <Box>
//                         <Typography variant='h5' sx={{ my: 2 }}>Clientes</Typography>
//                         <TableContainer component={Paper}>
//                             <Table size="small">
//                                 <TableHead>
//                                     <TableRow>
//                                         <TableCell style={{position: 'sticky', left: 0, minWidth: 12, backgroundColor: '#F2F2F2'}}>ID</TableCell>
//                                         <TableCell style={{position: 'sticky', left: 0, minWidth: 190, backgroundColor: '#F2F2F2'}}>CLIENTE</TableCell>
//                                         <TableCell align="right">CONTA</TableCell>
//                                         <TableCell align="right">COBRANCA</TableCell>
//                                         <TableCell align="right">BOLETO</TableCell>
//                                         <TableCell align="right">NOTAFISCAL</TableCell>
//                                         <TableCell align="right">BOLETO ANDRE</TableCell>
//                                         <TableCell align="right">BOLETO MARCIO</TableCell>
//                                         <TableCell align="right">COM ANDRE</TableCell>
//                                         <TableCell align="right">COM MARCIO</TableCell>
//                                         <TableCell align="right">DEVEDOR</TableCell>
//                                         <TableCell align="right">ORCAMENTO</TableCell>
//                                         <TableCell align="right">PAUSADO</TableCell>
//                                         <TableCell align="right">DEPÓSITO AUTOMATICO</TableCell>
//                                         <TableCell align="right">FINALIZADO</TableCell>
//                                         <TableCell align="right">PERMUTA</TableCell>
//                                         <TableCell align="right">AGUARDANDO</TableCell>
//                                         <TableCell align="right">TOTAL</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {
//                                         clientes.dados.map((cli, index) => (
//                                             <TableRow key={index}>
//                                                 <TableCell>{index + 1}</TableCell>
//                                                 <TableCell>{cli.CLIENTE}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.CONTA)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.COBRANCA)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.BOLETO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.NOTAFISCAL)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.BOLETOANDRE)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.BOLETOMARCIO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.COMANDRE)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.COMMARCIO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.ATRASADO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.DEVEDOR)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.ORCAMENTO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.PAUSADO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.DEPAUTOMATICO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.FINALIZADO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.PERMUTA)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.AGUARDANDO)}</TableCell>
//                                                 <TableCell align="right">{valorMoeda(cli.TOTAL)}</TableCell>
//                                             </TableRow>
//                                         ))
//                                     }
//                                 </TableBody>
//                             </Table>
//                         </TableContainer>
//                     </Box>
//                 )
//             }
//         </Box>
//     )
// }

// const mapStateToProps = (state) => {
//     return {
//         clientes: {
//             loading: state.controleGeral.loading,
//             dados: state.controleGeral.registros,
//             error: state.controleGeral.error
//         }
//     }
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         atualizarClientes: () => dispatch(fetchClientes())
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Clientes)
