import { Box, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import DialogErro from '../../../layouts/DialogErro'
import Loading from '../../../layouts/Loading'
import AppBarComponent from '../../../table/AppBarComponent'
import COLUMNS from './colunas'
import { FiltroColuna } from '../../../table/FiltroColunaComponent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import EditIcon from '@mui/icons-material/Edit'
import DialogExclusao from '../../../layouts/DialogExclusao'
import BarraInferiorTable from '../../../table/BarraInferiorTableComponent'
import { getExportFileBlob } from '../../../../funcoes/exportTable'
import { useExportData } from 'react-table-plugins'
import {
  fetchTiposRedesSociais,
  excluir,
  deleteRegistro,
} from '../../../../redux/cadastro/tabelas/tiposRedesSociaisSlice'

export const Tabela = (props) => {
  const { setModo, registros, listar, excluirBd, excluirStore } = props

  const [openDialogErro, setOpenDialogErro] = useState(true)
  const [exibirExcluidos, setExibirExcluidos] = useState(false)
  const [botaoExcluidos] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [idExclusao, setIdExclusao] = useState('')

  useEffect(() => {
    listar()
  }, [listar])

  const columns = useMemo(() => COLUMNS(), [])
  const data = useMemo(() => registros.dados, [registros])

  const defaultColumn = useMemo(() => {
    return {
      Filter: FiltroColuna,
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
    allColumns,
    getToggleHideAllColumnsProps,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useExportData
  )

  const { globalFilter, pageIndex, pageSize } = state

  const handleExcluir = (idExclusao) => {
    excluirBd(idExclusao)
    excluirStore(idExclusao)
  }

  return (
    <Box>
      {registros.loading ? (
        <Loading />
      ) : registros.error ? (
        <DialogErro
          openDialogErro={openDialogErro}
          setOpenDialogErro={setOpenDialogErro}
          dialogError={`ERRO: ${registros.error}`}
          mensagem="Não foi possível listar os dados. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
        />
      ) : (
        <Box>
          <AppBarComponent
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setModo={setModo}
            exibirExcluidos={exibirExcluidos}
            setExibirExcluidos={setExibirExcluidos}
            botaoExcluidos={botaoExcluidos}
            exportData={exportData}
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            allColumns={allColumns}
          />
          <Box>
            <table {...getTableProps()} style={{ marginTop: '150px' }}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Box display="flex" justifyContent="space-between">
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ArrowDropDownIcon
                                  sx={{ fontSize: '20px', color: 'black' }}
                                />
                              ) : (
                                <ArrowDropUpIcon
                                  sx={{ fontSize: '20px', color: 'black' }}
                                />
                              )
                            ) : null}
                          </span>
                        </Box>
                      </th>
                    ))}
                    <th>Ações</th>
                  </tr>
                ))}

                {pageCount > 1 &&
                  headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                      <td>
                        <Box sx={{ display: 'flex' }}>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setModo({
                                status: 'edit',
                                registro: row.original,
                              })
                            }
                            size="small"
                          >
                            <EditIcon color="danger" />
                          </IconButton>
                          <DialogExclusao
                            title="a Rede Social "
                            referencia={`${row.original.nome} (${row.original.descricao})`}
                            idExclusao={row.original.id}
                            setIdExclusao={setIdExclusao}
                            handleExcluir={handleExcluir}
                          />
                        </Box>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {pageCount > 1 ? (
              <BarraInferiorTable
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageCount={pageCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            ) : null}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    registros: {
      loading: state.tiposRedesSociais.loading,
      dados: state.tiposRedesSociais.registros,
      error: state.tiposRedesSociais.error,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listar: () => dispatch(fetchTiposRedesSociais()),
    excluirBd: (id) => dispatch(excluir(id)),
    excluirStore: (idExclusao) => dispatch(deleteRegistro(idExclusao)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabela)
